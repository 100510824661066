import { createSlice } from "@reduxjs/toolkit";


const generate = () => {

    let story = {
        characteristic: [
            'Righteous', 'Vain', 'Reckless', 'Heroic', 'Masterful', 'Drug-addicted', 'Corrupt', 
            'Selfless', 'Ambitious', 'Anxious', 'Rebellious', 'Scholarly', 'Eccentric', 'Depressed', 
            'Kind', 'Boastful', 'Contented', 'Whimsical', 'Dastardly', 'Wayward', 'Feckless', 
            'Mentally unstable', 'Brave', 'Clever', 'Daring', 'Determined', 'Loyal', 'Mischievous', 
            'Passionate', 'Resourceful', 'Humble', 'Strong', 'Curious', 'Enthusiastic', 'Fierce', 
            'Devious', 'Optimistic', 'Tenacious', 'Charismatic', 'Wise', 'Fearless', 'Graceful', 
            'Honest', 'Mysterious', 'Capable', 'Bold', 'Grizzled', 'Callous', 'Reserved', 'Indifferent', 
            'Pessimistic', 'Cynical', 'Lazy', 'Reclusive', 'Timid', 'Insecure', 'Apathetic', 'Obstinate', 
            'Careless', 'Impulsive', 'Unpredictable', 'Stingy', 'Inconsiderate', 'Untrustworthy', 
            'Neurotic', 'Abrasive', 'Narcissistic', 'Manipulative', 'Egocentric', 'Arrogant', 
            'Insensitive', 'Rash', 'Impatient', 'Unforgiving', 'Cautious', 'Distrustful', 'Skeptical', 
            'Lethargic', 'Complacent', 'Proud', 'Conceited', 'Stubborn', 'Cowardly', 'Gullible', 
            'Superficial', 'Tactless', 'Unyielding', 'Irresponsible', 'Defiant', 'Sluggish', 'Irascible', 
            'Foolhardy', 'Short-tempered', 'Unmotivated', 'Prejudiced', 'Uncooperative', 'Wary', 
            'Calculating', 'Compulsive', 'Compassionate', 'Resilient', 'Perceptive', 'Adaptable', 
            'Disciplined', 'Gregarious', 'Sociable', 'Witty', 'Sincere', 'Diligent', 'Vibrant', 
            'Cautious', 'Dutiful', 'Stoic', 'Vivacious', 'Adroit', 'Spirited', 'Judicious', 'Savvy', 
            'Pensive', 'Versatile', 'Sagacious', 'Admirable', 'Pioneering', 'Astute', 'Vigilant', 
            'Zealous', 'Conscientious', 'Prudent', 'Empowered', 'Unassuming', 'Steadfast', 'Luminary', 
            'Dynamic', 'Staunch', 'Exuberant', 'Dexterous', 'Meticulous', 'Incisive', 'Munificent', 
            'Audacious', 'Resplendent', 'Infallible', 'Stellar', 'Ineffable', 'Sublime', 'Majestic', 
            'Ethereal',''
        ],
        character: [
            'monarch','pirate','house-wife','detective','soldier','farmer','journeyman','courtier',
            'princess','apprentice','oligarch','wizard','hermit','nomad','orphan','chosen one',
            'sorcerer','witch','healer','priest(ess)','sailor','diplomat','rogue','rebel',
            'merchant','pilgrim','shaman','doctor','prophet','villager', 'thief','dog walker',
            'con artist','fisherman','poet','banker','war veteran','scientist','computer programmer',
            'carpenter','politician','demigod','guru','detective','banker','artist','musician','bard',
            'dwarf','elf','goblin','alien','zombie','treasure hunter','psychic','journalist','con artist',
            'magic student','gardner',  'cartographer', 'blacksmith', 'explorer', 'archaeologist', 
            'revolutionary', 'sorceress', 'illusionist', 'bounty hunter', 'philosopher', 'baker', 
            'inventor', 'astronomer', 'mercenary', 'philanthropist', 'time traveler', 'exorcist', 
            'fugitive', 'historian', 'cartoonist', 'butcher', 'geologist', 'airship captain', 
            'archer', 'tinkerer', 'alchemist', 'sleuth', 'hacker', 'martial artist', 'chef', 'librarian', 
            'race car driver', 'entrepreneur', 'courtesan', 'tattoo artist', 'game designer', 'sommelier', 
            'jester', 'Android', 'Quantum physicist', 'Interstellar explorer', 
            'Space mercenary', 'AI programmer', 'Telepath', 'Starship captain', 
             'Galactic diplomat', 'Time traveler', 'Nano-surgeon', 'political activist',
            'Synthetic life form','Exoplanet archeologist', 'Quantum mechanic', 'Robot rights activist', 'Alien diplomat', 
            'Astroarchaeologist', 'Warp drive technician', 'Geneticist', 
            'Dimensional physicist', 'Biohacker', 'Athlete', 'Neuroscientist', 'Astrobiologist', 
            'Virtual reality developer', 
            'Starfleet officer','Cyberspace detective','Starfleet medic', 'Quantum theorist', 
           
        ],
        situation: [
            'in a fight for survival',
            'in a total-war',
            'hopelessly in love',
            'accused of murder',
             'banished from the realm',
             'being chased by their pursuers',
             'condemned to die',
             'penniless',
             'lost in a strange land',
             'trapped in a burning building',
             'stranded on a deserted island',
             'lost in the wilderness',
             'held captive by a criminal organization',
             'kidnapped by a serial killer',
             'pursued by a dangerous animal',
             'caught in the middle of a natural disaster',
             'captured by enemy soldiers during a war',
             'stranded on a malfunctioning spaceship',
             'trapped in a sinking ship',
             'lost in a vast, uncharted cave system',
             'stuck in a malfunctioning elevator',
             'stranded in the Arctic wilderness',
             'injured and alone in a remote location',
             'captured and imprisoned in a foreign country',
             'stranded on a deserted mountaintop',
             'lost at sea in a life raft',
             'trapped in a collapsing mine',
             'stranded in a post-apocalyptic wasteland',
             'held hostage by terrorists on an airplane',
             'held hostage by terrorists in a bank',
             'held hostage by terrorists',
             'in a zombie apocalypse',
             'wakes up one morning with no memory and ',
             'in a long distance relationship',
             'deep inside a corporate conspiracy',
             'discovers they have magical powers and',
             'comes back from the dead inside of a robot body and',
             'recently turned into a vampire',
             'recieves a terminal diagnosis and',
             'discovers time travel and',
             'stuck in a time loop',
             'discovers they are stuck in a simulation and',
             'discovers their spouse is cheating on them and',
             'dosed with a hallucinogen against their will',
             'sentenced to life in prison for a crime they did not commit',
             'lost in a haunted mansion',
             'stuck in charge of an orphanage during an apocolypse',
             'stranded in orbit during an alien invasion',
             'discovers secrets that rewrite history and',
             'wakes up from a years long coma and',
             'unfrozen in the deep future',
             `wakes up in someone else's body and`,
             'in a forbidden love affair',
             'in a unqiue love triangle',
             'trapped inside a building with an active shooter',
             'with only hours left to live',
             'with only days left to live',
             'lost in a forest',
             'has a family member kidnapped and',
             'having a nervous breakdown',
             'in a platonic relationship with the love of their life',
             'pretending to be someone they are not',
             'fighting for survival against a robotic uprising',
             'caught in the crossfire of an intergalactic war',
             'struggling to prove their innocence in a high-profile political scandal',
             'adapting to life as a fugitive after a failed heist', 
             'discovers an ancient artifact with unknown powers and', 
             'entangled in a web of corporate espionage', 
             'investigating a series of mysterious disappearances', 
             'searching for a missing loved one in a dystopian city', 
             `struggling to break free from a cult's control` , 
             'exploring a parallel dimension filled with dangers', 
             'battling supernatural forces beyond their understanding', 
             'trapped in a virtual reality game gone awry', 
             'unraveling a conspiracy that spans multiple timelines', 
             'caught in a conflict between rival factions of magical beings', 
             'escaping a maximum-security prison with fellow inmates', 
             'trying to prevent a catastrophic scientific experiment', 
             'tracking down a mythical creature for a lucrative bounty', 
             'uncovering a hidden society with a dark secret', 
             'contending with the consequences of a wish gone wrong', 
             'fleeing from an ancient curse that threatens their life', 
             'surviving in a world where everyone has disappeared mysteriously', 
             'defying a tyrannical regime to lead a rebellion', 
             'uncovering a plot to control the world through mind control', 
             'building alliances with extraterrestrial civilizations', 
             'escaping from a secret government facility with experimental powers', 
             'facing the challenges of parenthood', 
             'negotiating a delicate peace between warring factions', 
             'trying to break a curse that transforms them into a monster', 
             'discovers a hidden society of supernatural beings and', 
             'infiltrating an underground resistance against a powerful dictatorship', 
             'caught in a time-travel conspiracy with far-reaching consequences', 
             'forging alliances with mythical creatures to save their world', 
             'awakening ancient powers that could reshape the fabric of reality', 
             'seeking redemption for past crimes through a perilous journey', 
             'finding themselves the target of a relentless assassin', 
             'uncovering a secret society that controls world events', 
             'struggling with a sudden ability to hear the thoughts of others', 
             'navigating the treacherous politics of a foreign court', 
             'uncovering the truth behind a series of unsolved murders', 
             'seeking revenge against those who betrayed them', 
             'caught in the midst of a battle between ancient gods', 
             'journeying through a cyberpunk city filled with corruption', 
             'dealing with the aftermath of a devastating alien invasion',
             'waking up in a future world with no memory',
             'battling a rival faction for control of a powerful artifact',
             'surviving in a city overrun by mutated creatures',
             'coming face-to-face with their own alternate reality counterpart',
             'leading a group of rebels against a totalitarian regime', 
             'living with the consequences of a deal made with a supernatural entity', 
             'adapting to life as a cyborg after a near-fatal accident', 
             'facing the consequences of accidentally unleashing a deadly virus', 
             'uncovering a hidden school for magic users', 
             'struggling with the ethics of advanced artificial intelligence',
             'escaping from a research facility experimenting on supernatural abilities', 
             'looking for their lost dog',
             'building a resistance against a corporate bully',




            ],

        objective: [
            'must prove their innocence',
            'must find their father',
            'must find the chosen one',
            'must find a special object',
            'must overcome a monster',
            'must save the world from destruction',
            'must find a cure for a deadly disease',
            'must rescue a loved one from danger',
            'must find a hidden treasure',
            `must clear their name of a crime they didn't commit`,
            'must escape from captivity',
            'must save a group of people from a natural disaster',
            'must stop a terrorist attack',
            'must prevent a war from breaking out',
            'must uncover a conspiracy',
            'must overthrow an evil dictator',
            'must find their long-lost family',
            'must discover the truth about their past',
            'must seek revenge on those who have wronged them',
            'must become the best in their field',
            'must find redemption for their past mistakes',
            'must find inner peace and enlightenment',
            'must right a wrong that has been done to them',
            'must fulfill a lifelong dream',
            'must save an endangered species from extinction',
            'must solve a mysterious murder',
            'must overcome a powerful addiciton',
            'must invent time travel',
            'must solve a series of puzzles',
            'must right a past wrong',
            'must confess to a crime they did not commit',
            'must regain long lost abilities',
            

                            
        
        ],
        opponent: [
            'defeat their proffesional rival',
            'defeat the dark lord',
            'defeat their romantic rival',
            'win the race against time',
            'conquer the powers-that-be',
            'win the war',
            'reunite with their former lover',
            'save the realm',
            'conquer their fears',
            'make the inner changes',
            'defeat an evil dictator',
            'defeat a criminal organization',
            'defeat a monstrous creature',
            'survive a natural disaster',
            'find a cure for a deadly virus',
            'traverse a treacherous landscape',
            'defeat a powerful sorceress',
            'outwit an enemy army',
            'destroy a rival business',
            'uncover a terrorist organization',
            'defeat a rival love interest',
            'overcome a powerful alien race',
            'bring peace to a vengeful ghost',
            'outwit a ruthless bounty hunter',
            'unveil a mysterious organization',
            'escape the wrath of a vengeful god',
            'defeat a gang of outlaws',
            'find the origins of an ancient and powerful being',
            'destory an all-seeing artificial intelligence',
        ],
        disaster: [
            'the end of the world',
            'they are killed',
            'everything they know and love is lost',
            'societal collapse',
            'humans go extinct',
            'war breaks out',
            'their loved one dies',
            'they lose everything',
            'all hope is lost',
            'they go insane',
            'they lose their soul',
            'they go bankrupt',
            'they lose their business',
            'they lose their home',
            'a deadly contagion spreads around the world',
            'the world is invaded by aliens',
            'a destructive robot uprising'
            ,'totalitarian control of the world',
            'reality dissolves into chaos',
            'a criminal mastermind gains absolute power',
            'the one they love gets away',
            'their reputation is irreparably damaged',
            
            
        ]
    };

    const random0 = Math.floor(Math.random() * story.characteristic.length);
    const random1 = Math.floor(Math.random() * story.character.length);
    const random2 = Math.floor(Math.random() * story.situation.length);
    const random3 = Math.floor(Math.random() * story.objective.length);
    const random4 = Math.floor(Math.random() * story.opponent.length);
    const random5 = Math.floor(Math.random() * story.disaster.length);

    const characteristic = story.characteristic[random0];
    const character =story.character[random1].toLowerCase();
    const situation =story.situation[random2];
    const objective =story.objective[random3];
    const opponent =`in order to ${story.opponent[random4]}`;
    const disaster =`before ${story.disaster[random5]}.`;

    let items ={characteristic,character,situation,objective,opponent,disaster};
    return items;
}

export const storyPremiseSlice = createSlice({
    name: 'storyPremise',
    initialState: {
        storyPremise :'',
        savedPrompts: [],
    },
    reducers: {
        premiseGen: (state) => {

            let characteristic = generate().characteristic;
            let character = generate().character;
            let situation = generate().situation;
            let objective = generate().objective;
            let opponent = generate().opponent;
            let disaster = generate().disaster;

            state.storyPremise = `${characteristic} ${character} ${situation} ${objective} ${opponent} ${disaster}`
            
        },
        savePrompt: (state, action) => {
            for(let i=0 ; i<state.savedPrompts.length+1 ; i++){
                if(state.savedPrompts[i]===action.payload){
                    return
                }
            }
            state.savedPrompts.push(state.storyPremise)
        },
        removePrompt: (state, action) => {
            state.savedPrompts = state.savedPrompts.filter((prompt) => prompt !== action.payload)

        }
    }
})



export const selectStoryPremise = (state) => state.storyPremise.storyPremise
export const selectSavedPrompts = (state) => state.storyPremise.savedPrompts
export default storyPremiseSlice.reducer;
export const { premiseGen, savePrompt, removePrompt } = storyPremiseSlice.actions;