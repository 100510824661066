import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const NarStyles = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div id='narStyles'>
            <header><h1>Narrative Styles</h1></header>
            <NavLink to='/story-elements/craft-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>


            <div className='genres'>
            <li className="genre">
                <h2>First-Person Narrative</h2>
                <p><em>Description:</em> The story is told from the perspective of a character within the story using first-person pronouns (I, we).</p>
                <p><em>Example:</em> I walked into the room and saw a mysterious figure standing by the window.</p>
            </li>

            <li className="genre">
                <h2>Third-Person Limited</h2>
                <p><em>Description:</em> The narrator is external to the story and focuses on the thoughts and feelings of a single character.</p>
                <p><em>Example:</em> She felt a wave of anxiety as she approached the interview room.</p>
            </li>

            <li className="genre">
                <h2>Third-Person Omniscient</h2>
                <p><em>Description:</em> The narrator is external and has knowledge of the thoughts and feelings of all characters in the story.</p>
                <p><em>Example:</em> Little did they know, a surprise awaited them just around the corner.</p>
            </li>

            <li className="genre">
                <h2>Second-Person Narrative</h2>
                <p><em>Description:</em> The narrator directly addresses the reader using second-person pronouns (you).</p>
                <p><em>Example:</em> You open the old, creaky door and step into the mysterious room.</p>
            </li>

            <li className="genre">
                <h2>Parallel Narrative</h2>
                <p><em>Description:</em> Multiple storylines occurring simultaneously, often with connections or thematic parallels.</p>
                <p><em>Example:</em> The narrative alternates between different characters or settings, gradually revealing the connections between their experiences.</p>
            </li>

            <li className="genre">
                <h2>Stream of Consciousness</h2>
                <p><em>Description:</em> A narrative technique that presents a character's thoughts and feelings as they occur in the character's mind, often without a clear structure.</p>
                <p><em>Example:</em> The rain began to fall, each droplet tapping on the window like a secret code. I wonder if the world outside feels the same weight I carry within me. The coffee on the table grows cold, forgotten amid the chaos of memories and unspoken words. How did I end up here...</p>
            </li>

            <li className="genre">
                <h2>Epistolary</h2>
                <p><em>Description:</em> The story is told through a series of letters, diary entries, or other written documents.</p>
                <p><em>Example:</em> The old letters revealed the secrets of a love long forgotten.</p>
            </li>

            <li className="genre">
                <h2>Objective Narrative</h2>
                <p><em>Description:</em> The narrator remains a detached observer, presenting only the actions and dialogue of the characters without delving into their thoughts.</p>
                <p><em>Example:</em> The clock struck midnight, and the protagonist entered the room without saying a word.</p>
            </li>

            <li className="genre">
                <h2>Flashback</h2>
                <p><em>Description:</em> The narrative moves back in time to recount events that occurred before the current point in the story.</p>
                <p><em>Example:</em> As she walked through the old neighborhood, memories of childhood flooded back in vivid detail.</p>
            </li>

            <li className="genre">
                <h2>Framed Narrative</h2>
                <p><em>Description:</em> The main narrative is presented as a story within a story, often with a framing device to set the stage.</p>
                <p><em>Example:</em> The elderly man began to recount his adventures, starting with the day he found an ancient journal in the attic.</p>
            </li>

            <li className="genre">
                <h2>Linear Narrative</h2>
                <p><em>Description:</em> The story unfolds in a chronological sequence, moving forward in time without significant jumps or interruptions.</p>
                <p><em>Example:</em> The protagonist's journey began on a sunny morning and continued through a series of challenges and triumphs.</p>
            </li>

            <li className="genre">
                <h2>Nonlinear Narrative</h2>
                <p><em>Description:</em> The story is presented out of chronological order, with events occurring in a sequence other than a straight timeline.</p>
                <p><em>Example:</em> Flashbacks, flash-forwards, and time loops contribute to a narrative structure that challenges traditional linear storytelling.</p>
            </li>

            <li className="genre">
                <h2>Unreliable Narrator</h2>
                <p><em>Description:</em> The narrator's credibility or reliability is compromised, intentionally or unintentionally, leading to a narrative where the audience questions the truth of the events.</p>
                <p><em>Example:</em> The narrator may have a biased perspective, distorted memory, or hidden motives, creating uncertainty about the accuracy of the story they present.</p>
            </li>

            <li className="genre">
                <h2>Metafiction</h2>
                <p><em>Description:</em> A narrative that draws attention to its own fictional nature, often breaking the fourth wall.</p>
                <p><em>Example:</em> The characters become aware that they are part of a story, addressing the reader or acknowledging the narrative structure.</p>
            </li>

            <li className="genre">
                <h2>Interactive Narrative</h2>
                <p><em>Description:</em> A narrative where the audience has the ability to make choices that affect the direction of the story.</p>
                <p><em>Example:</em> The reader/player decides the actions of the protagonist, influencing the outcome of the plot.</p>
            </li>
        


            </div>
        </div>
    )
}