import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState,useEffect } from "react";
import { debounce } from "../../utilties/debounce";
import './nav.css'

export const Nav = () => {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
  
    const handleScroll = debounce(() => {
      const currentScrollPos = window.pageYOffset;
  
      setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
  
      setPrevScrollPos(currentScrollPos);
    }, 100);
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
  
    }, [prevScrollPos, visible, handleScroll]);
  
    const navbarStyles = {
      transition: 'top 0.35s'
      
    }
  
    return (
        <div >
            <nav className='nav' style={{ ...navbarStyles, top: visible ? '0' : '-115px' }}>
              <div id='logos'>
                <Link to={'/'}><img alt='' id='favLogo' src='/images/favlogotrans.png' /></Link>
                <Link to={'/'}><img alt = '' id ='Wlogo'src='/images/newlogotrans.png' /></Link>
              </div>
                <ul>
                  <NavLink to={`prompt-generators`} id='nav6'className='navLink'>Prompt Generators</NavLink>
                  <NavLink to={`get-idea`} id='nav2'className='navLink'>Get an Idea</NavLink>
                  <NavLink to={`write-idea`} id='nav3'className='navLink'>Write Your Idea</NavLink>
                  <NavLink to={'story-elements'} id='nav1' className='navLink'>Story Elements</NavLink>
                  <NavLink to={'blog'} id='nav4'className='navLink'>Blog</NavLink>
                  <NavLink to={'about'} id='nav5'className='navLink'>About</NavLink>
                  <NavLink to={`login`} id='nav7'className='navLink'>Login</NavLink>
                </ul>
                
                <div className="navbar">
                  <div className="dropdown">
                    <button className="dropbtn"><img alt='' src='/images/favlogotrans.png' />
                      <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="dropdown-content">
                      <NavLink to={`/`} id='nav7'className='dropLink'>Home</NavLink>
                      <NavLink to={`prompt-generators`} id='nav6'className='dropLink'>Prompt Generators</NavLink>
                      <NavLink to={`get-idea`} id='nav2'className='dropLink'>Get an Idea</NavLink>
                      <NavLink to={`write-idea`} id='nav3'className='dropLink'>Write Your Idea</NavLink>
                      <NavLink to={'story-elements'} id='nav1' className='dropLink'>Story Elements</NavLink>
                      <NavLink to={'blog'} id='nav4'className='dropLink'>Blog</NavLink>
                      <NavLink to={'about'} id='nav5'className='dropLink'>About</NavLink>
                      <NavLink to={`login`} id='nav7'className='dropLink'>Login</NavLink>

                    </div>
                  </div>
                </div>
            </nav>
        </div>
    )
}