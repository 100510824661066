import React from "react";

export const BlogPost2 = () => {

        window.scroll(0,0)


    return(
            <div className='blogPost'>
                
                <div className='blogLeft'>
                    <img className='blogImage' src='/images/blog2.png' alt='garden next to a building'/>
                    <p className = 'blogDate'>Nov 30, 2023</p>
                    <div className = 'blogNav'>
                        <h4>Contents</h4>
                        <ul>
                            <li>Gardner Exercise</li>
                            <li>Architect Exercise</li>
                            <li>What the results mean for you</li>
                        </ul>
                    </div>
                </div>
                
                <div className='blogTop'>
                    <div className='blogTitle'>Are You a Gardner or an Architect?</div>
                    <div className='subtitle'>
                        <p>Find out your plotting style and what it means for your next writing project.</p>
                    </div>
                </div>


        </div>
    )
}