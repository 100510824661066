import React from "react";
import { useEffect } from "react";
import { Link,NavLink, Outlet } from "react-router-dom";


export const Write4 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);


    return(
        <div className ='step'>
            <h3>Step 4: Story Structure Summary</h3>
            <p className='step-p'>Write one sentence for each plot point, according to your chosen character arc. Depending on the length of your story, some of these plot points won't make it onto the page, but they're still worthwhile to brainstorm, if only for backstory.</p>
            <p className='step-p'>Your story is like an iceberg, with only the tip visible to the reader. The shorter the story, the more left unseen.</p>
            <p className='step-p'>If you have mutliple POV characters, you'll need to do this for each one. After this, you’ll have a much better idea about the beginning, middle, and end of your story. But don’t spend too much time here, as these can, and probably will, change.</p>

           <br/>
                <ul id='arcsList' className='lineNav'>
                    <NavLink to='positive-arc' className='link'>Positive</NavLink>
                    <NavLink to='flat-arc' className='link'>Flat</NavLink>
                    <NavLink to='corruption-arc' className='link'>Corruption</NavLink>
                    <NavLink to='disillusionment-arc' className='link'>Disillusionment</NavLink>
                    <NavLink to='thefall-arc' className='link'>The Fall</NavLink>
                </ul>
            <br/>
            <Outlet/>

            <br/>
           
            <div className='stepArrows'>
                        <Link to='../write-idea-step-3'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../write-idea-step-5'><button id='button2'><span>Next</span></button></Link>
                    </div>
        </div>
    )}