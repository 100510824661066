import React from "react";
import './footer.css'
import { Link } from "react-router-dom";

export const Footer=()=>{



    return(
            <div className='footer'>
                <div className='icons'>
                    <img  alt=''src='/images/x.png'/>
                   <a target="_blank" rel="noreferrer"  href='https://www.instagram.com/writician_/'><img  alt=''src='/images/insta.png'/></a> 
                    <img  alt=''src='/images/fb.png'/>
                </div>
                <div id='footerLists'>
                    <ul className ='footerList'>
                        <Link className='footerLink'to='/story-elements/plot-elements/genres'>Genres</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/plot-archetypes'>Plot Archetypes</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/character-arcs'>Character Arcs</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/subplots'>Subplots</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/hooks'>Hooks</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/endings'>Endings</Link>
                    </ul>
                    <ul className ='footerList'>
                        <Link className='footerLink'to='/story-elements/character-elements/character-archetypes'>Character Archetypes</Link>
                        <Link className='footerLink'to='/story-elements/character-elements/personality-types'>Personality Types</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/lit-devices'>Literary Devices</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/narrative-styles'>Narrative Styles</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/themes'>Themes</Link>
                    </ul>
                    <ul className ='footerList'>
                        <Link className='footerLink'to='/get-idea'>Get an Idea</Link>
                        <Link className='footerLink'to='/write-idea'>Write Your Idea</Link>
                        <Link className='footerLink'to='/blog'>Blog</Link>
                        <Link className='footerLink'to='/about'>About</Link>
                        <Link className='footerLink'to='/prompt-generators'>Prompt Generators</Link>
                        <Link className='footerLink'to='/login'>Login</Link>
                    </ul>
                </div>
                
                <img alt='' id='footerLogo' src='images/favlogotrans.png'/>
                <p>copyright Flintsmoke Digital Limited 2023</p>
                
                

            </div>

    )
}