import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlotSave } from "./plotSave";
import { Selector } from "./selector";
import { plotGen } from "./plotPromptSlice";
import { selectPlotPrompt,displayPlot } from "./plotPromptSlice";
import { All } from "./all";
import { useState } from "react";
import { useEffect } from "react";

export default function PlotPrompt(){

    const dispatch = useDispatch();
    const plotPrompt = useSelector(selectPlotPrompt);
    const prompt = plotPrompt.prompt.prompt;

    const [everything, setEverything] = useState(false)
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});
    
    useEffect(()=>{
        if(prompt !== undefined){
            setSave(true);

            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[prompt])


    const handleClick = () => {
        dispatch(plotGen());
        dispatch(displayPlot())
        save? setSave(false): setSave(true)

    }

    
    const handleEverything = () => {
        if(everything){
            setEverything(false)
            document.getElementById('seeAll').innerHTML='See all story elements.'
        } else {
        setEverything(true);
        document.getElementById('seeAll').innerHTML='Hide all story elements.'
    }}

    return(
        <div id="plotPrompt">
            <Selector /> 
            {save && <div id='plotP' style={style}>
                {prompt}
            </div>}
            <button id='button1' onClick={()=> handleClick()}>Generate New Plot</button>
            {save && <PlotSave style={style}/>}


            <div id='seeAllBox'> 
                <a href='#mainAll'><p id='seeAll' onClick={()=> handleEverything()}>
                See all story elements.
                </p></a>
            </div>
            {everything && <All />}
        </div>
    )
}



/*              


    const all = useSelector(selectShowAll);
    const [visible, setVisible] = useState(false)



    const handleShow = () => {
        if(visible){
            setVisible(false)
            document.getElementById('showButton').innerHTML = 'Show All'
        } else {
            setVisible(true)
            document.getElementById('showButton').innerHTML = 'Hide All'
        }
        
    }


                {save && <p id='showButton'onClick={()=>handleShow()}>Show All</p>}
                { visible && 
                <ul id='showAll' className = 'show'>
                    <li className = 'genre'>{all.genre}</li>
                    <li className = 'genre'>{all.plot}</li>
                    <li className = 'genre'>{all.personality}</li>
                    <li className = 'genre'>{all.character}</li>
                    <li className = 'genre'>{all.arc}</li>
                </ul>
            } */