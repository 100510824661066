

            let scifi = (
                <div>
                    <h3>Science Fiction</h3>
                    <p> A genre of speculative fiction that commonly explores creative and forward-looking concepts, including advanced science and technology, space exploration, time travel, parallel universes, and extraterrestrial life. Often referred to as the "literature of ideas," this genre frequently delves into the potential ramifications of scientific, social, and technological advancements.</p>
                    <h6><i>Dune, Foundation, Ender's Game, War of the Worlds</i></h6>
                </div> )


            let fantasy = (
                <div>
                    <h3>Fantasy</h3>
                    <p>A genre of speculative fiction that incorporates magical elements, often situated in a fictional universe and occasionally drawing inspiration from mythology and folklore. Fantasy typically revolves around the use of magic or other supernatural elements as key components of the plot, theme, or setting. Within these fantastical worlds, magic, practitioners of magic (such as sorcerers and witches), and magical creatures are frequently prominent features.</p>
                    <h6><i>Lord of the Rings, A Song of Ice and Fire, The Wheel of Time</i></h6>
                </div>)


            let mystery = (
                <div>
                    <h3>Mystery</h3>
                    <p>A genre of fiction characterized by a mysterious event, typically a murder or crime, the resolution of which is withheld until the conclusion of the narrative. Frequently unfolding within a confined group of suspects, each individual is typically endowed with a plausible motive and a feasible opportunity to have committed the crime. The central figure in these narratives is often a detective figure, exemplified by characters like Sherlock Holmes, who ultimately unravels the mystery through logical deduction based on the facts presented to the reader.</p>
                    <h6><i>Sherlock Holmes, Murder on the Orient Express, The Maltese Falcon, The DaVinci Code</i></h6>
                </div>
            )

            let thriller = (
                <div>
                    <h3>Thriller</h3>
                    <p>A category of fiction encompassing various, frequently intersecting subgenres, thrillers are distinguished and identified by the emotions they evoke, provoking heightened sensations of suspense, excitement, surprise, anticipation, and anxiety in the audience.</p>
                    <h6><i>The Bourne Identity, Gone Girl, The Manchurian Candidate</i></h6>
                </div>
            )
                
            let dystopian = (
                <div>
                    <h3>Dystopian</h3>
                    <p>A speculative fiction genre that envisions worlds or societies marked by profound suffering due to deprivation, oppression, or terror. Human existence in these settings is defined by widespread misery, encompassing elements such as squalor, oppression, disease, overcrowding, environmental degradation, or war.</p>
                    <h6><i>1984, Brave New World, The Handmaid's Tale, The Giver</i></h6>
                </div>
            )
                
            let steampunk = (
                <div>
                    <h3>Steampunk</h3>
                    <p>A subgenre of science fiction that  integrates retrofuturistic technology and aesthetics inspired by 19th-century industrial steam-powered machinery. Steampunk works frequently unfold in alternative histories of the Victorian era or the American "Wild West," envisioning worlds where steam power continues to be widely utilized. Additionally, steampunk settings may extend to fantasy realms that similarly embrace the imaginative and fantastical applications of steam power.</p>
                    <h6><i>The Goldren Compass, Mortal Engines, Wild Wild West, The League of Extraordinary Gentlemen</i></h6>
                </div>
            )
                
            let romance = (
                <div>
                    <h3>Romance</h3>
                    <p>Romantic fiction revolves around the love stories of two individuals, characterized by a lighthearted and optimistic tone. These narratives ensure emotional satisfaction with endings that consistently favor the romantic relationship. While conflicts are present within romance novels, they never overshadow the enduring nature of the love story, which invariably triumphs in the conclusion.</p>
                    <h6><i>The Notebook, Pride and Prejudice, The Fault in Our Stars</i></h6>
                </div>
            )
                
            let historicalFiction = (
                <div>
                    <h3>Historical Fiction</h3>
                    <p>Stories set in bygone eras. Crafted with a meticulous blend of research and imagination, they skillfully transport readers to periods and locations that may be authentic, fictional, or a fusion of both. Numerous historical novels weave narratives around real historical figures or events, immersing readers in the rich tapestry of historical settings.</p>
                    <h6><i>The Book Thief, Pillars of the Earth, Shogun, The Revenant"</i></h6>
                </div>
            )
                
            let postApocalyptic = (
                <div>
                    <h3>Post-Apocalyptic</h3>
                    <p>A subgenre within science fiction, science fantasy, dystopia, or horror, depicts scenarios where civilization is in the process of collapsing or has already crumbled. The apocalyptic events can range from climatic or astronomical occurrences like impact events, to destructive events such as nuclear holocausts or resource depletion. Additionally, they may include medical crises like pandemics, whether of natural or human origin, or more fantastical scenarios like a zombie apocalypse, cybernetic revolt, technological singularity, dysgenics, or alien invasion, as well as end-time events such as the Last Judgment, Second Coming, or Ragnarök.</p>
                    <h6><i>I, Robot, The Matrix, Mad Max, The Road</i></h6>
                </div>
            )

            let adventure = (
                <div>
                    <h3>Adventure</h3>
                    <p>Adventure stories place the element of danger at the forefront. An adventure, typically involving a series of events outside the protagonist's routine life, is characterized by the presence of danger, often manifested through physical action. These narratives generally unfold rapidly, emphasizing the plot's pace as a paramount element, with equal significance to characterization, setting, and other creative components.</p>
                    <h6><i>Indian Jones, Jurassic Park, The Three Musketeers </i></h6>
                </div>
            )
                
                let horror = (
                <div>
                    <h3>Horror</h3>
                    <p>Horror novels are crafted to instill fear, provoke shock, and even evoke repulsion in readers. Typically centering around themes of death, demons, evil spirits, and the afterlife, these narratives exploit fears through the presence of terrifying entities such as ghosts, vampires, werewolves, witches, and monsters. In the realm of horror fiction, both plot and characters function as instruments, strategically employed to generate a chilling and terrifying sense of dread.</p>
                    <h6><i>The Shining, The Exorcist, Bird Box</i></h6>
                </div>
                )

                let literaryFiction = (
                    <div>
                    <h3>Literary Fiction</h3>
                    <p>Recognized for their artistic value and literary merit, these novels frequently incorporate elements of political criticism, social commentary, and profound reflections on the human condition. Distinguished by their emphasis on character development, literary fiction often prioritizes the inner narrative of characters over a strictly plot-driven structure. The genre delves into the complexities of human experience, offering nuanced insights into the intricacies of individuals and society.</p>
                    <h6><i>To Kill a Mockingbird, One Hundred years of Solitude, Anna Karenina, The Great Gatsby</i></h6>
                </div>
                )
                
                let highFantasy = (
                    <div>
                        <h3>High Fantasy</h3>
                        <p>A subgenre of fantasy characterized by the grand and expansive nature of its setting, characters, themes, or plot. It stands out for being situated in an alternative and fictional ("secondary") world, as opposed to the familiar "real" or "primary" world. This secondary world within high fantasy is typically intricately crafted and internally consistent, adhering to its own set of rules and dynamics distinct from those of the primary world.</p>
                        <h6><i>The Chronicles of Narnia, Mistborn, The Earthsea Cycle, Lord of the Rings</i></h6>
                    </div>
                )
                let lowFantasy = (
                    <div>
                        <h3>Low Fantasy</h3>
                        <p>Also known as intrusion fantasy, low fantasy is a subgenre of fantasy fiction wherein magical events disrupt an otherwise ordinary world. It is characterized by its setting on Earth—the primary or real world—or in a rational and familiar fictional world, with the incorporation of magical elements. Unlike high fantasy, which often unfolds in entirely fantastical realms, low fantasy introduces magic into environments that are recognizable and grounded in reality.</p>
                        <h6><i>Harry Potter, American Gods, The Magicians, The Dresden Files</i></h6>
                    </div>
                )

                let slipstream = (
                    <div>
                        <h3>Slipstream</h3>
                        <p>The slipstream genre is a term denoting forms of speculative fiction that blends together science fiction, fantasy, and literary fiction or do not remain in conventional boundaries of genre and narrative, directly extending from the experimentation of the New Wave science fiction movement while also borrowing from fantasy, psychological fiction, philosophical fiction and other genres or styles of literature.</p>
                        <h6><i></i></h6>
                    </div>
                )

                let superHero = (
                    <div>
                        <h3>Super Hero</h3>
                        <p>A category within speculative fiction that explores the adventures, personalities, and ethical dilemmas of costumed crime fighters known as superheroes. These characters often possess superhuman powers and engage in battles with similarly powered criminals referred to as supervillains. Situated between hard fantasy and soft science fiction on the spectrum of scientific realism, the superhero genre incorporates elements of both fantastical and scientifically influenced storytelling.</p>
                        <h6><i>X-men, Black Panther, The Incredibles, The Reckoners </i></h6>
                    </div>
                )

                let cyberpunk = (
                    <div>
                        <h3>Cyberpunk</h3>
                        <p>A subset within science fiction, Cyberpunk unfolds in a dystopian future where there is an emphasis on the intersection of "lowlife and high tech." It showcases advanced technological and scientific marvels like artificial intelligence and cybernetics against a backdrop of societal collapse, dystopia, or decay. This subgenre explores the complex interplay between futuristic innovation and the darker facets of society, often delving into themes of corruption, inequality, and urban deterioration.</p>
                        <h6><i>Neuromancer, Bladerunner, Ready Player One, The Matrix</i></h6>
                    </div>
                )

                let urbanFantasy = (
                    <div>
                        <h3>Urban Fantasy</h3>
                        <p>A fantasy subgenre that integrates supernatural elements into a modern urban environment. This fusion allows authors to employ whimsical plot devices, unique character traits, and a backdrop for traditional fantasy themes, all while avoiding the necessity of constructing an entirely imagined world.</p>
                        <h6><i>The Mortal Instruments, City of Stairs, Rivers of London, Neverwhere</i></h6>
                    </div>
                )

                let altHistory = (
                    <div>
                        <h3>Alternative History</h3>
                        <p>A genre of speculative fiction that explores narratives in which one or more historical events unfold and conclude differently than in reality. Rooted in conjecture based on historical facts, these stories pose "What if?" scenarios, envisioning crucial events in human history with outcomes diverging significantly from the established historical record.</p>
                        <h6><i>The Man in the High Castle, The Years of Rice and Salt, For Want of a Nail</i></h6>
                    </div>
                )

                let politicalThriller = (
                    <div>
                        <h3>Politcal Thriller</h3>
                        <p> A thriller that is set against the backdrop of a political power struggle, high stakes and suspense is the core of the story. The genre often forces the audiences to consider and understand the importance of politics. The stakes in these stories are immense, and the fate of a country is often in the hands of one individual. Political corruption, organized crime, terrorism, and warfare are common themes.</p>
                        <h6><i>Tinker Tailor Soldier Spy, Red Sparrow, The Sum of All Fears</i></h6>
                    </div>
                )

                let western = (
                    <div>
                        <h3>Western</h3>
                        <p>The Western genre unfolds against the backdrop of the American frontier, closely tied to the folk tales of the Western United States, particularly the Southwestern United States, Northern Mexico, and Western Canada. The 'old west' was a terrain traversed by cowboys, outlaws, sheriffs, and an array of archetypal "gunslinger" figures. Western narratives frequently delve into the gradual endeavors to bring order to the crime-infested American West, interweaving broader themes of justice, freedom, rugged individualism, Manifest Destiny, and the historical and national identity of the United States.</p>
                        <h6><i>True Grit, All the Pretty Horses, Butch Cassidy and the Sundance Kid</i></h6>
                    </div>
                )

                let spyFiction = (
                    <div>
                        <h3>Spy Fiction</h3>
                        <p>A genre that incorporates espionage as a significant context or plot device. Originating in the early twentieth century, it draws inspiration from the rivalries and intrigues among major powers during that time, as well as the establishment of modern intelligence agencies.</p>
                        <h6><i>Red Sparrow, Casino Royale, The Day of the Jackal</i></h6>
                    </div>
                )

                let satire = (
                    <div>
                        <h3>Satire</h3>
                        <p>A genre of fiction that employs ridicule to highlight vices, follies, abuses, and shortcomings with the aim of exposing or shaming perceived flaws in individuals, corporations, government, or society as a whole, prompting improvement. While humor is a common element, the primary purpose of satire is often constructive social criticism. It uses wit to bring attention to specific and broader societal issues, intending to provoke reflection and change.</p>
                        <h6><i>Animal Farm, Catch-22, Gulliver's Travels</i></h6>
                    </div>
                )

                let youngAdult = (
                    <div>
                        <h3>Young Adult</h3>
                        <p>Tailored for readers aged approximately 12 to 18. Delves into themes such as friendship, sexuality, drugs and alcohol, as well as sexual and gender identity. Narratives often center on the trials of youth, such as problem novels or coming-of-age novels. The demarcation between children's and adult literature is fluid, influenced by moral and political ideology, and in certain instances, it holds little meaningful distinction.</p>
                        <h6><i>The Hate U Give, The Hunger Games, The Perks of Being a Wallflower</i></h6>
                    </div>
                )

                let middleGrade = (
                    <div>
                        <h3>Middle Grade</h3>
                        <p>Fiction intended for children between the ages of 8 and 12. Middle grade works do not include profanity, graphic violence, or sexuality, and they tend to focus on the characters' friends, family, and immediate surroundings.</p>
                        <h6><i>Holes, Harry Potter and the Sorcerer's Stone, Percy Jackson and the Olympians</i></h6>
                    </div>
                )
                
                let firstContact = (
                    <div>
                        <h3>First Contact</h3>
                        <p>A prevalent science fiction motif revolves around the initial encounter between humans and extraterrestrial life or between sentient species from different celestial bodies. This theme provides writers with an avenue to delve into subjects like xenophobia, transcendentalism, and fundamental linguistics. By adapting the anthropological concept of first contact to extraterrestrial cultures, storytellers can explore the intricacies and challenges of these unprecedented interactions.</p>
                        <h6><i>Contact, Arrival, Ender's Game</i></h6>
                    </div>
                )

                let spaceOpera = (
                    <div>
                        <h3>Space Opera</h3>
                        <p>A subgenre of science fiction, places a strong emphasis on space warfare, weaving melodramatic, adventurous tales of risk, relationships, and chivalric romance. Primarily or exclusively set in outer space, this genre showcases technological and social advancements (or their absence) through faster-than-light travel, futuristic weaponry, and sophisticated technology. Against the backdrop of galactic empires and interstellar conflicts, often featuring fictional aliens in imaginary galaxies, space opera invites audiences into grandiose narratives of cosmic proportions.</p>
                        <h6><i>Star Wars, Dune, The Exapanse, Lensman</i></h6>
                    </div>
                )

                let magicalRealism = (
                    <div>
                        <h3>Magical Realism</h3>
                        <p>a literary and artistic style that portrays a realistic view of the world while seamlessly incorporating magical elements, creating a subtle interplay between fantasy and reality. In this genre, magical or supernatural phenomena are seamlessly woven into an otherwise real-world or ordinary setting. While magical realism includes elements of magic, it is distinct from fantasy as it integrates a substantial amount of realistic detail. The use of magical elements serves to convey deeper insights about reality, distinguishing it from fantasy, where narratives are often distinctly separated from the constraints of the real world.</p>
                        <h6><i>One Hundred Years of Solitude, Midnight's Children, The Master and Margarita</i></h6>
                    </div>
                )
                 let epic = (
                    <div>
                        <h3>Epic</h3>
                        <p>A narrative genre characterized by the portrayal of heroic or legendary adventures presented in an extended format.</p>
                        <h6><i>The Iliad, Beowulf, The Mahabharata, Paradise Lost</i></h6>
                    </div>
                )
                let heist = (
                    <div>
                        <h3>Heist</h3>
                        <p>A subgenre of crime movies that center on the meticulous planning, execution, and aftermath of a notable robbery.</p>
                        <h6><i>Ocean's Eleven, The Italian Job, Reservoir Dogs</i></h6>
                    </div>
                )



                ;

                
                export const genres = {
                        scifi,
                        fantasy,
                        mystery,
                        thriller,
                        dystopian,
                        steampunk,
                        romance,
                        historicalFiction,
                        postApocalyptic,
                        adventure,
                        horror,
                        literaryFiction,
                        highFantasy,
                        lowFantasy,
                        slipstream,
                        western,
                        urbanFantasy,
                        epic,
                        youngAdult,
                        middleGrade,
                        firstContact,
                        spaceOpera,
                        magicalRealism,
                        altHistory,
                        cyberpunk,
                        superHero,
                        politicalThriller,
                        spyFiction,
                        satire,
                        heist

                    }


                    let overcoming = (
                        <div>
                            <h3>Overcoming the Monster</h3>
                            <p>The protagonist sets out to defeat an antagonistic force (often evil) which threatens the protagonist and/or protagonist's homeland.</p>
                        </div>
                    );
                    let rags = (
                        <div>
                            <h3>Rags to Riches</h3>
                            <p>The poor protagonist acquires power, wealth, and/or a mate, loses it all and gains it back, growing as a person as a result.</p>
                        </div>
                    );
                    let quest =(
                        <div>
                            <h3>The Quest</h3>
                            <p>The protagonist and companions set out to acquire an important object or to get to a location. They face temptations and other obstacles along the way.</p>
                        </div>
                    );
                    let voyage =(
                        <div>
                            <h3>Voyage and Return</h3>
                            <p>The protagonist goes to a strange land and, after overcoming the threats it poses or learning important lessons unique to that location, they return with experience.</p>
                        </div>
                    );
                    let comedy =(
                        <div>
                            <h3>Comedy</h3>
                            <p>Light and humorous character with a happy or cheerful ending; a dramatic work in which the central motif is the triumph over adverse circumstance, resulting in a successful or happy conclusion.</p>
                        </div>
                    );
                    let tragedy = (
                        <div>
                            <h3>Tragedy</h3>
                            <p>The protagonist is a hero with a major character flaw or great mistake which is ultimately their undoing. Their unfortunate end evokes pity at their folly and the fall of a fundamentally good character.</p>
                        </div>
                    );
                    let rebirth = (
                        <div>
                            <h3>Rebirth</h3>
                            <p>An event forces the main character to change their ways and often become a better individual.</p>
                        </div>
                    );
                    let rebellion =(
                        <div>
                            <h3>Rebellion Against 'The One'</h3>
                            <p>Concerns a hero who rebels against the all-powerful entity that controls the world until he is forced to surrender to that power.</p>
                        </div>
                    );
                    let mystery0 =(
                        <div>
                            <h3>Mystery</h3>
                            <p>The protagonist comes across a gruesome event, like a murder, and becomes involved in trying to solve the event. The protagonist will go through several trials and tribulations in order to solve the puzzle of the crime or event. The Mystery plot may be a sub-plot of other plots, such as a Quest or Tragedy.'</p>
                        </div>
                    );



                    export const plots = {
                        overcoming,
                        rags,
                        voyage,
                        comedy,
                        tragedy,
                        quest,
                        rebellion,
                        rebirth,
                        mystery0
                    };

    
    let architect = (<div>
                    <h3>Architect</h3>
                    <h5>Most independent</h5>
                    <ul>
                        <li>Theoretical</li>
                        <li>Skeptical</li>
                        <li>Needs to be competent</li>
                        <li>Sees world as chessboard</li>
                        <li>Needs things 'my way'</li>
                        <li>Imaginative and strategic thinkers, with a plan for everything.</li>
                    </ul>
                </div>);

        let logician = (
                <div>
                    <h3>Logician</h3>
                    <h5>Most conceptual</h5>
                    <ul>
                        <li>Challenges others to think</li>
                        <li>High need for competency</li>
                        <li>Socially cautious</li>
                        <li>Independent</li>
                        <li>Innovative inventors with an unquenchable thirst for knowledge</li>
                    </ul>
                </div>

        );

        let commander = (
            <div>
                    <h3>Commander</h3>
                    <h5>Most commanding</h5>
                    <ul>
                        <li>Visionary</li>
                        <li>Gregarious</li>
                        <li>Argumentative</li>
                        <li>Planner</li>
                        <li>Impatient with incompetence</li>
                        <li>Bold, imaginative and strong willed leaders, always finding a way, or making one</li>
                    </ul>
                </div>
        );
                

        let debater = (
        <div>
            <h3>Debater</h3>
            <h5>Most inventive</h5>
            <ul>
                
                <li>Given to brinksmanship</li>
                <li>Tests limits</li>
                <li>Enthusiastic</li>
                <li>Innovative</li>
                <li>Enjoys arguing both sides</li>
                <li>Likes challenges</li>
                <li>Smart and curious thinkers who cannot resist an intellectual challenge</li>
            </ul>
        </div>
        );

        let advocate = (
                <div>
                    <h3>Advocate</h3>
                    <h5>Most reflective</h5>
                    <ul>
                        <li>Introspective</li>
                        <li>Quietly caring</li>
                        <li>Creative</li>
                        <li>Eloquent speaker/writer</li>
                        <li>Visionary</li>
                        <li>Quiet and mystical, yet very inspiring and tireless idealists</li>
                    </ul>
                </div>
        );

        let mediator = (
                <div>
                    <h3>Mediator</h3>
                    <h5>Most idealistic</h5>
                    <ul>
                        <li>Has strong personal values</li>
                        <li>Seeks inner order and peace</li>
                        <li>Creative</li>
                        <li>Non-directive</li>
                        <li>Reserved with people</li>
                        <li>Poetic, kind, and altruistic people, always eager to help a good cause</li>
                    </ul>
                </div>
        );

        let protagonist = (
            <div>
                    <h3>Protagonists</h3>
                    <h5>Most persuasive</h5>
                    <ul>
                        <li>Charismatic</li>
                        <li>Idealistic</li>
                        <li>Ignores unpleasantness</li>
                        <li>Sees the potential in others</li>
                        <li>Compassionate</li>
                        <li>Charismatic and inspiring leaders, able to mesmerize their listeners</li>
                    </ul>
                </div>
        )

        let campaigner = (
            <div>
                <h3>Campaigners</h3>
                <h5>Most optimistic</h5>
                <ul>
                    <li>People oriented</li>
                    <li>Creative</li>
                    <li>Seeks harmony with others</li>
                    <li>Life of the party</li>
                    <li>Better at starting than finishing</li>
                    <li>Enthusiastic, creative, sociable, free spirits, can always find a reason to smile</li>
                </ul>
            </div>
        )

        let logistician = (
                <div>
                    <h3>Logistician</h3>
                    <h5>Most reliable</h5>
                    <ul>
                        <li>Organized</li>
                        <li>Compulsive</li>
                        <li>Private</li>
                        <li>Trustworthy</li>
                        <li>Follows rules</li>
                        <li>Practical and fact minded individuals, who reliability cannot be doubted</li>
                    </ul>
                </div>
        )

        let defender = (
                <div>
                    <h3>Defender</h3>
                    <h5>Most loyal</h5>
                    <ul>
                        <li>Easy to collaborate with</li>
                        <li>Works behind the scenes</li>
                        <li>Sacrifices readily</li>
                        <li>Accountable</li>
                        <li>Very dedicated and warm protectors, ready to defend loved ones</li>
                    </ul>
                </div>
        )

        let executive = (
                <div>
                    <h3>Executive</h3>
                    <h5>Most forceful</h5>
                    <ul>
                        <li>Orderly and structured</li>
                        <li>Sociable</li>
                        <li>Opinionated</li>
                        <li>Results-oriented</li>
                        <li>Productive</li>
                        <li>Traditional</li>
                        <li>Excellent administrators, unsurpassed at managing things or people</li>
                    </ul>
                </div>
        )

        let consul = (
                <div>
                    <h3>Consul</h3>
                    <h5>Most harmonious</h5>
                    <ul>
                        <li>Gracious</li>
                        <li>Thoughtful</li>
                        <li>Lives to please</li>
                        <li>Has strong interpersonal skills</li>
                        <li>Accomplished host/hostess </li>
                        <li>Extraordinarily caring, social and popular people, eager to help</li>
                    </ul>
                </div>
        )

        let virtuoso = (
                <div>
                    <h3>Virtuoso</h3>
                    <h5>Most pragmatic</h5>
                    <ul>
                        <li>Observant</li>
                        <li>Often seen as cool and aloof</li>
                        <li>Practical</li>
                        <li>Unpretentious</li>
                        <li>Ready for anything</li>
                        <li>Bold and practical experimenters, masters of all kinds of tools</li>
                    </ul>
                </div>
        )

        let adventurer = (
                <div>
                    <h3>Adventurer</h3>
                    <h5>Most artistic</h5>
                    <ul>
                        <li>Warm</li>
                        <li>Sensitive</li>
                        <li>Unassuming</li>
                        <li>Team player</li>
                        <li>In touch with self and nature</li>
                        <li>Flexible and charming artists, always ready to explore and experience something new</li>
                    </ul>
                </div>
        )

        let entrepreneur = (
                <div>
                    <h3>Entrepreneur</h3>
                    <h5>Most fun</h5>
                    <ul>
                        <li>Unconventional</li>
                        <li>Fun</li>
                        <li>Gregarious</li>
                        <li>Lives for the here and now</li>
                        <li>Problem solver</li>
                        <li>Smart, energetic, and very perceptive, enjoy living on the edge</li>
                    </ul>
                </div>
        )

        let entertainer =(
                <div>
                    <h3>Entertainer</h3>
                    <h5>Most generous</h5>
                    <ul>
                        <li>Sociable</li>
                        <li>Spontaneous</li>
                        <li>Loves surprises</li>
                        <li>Cuts red tape</li>
                        <li>Juggles many projects</li>
                        <li>Shares readily</li>
                        <li>Spontaneous, energetic, enthusiastic, life is never boring around them</li>
                    </ul>
                </div>
        )

        export const personalities = {architect, logician, commander, debater, advocate, mediator, protagonist, campaigner, logistician, defender, executive, consul, virtuoso, adventurer, entrepreneur, entertainer}


        let lover =(
                <div>
                    <h3>The Lover</h3>
                    <h5>The romantic lead who's guided by the heart.</h5>
                    <ul>
                        <li>Strengths: humanism, passion, conviction</li>
                        <li>Weaknesses: naivete, irrationality</li>
                        <li>Goal: Connection</li>
                        <li>Fear: Isolation</li>
                    </ul>
                </div>
            
        )

        let hero = (
                <div>
                    <h3>The Hero</h3>
                    <h5>The protagonist who rises to meet a challenge and saves the day.</h5>
                    <ul>
                        <li>Strengths: courage, perseverance, honor</li>
                        <li>Weaknesses: overconfidence, hubris</li>
                        <li>Goal: Change the world</li>
                        <li>Fear: Weakness</li>
                    </ul>
                </div>

        )

        let magician = (
                <div>
                    <h3>The Magician</h3>
                    <h5>A powerful figure who has harnessed the ways of the universe to achieve key goals.</h5>
                    <ul>
                        <li>Strengths: omniscience, omnipotence, discipline</li>
                        <li>Weaknesses: corruptibility, arrogance</li>
                        <li>Goal: Alter reality </li>
                        <li>Fear: Unintended results</li>
                    </ul>
                </div>
        )

        let rebel = (
                <div>
                    <h3>The Rebel</h3>
                    <h5>The outlaw who won't abide by society's demands.</h5>
                    <ul>
                        <li>Strengths: independent thinking, virtue, owes no favors</li>
                        <li>Weaknesses: self-involved, potentially criminal</li>
                        <li>Goal: Revolution </li>
                        <li>Fear: No power</li>
                    </ul>
                </div>
        )

        let explorer = (
                <div>
                    <h3>The Explorer</h3>
                    <h5>A character naturally driven to push the boundaries of the status quo and explore the unknown.</h5>
                    <ul>
                        <li>Strengths: curious, driven, motivated by self-improvement</li>
                        <li>Weaknesses: restless, unreliable, never satisfied</li>
                        <li>Goal: Freedom</li>
                        <li>Fear: Entrapment</li>
                    </ul>
                </div>
        )

        let sage = (
                <div>
                    <h3>The Sage</h3>
                    <h5>A wise figure with knowledge for those who inquire. The mother figure or mentor is often based on this archetype.</h5>
                    <ul>
                        <li>Strengths: wisdom, experience, insight</li>
                        <li>Weaknesses: cautious, hesitant to actually join the action</li>
                        <li>Goal: Knowledge</li>
                        <li>Fear: Deception</li>
                    </ul>
                </div>
        )

        let innocent = (
                <div>
                    <h3>The Innocent</h3>
                    <h5>morally pure character, often a child, whose only intentions are good.</h5>
                    <ul>
                        <li>Strengths: morality, kindness, sincerity</li>
                        <li>Weaknesses: vulnerable, naive, rarely skilled</li>
                        <li>Goal: Happiness</li>
                        <li>Fear: Punishment</li>
                    </ul>
                </div>

        )

        let creator = (
                <div>
                    <h3>The Creator</h3>
                    <h5>A motivated visionary who creates art or structures during the narrative.</h5>
                    <ul>
                        <li>Strengths: creativity, willpower, conviction</li>
                        <li>Weaknesses: self-involvement, single-mindedness, lack of practical skills</li>
                        <li>Goal: Realize vision</li>
                        <li>Fear: Mediocrity</li>
                    </ul>
                </div>
        )

        let ruler = (
                <div>
                    <h3>The Ruler</h3>
                    <h5>A character with legal or emotional power over others.</h5>
                    <ul>
                        <li>Strengths: omnipotence, status, resources</li>
                        <li>Weaknesses: aloofness, disliked by others, out of touch</li>
                        <li>Goal: Prosperity </li>
                        <li>Fear: Overthrown</li>
                    </ul>
                </div>
        )

        let caregiver = (
                <div>
                    <h3>The Caregiver</h3>
                    <h5>A character who continually supports others and makes sacrifices on their behalf.</h5>
                    <ul>
                        <li>Strengths: honorable, selfless, loyal</li>
                        <li>Weaknesses: lacking personal ambition or leadership</li>
                        <li>Goal: Help others</li>
                        <li>Fear: Selfishness</li>
                    </ul>
                </div>
        )

        let everyman = (
                <div>
                    <h3>The Everyman</h3>
                    <h5>A relatable character who feels recognizable from daily life.</h5>
                    <ul>
                        <li>Strengths: grounded, salt-of-the-earth, relatable</li>
                        <li>Weaknesses: lacking special powers, often unprepared for what’s to come</li>
                        <li>Goal: Belonging</li>
                        <li>Fear: Exclusion</li>
                    </ul>
                </div>
        )

        let jester = (
                <div>
                    <h3>The Jester</h3>
                    <h5>A funny character or trickster who provides comic relief, but may also speak important truths.</h5>
                    <ul>
                        <li>Strengths: funny, disarming, insightful</li>
                        <li>Weaknesses: can be obnoxious and superficial</li>
                        <li>Goal: Levity and fun</li>
                        <li>Fear: Boredom</li>
                    </ul>
                </div>
        )

         export const characters = {innocent, everyman, hero, caregiver, explorer, rebel, lover, creator, jester, sage, magician, ruler}

        

         let positive = (
                <div>
                    <h3>Positive Arc</h3>
                    <h5>Character believes lie, overcomes lie, new truth is liberating.</h5>
                </div>
         );
         let flat = (
                <div>
                    <h3>Flat Arc</h3>
                    <h5>Character believes Truth, maintains Truth, uses Truth to overcome world's Lie.</h5>
                </div>
         );
         let corruption = (
                <div>
                    <h3>Corruption Arc</h3>
                    <h5>Character sees Truth, rejects Truth, embraces Lie.</h5>
                </div>
         );
         let disillusionment = (
                <div>
                    <h3>Disillusionment Arc</h3>
                    <h5>Character believes Lie, overcomes Lie, new Truth is tragic.</h5>
                </div>
         );
         let fall = (
                <div>
                    <h3>The Fall</h3>
                    <h5>Character believes Lie, clings to Lie, rejects new Truth, believes worse Lie.</h5>
                </div>
         );

        export const arcs = {positive,flat,corruption,disillusionment, fall}


            let action = (
                <div>
                    <h3>Action</h3>
                    <ul>
                        <li>Begin in the middle of the action</li>
                        <li>Grabs the reader's attention right away, adding context later on.</li>
                    </ul>
                </div>
            )

            let analogy = (
                <div>
                    <h3>Analogy</h3>
                    <ul>
                        <li>Compare two or more things.</li>
                        <li>A way to draw the reader into the themes of the story, possibily the protagonist/narrator's personality as well.</li>
                    </ul>

                </div>
            )

            let command = (
                <div>
                    <h3>Command to the Reader</h3>
                    <ul>
                        <li>Sets the tone right away, breaking the fourth wall.</li>
                        <li>Establishes the role of the narrator in the story.</li>
                    </ul>

                </div>
            )

            let boldStatement = (
                <div>
                    <h3>Bold Statement</h3>
                    <ul>
                        <li>Something shocking or interesting to catch the reader's attention and draw them in.</li>
                      
                    </ul>
                </div>
            )

            let thoughts = (
                <div>
                    <h3>Character's Thoughts/Feelings</h3>
                    <ul>
                        <li>Introduces the protagonist, establishes the story question through their fears or desires.</li>
                        <li>Should entice the reader to want to see what happens to them.</li>
                    </ul>

                </div>
            )

            let definition = (
                <div>
                    <h3>Definition</h3>
                    <ul>
                        <li>Define a word that encapsulates the theme of the story.</li>
                    </ul>
                </div>
            )

            let dialogue = (
                <div>
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Jumping right into dialogue quick-starts the story and drama.</li>
                        <li>The reader is forced right into the heart of the scene.</li>
                    </ul>
                </div>
            )

            let foreshadowing = (
                <div>
                    <h3>Foreshadowing</h3>
                    <ul>
                        <li>Set up events that will unfold later on in the story.</li>
                        <li>Could be a hint or clue of something to come that the reader won't recognize until it's revealed later on.</li>
                    </ul>
                </div>
            )

            let onomatopoeia = (
                <div>
                    <h3>Onomatopoeia</h3>
                    <ul>
                        <li>A word that is also the sound, catches the reader's attention.</li>
                    </ul>
                </div>
            )

            let question = (
                <div>
                    <h3>Question</h3>
                    <ul>
                        <li>Asks the reader a question, to be answered later on, or to reveal theme.</li>
    
                    </ul>
                </div>
            )

            let themeStatement = (
                <div>
                    <h3>Theme Statement</h3>
                    <ul>
                        <li>Tells the reader exactly what the story is going to be about.</li>
                        <li>Themes are vague and wide, so the specifics are not revealed.</li>
                    </ul>

                </div>
            )

            let sentenceFragment = (
                <div>
                    <h3>Sentence Fragment</h3>
                    <ul>
                        <li>A short fragment of a sentence that leaves reader wanting more.</li>
                        <li>What does it mean? Where is this going?</li>
                    </ul>
                </div>
            )

            let setting = (
                <div>
                    <h3>Setting</h3>
                    <ul>
                        <li>Paints a picture of the setting with words.</li>
                        <li>Draws the reader in with sensory details, building the world around them and placing them in it.</li>
                    </ul>
                </div>
            )


            export const hooks = {
                action,
                analogy,
                command,
                boldStatement,
                thoughts,
                definition,
                dialogue,
                foreshadowing,
                onomatopoeia,
                question,
                themeStatement,
                sentenceFragment,
                setting
            };




            let circular = (<div>
                    <h3>Circular</h3>
                    <ul>
                        <li>The story circles back to the beginning</li>
                        <li>Sometimes ending on the same idea or theme, even the exact same or similar words as the beginning of the story.</li>
                    </ul>

                </div>)
            let twist =(
                <div>
                    <h3>Twist</h3>
                    <ul>
                        <li>The story takes you where you didn't expect it to go.</li>
                        <li>An unforeseen ending, but in hindsight it makes sense if properly foreshadowed.</li>
                    </ul>
                </div>)
                
                let moral = (
                <div>
                    <h3>Moral</h3>
                    <ul>
                        <li>The main character grows, changes, or learns something positive at the end.</li>
                       
                    </ul>
                </div>)

                let warm = 
                (<div>
                    <h3>Warm & Fuzzy</h3>
                    <ul>
                        <li>The story ends leaving the reader feeling emotional or good inside.</li>
                        <li>Tugs at the heart strings</li>
                    </ul>
                </div>)

                let reflection =
                (<div>
                    <h3>Reflection</h3>
                    <ul>
                        <li>The narrator steps back and reflects on what just happened.</li>
                        <li>They often look back on the experience and determine the importance of it and what was learned.</li>
                    </ul>
                </div>)

                let cliffhanger = 
                (<div>
                    <h3>Cliffhanger</h3>
                    <ul>
                        <li>The story ends leaving the reader hanging or wanting more.</li>
                        <li>Unresolved ending, good for the end of chapters or book series.</li>
                    </ul>
                </div>)

                let question2 =
                (<div>
                    <h3>Question</h3>
                    <ul>
                        <li>Ends with a question to keep the reader thinking.</li>
                        <li>The question usually involves the reader and is used to make the writing memorable.</li>
                    </ul>
                </div>)

                let humorous = 
                (<div>
                    <h3>Humorous</h3>
                    <ul>
                        <li>Ends with a funny thought or statement, bringing levity to the story or situation, and making it more memorable.</li>
                        
                    </ul>
                </div>)

                let image =(   
                <div>
                    <h3>Image</h3>
                    <ul>
                        <li>Ends with an important scene that the writer shows the reader through vivid details. </li>
                        <li>By showing and not telling, the writer touches the reader's emotions and conveys a mood.</li>
                    </ul>
                </div>)

                let dialogue2 = 
                (<div>
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Ends with an important conversation or quote.</li>
                        <li>Reveals character's personality</li>
                    </ul>
                </div>)


            export const endings = {
                circular,
                twist,
                moral,
                warm,
                reflection,
                cliffhanger,
                question2,
                humorous,
                image,
                dialogue2
            }



            let juxtaposition =(
                <div>
                <h3>Juxtaposition</h3>
                <p><em>Description:</em> Placing two or more elements side by side for the purpose of comparison or contrast.</p>
                <p><em>Example:</em> In the bustling city, the homeless man slept peacefully on a park bench.</p>
                </div>
            );
            let macguffin = (
                <div>
                <h3>Macguffin</h3>
                <p><em>Description:</em> A Macguffin is a plot device, typically an object or goal, that propels the story by motivating the characters. It adds mystery and suspense without intrinsic significance, remaining interchangeable to maintain focus on the characters and their journey.</p>
                <p><em>Example:</em> The mysterious briefcase in the film that everyone is after, but its contents are never revealed.</p>
                </div>
            );
            let framingDevice = (
            <div>
                <h3>Framing Device</h3>
                <p><em>Description:</em> A narrative structure where a story is enclosed within another story, serving as a framing element.</p>
                <p><em>Example:</em> The main narrative is presented as a manuscript discovered by a character in the framing story.</p>
            </div>);
            let redHerring = (
                <div>
                    <h3>Red Herring</h3>
                    <p><em>Description:</em> A misleading clue or distraction intentionally inserted to divert attention from the real issue or plot point.</p>
                    <p><em>Example:</em> The detective follows a false lead that initially seems crucial to solving the mystery.</p>
                </div>
            );
            let chekhovsGun =(
                <div>
                    <h3>Chekhov's Gun</h3>
                    <p><em>Description:</em> The narrative principle that states every element in a story should be necessary and irreplaceable, and any element that is not should be removed.</p>
                    <p><em>Example:</em> A seemingly insignificant object introduced early in the story becomes crucial to the resolution later on.</p>
                </div>
            );
            let tickingClock = (
                <div>
                    <h3>Ticking Clock</h3>
                    <p><em>Description:</em> Introduction of a time constraint or impending deadline, creating tension and a sense of urgency in the narrative.</p>
                    <p><em>Example:</em> The characters must complete a mission before a bomb detonates, adding urgency to the plot.</p>
                </div>
            );
            let prophecy = (
                <div>
                    <h3>Self-fulfilling Prophecy</h3>
                    <p><em>Description:</em> A prediction or belief that influences a person's behavior in such a way that it causes the prediction to come true.</p>
                    <p><em>Example:</em> A character, fearing betrayal, takes actions that drive their friends away, fulfilling the fear of betrayal.</p>
                </div>
            );
            let alliteration =(
                <div>
                <h3>Alliteration</h3>
                <p><em>Description:</em> Repetition of the same consonant sound at the beginning of neighboring words.</p>
                <p><em>Example:</em> Peter Piper picked a peck of pickled peppers.</p>
                </div>
            );
            let predestination = (
                <div>
                    <h3>Predestination</h3>
                    <p><em>Description:</em> The idea that events or outcomes are predetermined and cannot be changed, often tied to a sense of destiny.</p>
                    <p><em>Example:</em> Characters discover a prophecy that foretells the specific events of their lives.</p>
                </div>
            );
            let unreliableNarrator = (
                <div>
                    <h3>Unreliable Narrator</h3>
                    <p><em>Description:</em> A narrator whose credibility or reliability is compromised, intentionally or unintentionally, leading to a narrative where the audience questions the truth of the events.</p>
                    <p><em>Example:</em> The narrator with a personal bias or mental instability presents events in a way that distorts the true nature of the story.</p>
                </div>
            );
            let symbolism =(
                <div>
                <h3>Symbolism</h3>
                <p><em>Description:</em> The use of symbols to represent ideas or qualities.</p>
                <p><em>Example:</em> The dove is often a symbol of peace.</p>
                </div>
            );
            let poeticJustice = (
                <div>
                    <h3>Poetic Justice</h3>
                    <p><em>Description:</em> The concept that virtue is ultimately rewarded, and vice is punished in a manner that is fitting or appropriate.</p>
                    <p><em>Example:</em> The villain who exploited others for personal gain faces a downfall that mirrors their own actions.</p>
                </div>
            );

            export const litDevices = {
                alliteration,
                poeticJustice,
                symbolism,
                unreliableNarrator,
                predestination,
                prophecy,
                tickingClock,
                chekhovsGun,
                redHerring,
                macguffin,
                juxtaposition,
                framingDevice,
            }


            let first = 
            (<div>
                <h3>First-Person Narrative</h3>
                <p><em>Description:</em> The story is told from the perspective of a character within the story using first-person pronouns (I, we).</p>
                <p><em>Example:</em> I walked into the room and saw a mysterious figure standing by the window.</p>
            </div>)

            let thirdLimited =
            (<div>
                <h3>Third-Person Limited</h3>
                <p><em>Description:</em> The narrator is external to the story and focuses on the thoughts and feelings of a single character.</p>
                <p><em>Example:</em> She felt a wave of anxiety as she approached the interview room.</p>
            </div>)

            let thirdO =
            (<div>
                <h3>Third-Person Omniscient</h3>
                <p><em>Description:</em> The narrator is external and has knowledge of the thoughts and feelings of all characters in the story.</p>
                <p><em>Example:</em> Little did they know, a surprise awaited them just around the corner.</p>
            </div>)

            let second =
            (<div>
                <h3>Second-Person Narrative</h3>
                <p><em>Description:</em> The narrator directly addresses the reader using second-person pronouns (you).</p>
                <p><em>Example:</em> You open the old, creaky door and step into the mysterious room.</p>
            </div>)

            let parallel =
            (<div>
                <h3>Parallel Narrative</h3>
                <p><em>Description:</em> Multiple storylines occurring simultaneously, often with connections or thematic parallels.</p>
                <p><em>Example:</em> The narrative alternates between different characters or settings, gradually revealing the connections between their experiences.</p>
            </div>)

            let stream =
            (<div>
                <h3>Stream of Consciousness</h3>
                <p><em>Description:</em> A narrative technique that presents a character's thoughts and feelings as they occur in the character's mind, often without a clear structure.</p>
                <p><em>Example:</em> The rain began to fall, each droplet tapping on the window like a secret code. I wonder if the world outside feels the same weight I carry within me. The coffee on the table grows cold, forgotten amid the chaos of memories and unspoken words. How did I end up here...</p>
            </div>)

            let epistolary =
            (<div>
                <h3>Epistolary</h3>
                <p><em>Description:</em> The story is told through a series of letters, diary entries, or other written documents.</p>
                <p><em>Example:</em> The old letters revealed the secrets of a love long forgotten.</p>
            </div>)

            let objective =
            (<div>
                <h3>Objective Narrative</h3>
                <p><em>Description:</em> The narrator remains a detached observer, presenting only the actions and dialogue of the characters without delving into their thoughts.</p>
                <p><em>Example:</em> The clock struck midnight, and the protagonist entered the room without saying a word.</p>
            </div>)

            let linear = 
            (<div>
                <h3>Linear Narrative</h3>
                <p><em>Description:</em> The story unfolds in a chronological sequence, moving forward in time without significant jumps or interruptions.</p>
                <p><em>Example:</em> The protagonist's journey began on a sunny morning and continued through a series of challenges and triumphs.</p>
            </div>)

            let nonLinear = 
            (<div>
                <h3>Nonlinear Narrative</h3>
                <p><em>Description:</em> The story is presented out of chronological order, with events occurring in a sequence other than a straight timeline.</p>
                <p><em>Example:</em> Flashbacks, flash-forwards, and time loops contribute to a narrative structure that challenges traditional linear storytelling.</p>
            </div>)

            export const narStyles = {
                first,
                second,
                thirdLimited,
                thirdO,
                objective,
                linear,
                nonLinear,
                stream,
                parallel,
                epistolary
            }

            export const enneagrams = [
                // Type 1: The Reformer
                (<div>
                    <h3>Type 1: The Reformer</h3>
                    <p><strong>The Reformer's Positive Arc: Resentment to Integrity</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to make mistakes."</p>
                    <p>Also known as the Perfectionist, Type Ones strive for responsibility and idealism. Their journey challenges them to move from resentment towards integrity, embracing all aspects of themselves and the world around them.</p>
                    <p><strong>The Reformer's Negative Arc: From Resentment to Tyranny</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You are good."</p>
                    <p>Type Ones' native desire to bring integrity to themselves and the world around them can become complicated by an unconscious fear that they are, in fact, inherently bad or corrupt in some way. This pushes these characters toward perfectionism, first in small ways, and then, if unchecked, into an obsessive tyranny that desires to control others as well.</p>
                </div>),
            
                // Type 2: The Helper
                (<div>
                    <h3>Type 2: The Helper</h3>
                    <p><strong>The Helper's Positive Arc: Pride to Unconditional Love</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to have your own needs."</p>
                    <p>Also known as the Caretaker, Type Twos navigate from pride towards unconditional love, learning to serve authentically while also honoring their own needs.</p>
                    <p><strong>The Helper's Negative Arc: From Pride to Manipulation</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You are wanted."</p>
                    <p>Type Twos' native desire to love and be loved can become complicated by an unconscious fear that they are, in fact, unworthy of love. Defensively, these characters then begin to over-give to others and to over-value this service (a source of “pride”), as they conflate the “need to be needed” with their true need of love.</p>
                </div>),
            
                // Type 3: The Achiever
                (<div>
                    <h3>Type 3: The Achiever</h3>
                    <p><strong>The Achiever's Positive Arc: Vanity to Authenticity</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to have your own feelings and identity."</p>
                    <p>Also known as the Role Model, Type Threes progress from vanity towards authenticity, realizing their inherent worth beyond external achievements.</p>
                    <p><strong>The Achiever's Negative Arc: From Vanity to Grandiosity</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You are loved for yourself."</p>
                    <p>Type Threes’ native desire to offer value to the world can become complicated by an unconscious fear that they are, in fact, without inherent value–that their only value is what they do, not who they are. Defensively, these characters then begin to chase after success and external affirmation as a way of pumping up their own self-image.</p>
                </div>),
            
                // Type 4: The Individualist
                (<div>
                    <h3>Type 4: The Individualist</h3>
                    <p><strong>The Individualist's Positive Arc: Envy to Equanimity</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to be too functional or too happy."</p>
                    <p>Also known as the Romantic, Type Fours journey from envy towards equanimity, embracing their uniqueness and finding self-acceptance.</p>
                    <p><strong>The Individualist's Negative Arc: From Envy to Self-Obsession</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You are seen for who you are."</p>
                    <p>Type Fours’ native desire to be authentically themselves can become complicated by an unconscious fear that they have no true personal significance or identity. Defensively, these characters then begin to hide behind a victim mentality that leads them to envy the “perfect” lives of others, or to hide behind romantic daydreams about what their lives could be.</p>
                </div>),
            
                // Type 5: The Investigator
                (<div>
                    <h3>Type 5: The Investigator</h3>
                    <p><strong>The Investigator's Positive Arc: Avarice to Non-Attachment</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to be comfortable in the world."</p>
                    <p>Also known as the Observer, Type Fives transition from avarice towards non-attachment, learning to engage with the world without fear of depletion.</p>
                    <p><strong>The Investigator's Negative Arc: From Avarice to Reclusion</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "Your needs are not a problem."</p>
                    <p>Type Fives’ native desire for competency and usefulness can become complicated by the unconscious fear that they lack the ability to interface with the world. Defensively, these characters then begin to conserve their personal resources (“avarice”), believing the world will demand too much from them.</p>
                </div>),
            
                // Type 6: The Loyalist
                (<div>
                    <h3>Type 6: The Loyalist</h3>
                    <p><strong>The Loyalist's Positive Arc: Anxiety to Inner Peace</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to trust yourself."</p>
                    <p>Also known as the Traditionalist, Type Sixes evolve from anxiety towards inner peace, developing self-reliance and faith in life.</p>
                    <p><strong>The Loyalist's Negative Arc: From Anxiety to Dogmatism</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You are safe."</p>
                    <p>Type Sixes’ native desire for safety and security can become complicated by the unconscious fear that they are unable to offer themselves the support and guidance they need in order to feel safe.</p>
                </div>),
            
                // Type 7: The Enthusiast
                (<div>
                    <h3>Type 7: The Enthusiast</h3>
                    <p><strong>The Enthusiast's Positive Arc: Gluttony to Joy</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to depend on anyone for anything."</p>
                    <p>Also known as the Energizer, Type Sevens progress from gluttony towards joy, embracing life fully and authentically.</p>
                    <p><strong>The Enthusiast's Negative Arc: From Gluttony to Escapism</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You will be taken care of."</p>
                    <p>Type Sevens’ native desire for happiness can become complicated by an unconscious fear that they are, in fact, likely to suffer deprivation or pain. Defensively, these characters fixate on seeking positive experiences (to the point of “gluttony”) and avoiding any and all negative experiences.</p>
                </div>),
            
                // Type 8: The Challenger
                (<div>
                    <h3>Type 8: The Challenger</h3>
                    <p><strong>The Challenger's Positive Arc: Intensity to Innocence</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to be vulnerable or trust anyone."</p>
                    <p>Also known as the Leader, Type Eights move from intensity towards innocence, reclaiming vulnerability and trust.</p>
                    <p><strong>The Challenger's Negative Arc: From Intensity to Violence</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "You will not be betrayed."</p>
                    <p>Type Eights’ native desire to feel protected and self-sovereign can become complicated by the unconscious fear that they are vulnerable to being controlled or harmed by others. Defensively, these characters push back as a way of feeling their own strength and invulnerability.</p>
                </div>),
            
                // Type 9: The Peacemaker
                (<div>
                    <h3>Type 9: The Peacemaker</h3>
                    <p><strong>The Peacemaker's Positive Arc: Resignation to Right Action</strong></p>
                    <p><em>Core Lie the Character Believes:</em> "It’s not okay to assert yourself."</p>
                    <p>Also known as the Healer, Type Nines transition from resignation towards right action, finding their voice and asserting themselves for the greater good.</p>
                    <p><strong>The Peacemaker's Negative Arc: From Apathy to Disassociation</strong></p>
                    <p><em>Core Truth the Character Fails to Believe:</em> "Your presence matters."</p>
                    <p>Type Nines’ native desire to be at peace with everyone and everything can become complicated by an unconscious fear that they are, in fact, irreparably separate from others.</p>
                </div>)
            ];