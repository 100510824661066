import React from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import './prompts.css'
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";



export const Prompts = () => {
  //window.onload = function() {
    // similar behavior as clicking on a link
  //window.location.href = "http://localhost:3000/prompt-generators/promptHome";
//}

    return (
        <div className='page'>
          <LeftSide/>

          <div className='content' id='prompts'>
          <header>
            <Link to={'/prompt-generators'} className='link'><h1>Super Random Story Prompt Generator</h1></Link>
          </header>
          <nav className='lineNav'>
            <ul>
              <NavLink to={'plotPrompt'} className='link'>Full Plot Prompt</NavLink>
              <NavLink to={'characterPrompt'} className='link'>Character</NavLink>
              <NavLink to={'settingPrompt'} className='link'>Setting</NavLink>
              <NavLink to={'randomPrompt'} className='link'>Random Story Elements</NavLink>
              <NavLink to={'premisePrompt'} className='link'>Story Premise</NavLink>
              <NavLink to={'uspPrompt'} className='link'>USP</NavLink>
              <NavLink to={'redditPrompt'} className='link'>r/WritingPrompts</NavLink>
            </ul>
          </nav>

              <Outlet />
        </div>

          <RightSide/>
        </div>
        
    )

}