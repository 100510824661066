import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Get5 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;
    const step5 = getIdea.step5;

    let [input5, setInput5] =useState('')

    const handleChange =({target})=> {
            setInput5(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput5('')
        }

    return(
        <div id='step-five'className='step'>
            <h3>Step 5: Give Your Character a Goal and a Flaw</h3>
            <p className='step-p'>Your goal should be tied to your theme, genre, plot archetype and character.</p>
            <p className='step-p'><b>To understand your character and their goal better, you need to consider five things: <i>want, need, truth, lie, and weakness</i>.</b></p>
            <div id=''className = 'stepList'>
               <ol>
                    <li>Want</li>
                        <ul>
                            <li>external</li>
                            <li>known by character</li>
                            <li>drives the plot</li>
                            <li>unique to your character</li>
                            <li>example:  Wants to save the world</li>
                        </ul>
                    <li>Need</li>
                        <ul>
                            <li>internal</li>
                            <li>unknown by character</li>
                            <li>drives the plot</li>
                            <li>related to theme</li>
                            <li>heart – gives depth and satisfying emotional journey</li>
                            <li>universal – can be applied to everyone</li>
                            <li>example: Needs to change/grow as a person</li>
                            <h6>Your character will spend most of the story pursuing their outer goal, their Want, but what the story is really about on a deeper level is their growth into a place where they, first subconsciously, then consciously, recognizes and pursues their inner goal, the thing they Need.</h6>

                        </ul>
                    <li>Truth</li>
                        <ul>
                            <li>Tied to need, this is what your character needs to realize.</li>
                        </ul>
                    <li>Lie</li>
                        <ul>
                            <li>
                            The Lie your character believes, what stops your character from achieving what they truly need to be complete.
                            </li>
                        </ul>
                    <li>Weakness</li>
                        <h6>Choose at least one flaw, and a handicap or limitation for more potential conflict.</h6>
                        <ul>
                            <li>Flaw</li>
                                <ul>
                                    <li>Something the character has control over that they should have fixed by now.</li>
                                </ul>
                            <li>Handicap</li>
                            <ul>
                                    <li>Something that must be overcome, but it's not the character’s fault, and they have no power over whether or not this thing can change. (though it may by outside means.)</li>
                                </ul>
                            <li>Limitation</li>
                            <ul>
                                    <li>Something not to change or overcome, but to work within limits. This should generally not change, and we wouldn’t even want them to change it. (loves their grandma, has to protect her)</li>
                                </ul>
                        </ul>
                </ol>
            </div>
            <p id='example'>Example 1<span>
                Let's say we’re dealing with lost honor. A defender is loyal, so losing his honor would be a crushing blow. Brainstorm how this could have happened. Clearly he needs to get it back. How will he do that? We can start with an abstract goal and zero in on a more concrete goal later on...<br/>
                Our Defender Lover wants to restore his honor, but what he really needs is to learn to value himself and others outside of the chain of command. His lie, is that only his king/commander/leader can restore his honor. His truth then, is that honor and self worth only comes from within. <br/>
                Flaw: a Defender is known to be humble and shy, takes things too personally, represses their feelings, is reluctant to change, overloads themselves and can be too altruistic . Let’s say he’s a push over. He values his humbleness and altruism to a fault, so he’ll have to learn to stand up for himself and learn to say no.<br/>
                Handicap: He could be of a minority race or born into a low caste. Maybe he isn’t as intellectually capable as his peers (which is why he always toes the line.) Perhaps he’s homosexual and that is frowned upon by his society. Or, since it is a Space Opera, maybe all humans are low caste. Or, he was naturally born where most others were genetically designed…Yea, that last one sounds all right.<br/>
                Limitation: Won’t kill in cold blood </span></p>
                <p id='example'>Example 2<span>
                Our Virtuoso Rebel struggles with the will to survive. He’s on a Quest, so let’s say he’s stranded in the wilderness after going off on a crazy archaeological treasure hunt. Now he has to use his virtuoso wits to escape danger and get him out of there with the ancient relic...<br/>
                Our virtuoso rebel wants to escape with the relic, that is his external goal, though perhaps he needs to understand the value of human life, sacrificing the relic to save his partners. His lie is then that the relic matters above all else, while his truth is that human relationships are worth more than ancient treasures. Maybe our central theme shifts from the will to survive, (which will still have its place,) to sacrifice, or friendship.<br/>
                Flaw:  A Virtuoso can be stubborn, noncommittal, easily bored, risky, and insensitive. Let’s say he is just too stubborn. He won’t give up the relic. He never leaves a hunt without finding what he’s looking for. Well, this time, he’ll have to.<br/>
                Handicap: He could have a phobia of snakes. (Just kidding, Dr. Jones.) Maybe he’s allergic to bees, he only has one arm, he is very short, he is blind in one eye, deaf in one ear, he has emotional trauma from childhood abuse, has nightmares, is grieving the loss of his wife or family, has dissociative identity disorder…Let say he’s in grief. He’s just lost his wife, who was his partner, and this is his first expedition without her.<br/>
                Limitation: He finds a native child along the way he has to protect.<br/>
                </span>
            </p>
            <div className='input'>
                <label>Write your goal and flaw(s) here:  </label>
                <textarea rows = "2" cols = "20" id='step5' className='stepInput' type='text' value={input5} onChange={handleChange}>
                </textarea>
                {input5!==''&&<button onClick={()=>handleClick('step5', input5)}id='button2'>Enter</button>}
                
            </div>
            <div className='savedInput'>
                <p>Genre(s): <b>{step1}</b></p>
                <p>Plot Archetype: <b>{step2}</b></p>
                <p>Character: <b>{step3}</b></p>
                <p>Theme(s):<b>{step4}</b></p>
                <p>Want, Need, Truth, Lie, Flaws & Weaknesses: <b>{step5}</b></p>

            </div>
            <div className='stepArrows'>
                <Link to='../get-idea-step-4'><button id='button2'><span>Back</span></button></Link>
                <Link to='../get-idea-step-6'><button id='button2'><span>Next</span></button></Link>
            </div>       
        </div>
             
       
    )
}