import React, { useState, useEffect } from "react"
import {selectRedditPrompt, getPrompt } from "./redditPromptSlice"
import { useDispatch,useSelector } from "react-redux"
import { RedditSave } from "./redditSave";
import { selectLoading } from "./redditPromptSlice";

export const RedditPrompt = () => {

    const dispatch = useDispatch();
    const prompt = useSelector(selectRedditPrompt);
    const loading = useSelector(selectLoading);

    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({})

    useEffect(()=>{
        if(prompt[0] !== undefined){
            setSave(true);
            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[prompt])

    
    
    const handleClick = () => {
        dispatch(getPrompt());
        save? setSave(false): setSave(true)
    }

    return (
        <div className='prompt'id='redditPrompt'> 
           {!loading && <p id='redditP' style={style}>{prompt[0]} {prompt[1]}</p>}
           {loading && <p id='loading'>&#x22C7;</p>}
            <button id='redditButton' onClick={()=>handleClick()}>Fetch New Prompt</button>
            {save && <RedditSave style={style}/>}
        </div>
    )
}
