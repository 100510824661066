import React, {useState, useEffect} from "react";
import { selectSettingPrompt,setGen } from "./settingPromptSlice";
import { useSelector,useDispatch } from "react-redux";
import { SettingSave } from "./settingSave";

export function SettingPrompt(){

    const dispatch = useDispatch();
    const settingPrompt = useSelector(selectSettingPrompt);
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(()=>{
        if(settingPrompt !== ''){
            setSave(true);
            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
        
            setStyle(style1)
        }
    },[settingPrompt])

    const handleClick = () => {
        dispatch(setGen());
        save? setSave(false): setSave(true)
    }

    return(
        <div className='prompt'id='settingPrompt'>
            <p style={style}>{settingPrompt}</p>
            <button onClick={() => handleClick()}>Generate New Setting</button>
            {save && <SettingSave style={style}/>}
        </div>
    )
}