import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write5 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 5: Create a Three Act Outline</h3>
            <p className='step-p'>This is a more in-depth outline of your major plot points. Write about a paragraph for each one, depending on the length you have in mind for your story. </p>
            <ul className='plotList'>
                <div className = 'actBox'>
                    <li><h2>First Act</h2></li>
                    <ul className='actList'>
                        <li><b>Hook</b> 1%<ul><li>Opening moment, opening scene, first thing that happens, possibly first line.</li></ul></li>
                        <li><i>set-up</i> 1-12%<ul><li>Readers learn about your characters, their goals, and the stakes.</li></ul></li>
                        <li><b>Inciting Incident</b> 12%<ul><li>A turning point occurs halfway through the First Act. The Call to Adventure, the moment the Normal World is first significantly rocked by the conflict.</li></ul></li>
                        <li><i>build-up</i> 12-25%<ul><li>The final pieces necessary for the main conflict are moved into position, while ramping up the tension.</li></ul></li>
                        <li><b>1st Plot Point </b> 25%<ul><li>The doorway between the end of the First Act and the beginning of the Second. Also likely to be the Key Event. Establishes the Story Question. The Point of No Return.</li></ul></li>
                    </ul>
                </div>
                <div className = 'actBox'>
                    <li><h2>Second Act</h2></li>
                    <ul className='actList'>
                        <li><i>reaction</i> 25-37%<ul><li>After the 1st Plot Point, protagonist scrambles to understand obstacles thrown in his way by the antagonist. </li></ul></li>
                        <li><b>1st Pinch Point</b> 37%<ul><li>First battle, opposition shows themselves. A reminder of the antagonist’s power, which provides new clues about the nature of the conflict.</li></ul></li>
                        <li><i>realization</i> 37-50%<ul><li>Protagonist’s realization grows and his reactions become more informed.</li></ul></li>
                        <li><b>Midpoint</b> 50%<ul><li>The Moment of Truth when protagonist realizes the central truth about the nature of the conflict. Shift from reactive victim to proactive warrior.</li></ul></li>
                        <li><i>action</i> 50-62%<ul><li>Thanks to his new understanding, the protagonist makes headway against the antagonist.</li></ul></li>
                        <li><b>2nd Pinch Point</b> 62%<ul><li>Second battle, antagonist strikes back. Foreshadows 3rd Plot Point and serves to remind the protagonist of what’s at stake. </li></ul></li>
                        <li><i>renewed push</i> 62-75%<ul><li>Protagonist renews attack upon the antagonist. He reaches a seeming victory.</li></ul></li>
                    </ul>

                </div>
                <div className = 'actBox'>
                    <li><h2>Third Act</h2></li>
                    <ul className='actList'>
                        <li><b>3rd Plot Point</b> 75%<ul><li>A dark moment for the character. After the victory at the end of the Second Act, he experiences a reversal. Ramp up for third act. Worse thing ever in terms of story question, dark night of the soul, sacrifice, antagonist wins. </li></ul></li>
                        <li><i>recovery</i> 75-88%<ul><li>Protagonist reels as he questions his choices, his commitment to his goal, and his own worth and ability.</li></ul></li>
                        <li><b>Climax Begins</b> 88%<ul><li>This turning point forces the protagonist and the antagonist to finally face each other in the Final Battle, moment of triumph/knowledge. </li></ul></li>
                        <li><i>confrontation</i> 88-98%<ul><li>Duel to the (literal or metaphoric) death. What occurs here ensures protagonist and antagonist cannot both walk away.</li></ul></li>
                        <li><b>Climatic Moment</b> 98%<ul><li>The moment the protagonist’s goal is met, its becomes a physical impossibility for the conflict to continue. When the reader is meant to stand up and cheer, or sit down and cry.</li></ul></li>
                        <li><i>resolution</i> 98-100%<ul><li>Ease readers out of the excitement of the Climax into the final emotion.</li></ul></li>
                    </ul>

                </div>

                
                    
            </ul>
            
           
            <div className='stepArrows'>
                <Link to='../write-idea-step-4/positive-arc'><button id='button2'><span>Back</span></button></Link>
                <Link to='../write-idea-step-6'><button id='button2'><span>Next</span></button></Link>
            </div>

            <p></p>

        </div>
    )}