import React from "react";
import { NavLink } from "react-router-dom";

export const StoryHome = () => {
    return(
        <div>
            <header>
                <h1>Story Elements</h1>
            </header>
            <div className="navBoxes">
                
                <NavLink to='plot-elements' className='boxLink'>
                        Plot
                </NavLink>
                    
                <NavLink to='character-elements'className='boxLink'>
                       Character
                </NavLink>
                <NavLink to='craft-elements'className='boxLink'>
                        Craft
                </NavLink>
                
                </div>
        </div>
    )
}