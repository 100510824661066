import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { genres } from "../prompts/plotPrompt/descriptions";

export const Get1 = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1;

    let [input1, setInput1] =useState('')

    const handleChange =({target})=> {
            setInput1(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput1('')
        }


        function showGenre(genre){
            let description;
                if(genre==='sci-fi'){
                    description = genres.scifi
                }
                if(genre==='fantasy'){
                    description = genres.fantasy
                }
                if(genre==='mystery'){
                    description = genres.mystery
                }
                if(genre==='thriller'){
                    description = genres.thriller
                }
                if(genre==='dystopian'){
                    description = genres.dystopian
                }
                if(genre==='steampunk'){
                    description = genres.steampunk
                }
                if(genre==='romance'){
                    description = genres.romance
                }
                if(genre==='historical fiction'){
                    description = genres.historicalFiction
                }
                if(genre==='post-apocalyptic'){
                    description = genres.postApocalyptic
                }
                if(genre==='adventure'){
                    description = genres.adventure
                }
                if(genre==='horror'){
                    description = genres.horror
                }
                if(genre==='literary fiction'){
                    description = genres.literaryFiction
                }
                if(genre==='spy fiction'){
                    description = genres.spyFiction
                }
                if(genre==='high fantasy'){
                    description = genres.highFantasy
                }
                if(genre==='low fantasy'){
                    description = genres.lowFantasy
                }
                if(genre==='satire'){
                    description = genres.satire
                }
                if(genre==='cyberpunk'){
                    description = genres.cyberpunk
                }
                if(genre==='spy fiction'){
                    description = genres.spyFiction
                }
                if(genre==='alternative history'){
                    description = genres.altHistory
                }
                if(genre==='urban fantasy'){
                    description = genres.urbanFantasy
                }
                if(genre==='first contact'){
                    description = genres.firstContact
                }
                if(genre==='space opera'){
                    description = genres.spaceOpera
                }
                if(genre==='young adult'){
                    description = genres.youngAdult
                }
                if(genre==='middle grade'){
                    description = genres.middleGrade
                }
                if(genre==='epic'){
                    description = genres.epic
                }
                if(genre==='magical realism'){
                    description = genres.magicalRealism
                }
                if(genre==='slipstream'){
                    description = genres.slipstream
                }
                if(genre==='western'){
                    description = genres.western
                }
                if(genre==='superhero'){
                    description = genres.superHero
                }
                if(genre==='political thriller'){
                    description = genres.politicalThriller
                }
                
                return description;        
            } 

    return(
        <div id='step-one'className='step'>
            <h3>Step 1: Choosing a Genre</h3>
            <p className='step-p'>You probably already know what genre you like to write in, but even if you do, 
            it’s good to write in multiple genres, at least in the beginning. This way, you can flex other writing 
            muscles from time to time. Also, there’s no reason why a story only needs to be in one genre. 
            They almost never are. </p>
            
            <p className='step-p'>How about a sci-fi thriller that’s really about two people falling in love? 
            Or a buddy-cop western about two detectives solving a murder out on the frontier? 
            A dystopian fantasy world, or a steampunk memoir, or an alternative history super-hero rag, 
            or a spy-vs.- spy space opera, or a magical realism first contact tale? Be creative. Do it!</p>
            <div className = 'stepList'>
                <ul>
                    <li>Literary Fiction<span>{showGenre('literary fiction')}</span></li>
                    <li>Historical Fiction<span>{showGenre('historical fiction')}</span></li>
                    <li>Murder Mystery<span>{showGenre('mystery')}</span></li>
                    <li>Thriller<span>{showGenre('thriller')}</span></li>
                    <li>High Fantasy<span>{showGenre('high fantasy')}</span></li>
                    <li>Low Fantasy<span>{showGenre('low fantasy')}</span></li>
                    <li>Science Fiction<span>{showGenre('sci-fi')}</span></li>
                    <li>Steampunk<span>{showGenre('steampunk')}</span></li>
                    <li>Cyberpunk<span>{showGenre('cyberpunk')}</span></li>
                    <li>Urban Fantasy<span>{showGenre('urban fantasy')}</span></li>
                    <li>Horror<span>{showGenre('horror')}</span></li>
                    <li>Superhero<span>{showGenre('superhero')}</span></li>
                    <li>Alternative History<span>{showGenre('alternative history')}</span></li>
                    <li>Political Thriller<span>{showGenre('political thriller')}</span></li></ul>
                <ul>
                    <li>Romance<span>{showGenre('romance')}</span></li>
                    <li>Action Adventure<span>{showGenre('adventure')}</span></li>
                    <li>Western<span>{showGenre('western')}</span></li>
                    <li>Slipstream<span>{showGenre('slipstream')}</span></li>
                    <li>Dystopia<span>{showGenre('dystopian')}</span></li>
                    <li>Spy Fiction<span>{showGenre('spy fiction')}</span></li>
                    <li>Satire<span>{showGenre('satire')}</span></li>
                    <li>Young Adult<span>{showGenre('young adult')}</span></li>
                    <li>Middle-grade<span>{showGenre('middle grade')}</span></li>
                    <li>First Contact<span>{showGenre('first contact')}</span></li>
                    <li>Post-Apocalyptic<span>{showGenre('post-apocalyptic')}</span></li>
                    <li>Space Opera<span>{showGenre('space opera')}</span></li>
                    <li>Magical Realism<span>{showGenre('magical realism')}</span></li>
                    <li>Epic<span>{showGenre('epic')}</span></li>
                </ul>
                </div>
                    <p id='example'>Examples<span>Example 1: Wild-West Space Opera <br/> Example 2: Historical Thriller </span></p>
                    <div className='input'>
                        <label>Write your genre(s) here:  </label>
                        <textarea id='step1' className='stepInput' type='text' value={input1} onChange={handleChange} />
                        {input1!=='' && <button onClick={()=>handleClick('step1', input1)} id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                        <p>Genre(s): <b>{step1}</b></p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='..'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../get-idea-step-2'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}