import React from "react"
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './blog.css'
import { Outlet } from "react-router-dom";

export const Blog = () => {

    return(
        <div className='page'>
            <LeftSide />
            <div className = 'content'>
                <header>
                    <h1 id='blogHeader'>The Writician Blog</h1>
                    <Outlet/>
                </header>

            </div>
            <RightSide />
        </div>
        
    )
}

/*<Outlet />*/