import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePrompt, selectSavedPrompts,removePrompt, selectPlotPrompt } from "./plotPromptSlice";

export const PlotSave = ({style}) => {

    const plotPrompt = useSelector(selectPlotPrompt);
    const dispatch = useDispatch();
    const savedPrompts = useSelector(selectSavedPrompts);
    

    const handleClick = () => {
        dispatch(savePrompt(plotPrompt));
    }

    
    return (
        <div className='plotPrompt save'>
            <button id='button2'onClick={()=>handleClick()}>Save</button>
            <div className = 'savedPrompt'>
                {savedPrompts.map((prompt) => (
                    <div>
                        <div style={style} id='plotPSave'>{prompt.prompt}</div>
                        <p id='button2'onClick={()=> dispatch(removePrompt(prompt))}>remove</p>
                    </div>
                ))}
            </div>
        </div>
    )
}


           