import React from "react";

export const BlogPost3 = () => {

        window.scroll(0,0)


    return(

        <div className = 'blogPost'>
            <div className='blogTop' id='blogTop3'>
                <img className='blogImage' src='/images/magic.jpg' alt='magical book'/>
                <div className = 'blogHeader'>
                    <div className='blogTitle'>Creative Writing Exercise #1</div>
                    <div className='blogSubtitle'>Magical abilities in fantasy based on world-building.</div>
                </div>
            </div>
            

        
        </div>
    )
}