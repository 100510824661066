import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write7 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 7: Begin Writing in Motivation-Reaction Units</h3>
            <p className='step-p'>At last, it is time to begin writing. <br/><br/> <b>Motivation - Reaction</b> units are the smallest units of writing used in narrative fiction. M-R units build into Scene-Sequel sets, which build into each of Three Acts, which make up your complete Story.</p>
            <ul className = 'plotList'>
                <li><b>Motivation</b> - external, objective sentence(s) that does not include your character.</li>
                <li><b>Reaction</b> – internal, subjective sentence(s) about your character. Follows the pattern of <i><u>feeling</u>, <u>action</u>, <u>speech</u>,</i> but doesn't always have to include all three. Feeling precedes action, and action, speech, because feeling provides the drive for the other two.
                </li>
            </ul>
            <p>Example:</p>
            <ul className='plotList'>
                <li>Motivating stimulus:
                    <ul>
                        <li>“Hi, Jill,” he called. “How’s it going?”</li>
                    </ul>
                </li>
                <li>Character reaction:
                    <ul>
                        <li>Feeling - A glow of warmth at his friendliness crept through Jill.</li>
                        <li>Action - She smiled.</li>
                        <li>Speech - “Just fine, thanks,” she said.</li>
                    </ul>
                </li>
            </ul>
            <p className='step-p'>These flow back and forth. You don't need all three each time, but they should always come in this order; feeling, followed by action, followed by speech. 
                These beats, interposed with dialogue, description, backstory, reflection, and world building, are your story. Only practice will teach you where to put what and when.</p>
            <br/>
            <p>~~~~~~~~~~~~~~</p>
            <br/>
            <h4>You've made it!</h4>
            <p className='step-p'>There you have it, everything you need to finish your story. If you've completed every step, writing the thing should be a breeze. You might not stick
                to your plan precisely, you may realize you need to make big changes halfway through. That's all good. You'll work out your own process. But if you've got the plan, the blueprint, then you 
                won't feel lost in the woods, you'll have a map and you'll know where you're going and how to get there. That's half the battle. </p>
                <p className='step-p'>Writing itself is a performance, it should be flowing and organic.
             All the work you've just done is there to support you so you keep gliding upright and on the move.
                <p className='step-p'>Good luck,<br/> I beleive in you, <br/>get that story out into the world!</p>
            </p>
            
           
            <div className='stepArrows'>
                        <Link to='../write-idea-step-6'><button id='button2'><span>Back</span></button></Link>
                    </div>
        </div>
    )}