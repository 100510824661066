import React from "react";


export const CharacterArcsHome = () => {
    return (
        <div className = 'arcs-page'>
            <h1>What are Character Arcs?</h1>
            <p id='archP'>A character arc refers to the transformation or development that a character undergoes throughout a story. It involves a change in the character's beliefs, attitudes, or traits as a result of their experiences and challenges. Character arcs are an essential element of storytelling, as they add depth and complexity to the characters, making them more relatable and interesting to the audience.</p>
        </div>
    )
}