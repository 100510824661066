import React, {useState, useEffect} from "react";
import { displayPrompt, selectCharacterPrompt } from "./characterPromptSlice";
import { useSelector, useDispatch } from "react-redux";
import { charGen } from "./characterPromptSlice";
import { CharacterSave } from "./characterSave";
import { CharacterSelector } from "./characterSelector";

export default function CharacterPrompt(){

    const characterPrompt = useSelector(selectCharacterPrompt)
    const dispatch = useDispatch();
    const prompt = characterPrompt.prompt.prompt
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({})

    

    useEffect(()=>{
        
        if(prompt !== undefined){
            setSave(true);

            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[prompt])
    

    const handleClick = () => {
        dispatch(charGen());
        dispatch(displayPrompt());
        save? setSave(false): setSave(true);
    }
    return(
        //adding the save && takes away the blank space of the p object before the prompt is generated
        <div id='plotPrompt'>
            <CharacterSelector />
            <div className='prompt' id='characterPrompt' >
                {save && <p style={style}>{prompt}</p>}
                <button id='button1' onClick={()=> handleClick()}>Generate New Character</button>
                {save && <CharacterSave style={style}/>}
            </div>
        </div>
    )
}
