import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import ErrorPage from './app/errorPage/errorPage';
import { Prompts } from './pages/prompts/promptsPage';
import PlotPrompt from './pages/prompts/plotPrompt/plotPromptPage';
import CharacterPrompt from './pages/prompts/characterPrompt/characterPromptPage';
import { SettingPrompt } from './pages/prompts/settingPrompt/settingPromptPage';
import { RandomElements } from './pages/prompts/randomElements/randomElementsPage';
import { StoryPremise } from './pages/prompts/storyPremise/storyPremisePage';
import { UspPrompt } from './pages/prompts/uspPrompt/uspPromptPage';
import { RedditPrompt } from './pages/prompts/redditPrompt/redditPromptPage';
import { GetIdea } from './pages/get-idea/get-idea-page';
import { Home } from './pages/home/home';
import { PromptHome } from './pages/prompts/promptsHome';
import { Blog } from './pages/blog/blog';
import { About } from './pages/About/about';
import { WriteIdea } from './pages/write-idea/write-ideaPage';
import { StoryElements } from './pages/storyElements/storyElementsPage';
import { Get1 } from './pages/get-idea/get1';
import { GetHome } from './pages/get-idea/getHome';
import { Get2 } from './pages/get-idea/get2';
import { Get3 } from './pages/get-idea/get3';
import { Get4 } from './pages/get-idea/get4';
import { Get5 } from './pages/get-idea/get5';
import { Get6 } from './pages/get-idea/get6';
import { Get7 } from './pages/get-idea/get7';
import { Get8 } from './pages/get-idea/get8';
import { GetSummary } from './pages/get-idea/getSummary';
import { WriteHome } from './pages/write-idea/writeHome';
import { Write1 } from './pages/write-idea/write1';
import { Write2 } from './pages/write-idea/write2';
import { Write3 } from './pages/write-idea/write3';
import { Write4 } from './pages/write-idea/write4';
import { Write5 } from './pages/write-idea/write5';
import { Write6 } from './pages/write-idea/write6';
import { Write7 } from './pages/write-idea/write7';
import { PositiveArc } from './pages/write-idea/arcs/postiveArc';
import { FlatArc } from './pages/write-idea/arcs/flatArc';
import { CorruptionArc } from './pages/write-idea/arcs/corruptionArc';
import { DisillusionmentArc } from './pages/write-idea/arcs/disillusionmentArc';
import { ThefallArc } from './pages/write-idea/arcs/thefallArc';
import { PlotHome } from './pages/storyElements/plotElements/plotElementsHome';
import { PlotElements } from './pages/storyElements/plotElements/plotElementsPage';
import { CharacterElements } from './pages/storyElements/characterElements/characterElementsPage';
import { StoryHome } from './pages/storyElements/storyElementsHome';
import { CraftElements } from './pages/storyElements/craftElements/craftElementsPage';
import { Genres } from './pages/storyElements/plotElements/genres';
import { CharacterArcs } from './pages/storyElements/plotElements/characterArcs';
import { Subplots } from './pages/storyElements/plotElements/subplots';
import { Hooks } from './pages/storyElements/plotElements/hooks';
import { Endings } from './pages/storyElements/plotElements/endings';
import { Hero } from './pages/storyElements/plotElements/plot archetypes/heros-journey';
import { Polti } from './pages/storyElements/plotElements/plot archetypes/polti';
import { Booker } from './pages/storyElements/plotElements/plot archetypes/booker';
import { Tobias } from './pages/storyElements/plotElements/plot archetypes/tobias';
import { Vonnegut } from './pages/storyElements/plotElements/plot archetypes/vonnegut';
import { Card } from './pages/storyElements/plotElements/plot archetypes/card';
import { PlotArchsPage } from './pages/storyElements/plotElements/plot archetypes/plotArchsPage';
import {PlotArchsHome} from './pages/storyElements/plotElements/plot archetypes/plotArchsHome'
import {CharacterElementsHome} from './pages/storyElements/characterElements/characterElementsHome';
import { PersonalityTypes } from './pages/storyElements/characterElements/personalityTypes';
import { CharacterArchetypes } from './pages/storyElements/characterElements/characterArchetypes';
import { CharacterArcsHome } from './pages/storyElements/plotElements/characterArcsHome';
import { LitDevices } from './pages/storyElements/craftElements/literaryDevices';
import { CraftHome } from './pages/storyElements/craftElements/craftElementsHome';
import { NarStyles } from './pages/storyElements/craftElements/narStyles';
import { Themes } from './pages/storyElements/craftElements/themes';
import { GetPublished } from './pages/storyElements/craftElements/getPublished';
import { BlogPost1 } from './pages/blog/blogPost1';
import { BlogHome } from './pages/blog/blogHome';
import { BlogPost2 } from './pages/blog/blogPost2';
import { BlogPost3 } from './pages/blog/blogPost3';
import { Login } from './app/login';


const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [{
        errorElement: <ErrorPage />,
        children: [
          {index: true, element: <Home />},
          {path: 'login', element: <Login /> },
          {path: 'about', element: <About /> },
          {path: 'blog', element: <Blog />,
          children:[
            {index: true, element: <BlogHome />},
            {path: 'welcome-to-writician',element: <BlogPost1 />},
            {path: 'are-you-a-gardner-or-an-architect',element: <BlogPost2 />},
            {path: 'creative-writing-exercise-1',element: <BlogPost3 />},

          ]
        },
          {path: 'story-elements', element: <StoryElements/>,
          children: [
            {index: true, element: <StoryHome/>},
            {path:'plot-elements', element: <PlotElements/>,
            children: [
              {index: true, element: <PlotHome/>},
             {path: 'genres', element: <Genres />},
             {path: 'plot-archetypes', element:<PlotArchsPage/>,
              children: [
                {index: true, element: <PlotArchsHome/>},
                {path: 'hero', element:<Hero/>},
                {path: 'polti', element:<Polti/>},
                {path: 'booker', element:<Booker/>},
                {path: 'tobias', element:<Tobias/>},
                {path: 'vonnegut', element:<Vonnegut/>},
                {path: 'card', element: <Card/>}
                
              ]
            },
             {path: 'character-arcs', element: <CharacterArcs />,
             children: [
              {index: true, element: <CharacterArcsHome /> },
              {path:'positive-arc', element: <PositiveArc/>},
              {path:'flat-arc', element: <FlatArc/>},
              {path:'corruption-arc', element: <CorruptionArc/>},
              {path:'disillusionment-arc', element: <DisillusionmentArc/>},
              {path:'thefall-arc',element: <ThefallArc/>},
              ]},
             {path: 'subplots', element: <Subplots />},
             {path: 'hooks', element: <Hooks/>},
             {path: 'endings', element: <Endings/>}
            ]},
            {path:'character-elements', element:<CharacterElements/>,
            children: [
              {index: true, element: <CharacterElementsHome/>},
              {path: 'personality-types', element: <PersonalityTypes/>},
              {path: 'character-archetypes', element: <CharacterArchetypes/>}
            ]
          },
            {path:'craft-elements', element:<CraftElements />,
          children: [
            {index: true, element: <CraftHome /> },
            {path: 'lit-devices', element: <LitDevices />},
            {path:'narrative-styles', element: <NarStyles />},
            {path: 'themes', element: <Themes />},
            {path: 'get-published',element: <GetPublished />}
          ]}
          ]},
          {path: 'get-idea', element: <GetIdea />,
            children: [
              {index: true,element: <GetHome />},
              {path:'get-idea-step-1', element: <Get1 />},
              {path:'get-idea-step-2', element: <Get2 />},
              {path:'get-idea-step-3', element: <Get3 />},
              {path:'get-idea-step-4', element: <Get4 />},
              {path:'get-idea-step-5', element: <Get5 />},
              {path:'get-idea-step-6', element: <Get6 />},
              {path:'get-idea-step-7', element: <Get7 />},
              {path:'get-idea-step-8', element: <Get8 />},
              {path:'get-idea-summary', element: <GetSummary />},
            ]},
          {path: 'write-idea',element: <WriteIdea />,
          children: [
            {index: true, element: <WriteHome />},
            {path:'write-idea-step-1', element: <Write1 />},
            {path:'write-idea-step-2', element: <Write2 />},
            {path:'write-idea-step-3', element: <Write3 />},
            {path:'write-idea-step-4',element: <Write4 />,
              children: [
              {path:'positive-arc', element: <PositiveArc/>},
              {path:'flat-arc', element: <FlatArc/>},
              {path:'corruption-arc', element: <CorruptionArc/>},
              {path:'disillusionment-arc', element: <DisillusionmentArc/>},
              {path:'thefall-arc',element: <ThefallArc/>},
              ]},
            {path:'write-idea-step-5', element: <Write5 />},
            {path:'write-idea-step-6', element: <Write6 />},
            {path:'write-idea-step-7', element: <Write7 />},
          ]},
          {path: "prompt-generators", element: <Prompts />,
          children: [
            {index: true, element: <PromptHome />},
            {path: 'plotPrompt',element: <PlotPrompt />,},
            {path: 'characterPrompt', element: <CharacterPrompt />},
            {path: 'settingPrompt', element: <SettingPrompt />},
            {path: 'randomPrompt', element: <RandomElements />},
            {path: 'premisePrompt', element: <StoryPremise />},
            {path: 'uspPrompt', element: <UspPrompt />},
            {path: 'redditPrompt', element: <RedditPrompt />},
        ]},
      ]
       }]
  }]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
