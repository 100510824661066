import React from "react";
import { Link } from "react-router-dom";

export const GetHome = ()=> {
    window.scrollTo(0,0)

    return(
        <div className='step'>
            <img id='get-img'src='/images/8wheel.png' alt='assorted writing utensils'/>
            <p className='step-p'>
            We’ve been telling each other stories since the dawn of time. They are the meat on the bones of life.
            We once sat in caves, huddled around a fire, waiting out the wintry months, retelling tales of summer hunts,
            festival games, fights with rivals, and young loves.</p>
            
            <p className='step-p'>Our minds are the ultimate story tellers. I bet you’re telling yourself a story right now.
            In fact, I know you are. It is the story of your life, the story we all tell ourselves about who we are,
             why we do the things we do, where we are going, justifying our every action, advising our every move.</p>
            <p className='step-p'>Our society tells a story as well. Our histories are the stories of our people, 
            our nation, our world. Without stories, we’d be lost, we’d have no connection to the past, the future, 
            or to each other. And every story we tell, is pretty much about the same thing: </p>
            
            <p className='step-p'>Change.</p>

            <p className='step-p'>We were something, but something happened, and now we’re something else. The dressing of the story 
            differs, whether it be a romance, comedy, thriller, horror, sci-fi, fantasy, western, dystopian, 
            or any other. But the dressing, oh, that makes all the difference!</p>
            <p className='step-p'>Because, if we’ve been telling ourselves stories since the dawn of time and 
            we’re still telling them today, chances are they’re not going anywhere. And chances are, there’s room 
            for yours. In fact, the world probably wants it. Craves it. Needs it.</p>
            <p className='step-p'>So let’s get all the dressings of an awesome story idea ready for you to tell the 
            story in the only way you can; your own.</p>
            <p className='step-p'><b>Keep in mind this is a creative exercise. Let your mind be free to go off on 
                tangents, change, come back, rework, think anew. I’ve listed steps in an order that seem logical to me,
                 but the idea’s growth won’t necessary follow them.</b></p>
            <br/>
            <Link to='get-idea-step-1'><button id='button2'><span>Begin Your Journey</span></button></Link>
        </div>
        )
}