import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const Hooks = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div>
            <header><h1>Hooks</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className="genres">
                <div className="genre">
                    <h3>Action</h3>
                    <ul>
                        <li>Begin in the middle of the action</li>
                        <li>Grabs the reader's attention right away, adding context later on.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Analogy</h3>
                    <ul>
                        <li>Compare two or more things.</li>
                        <li>A way to draw the reader into the themes of the story, possibily the protagonist/narrator's personality as well.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Command to the Reader</h3>
                    <ul>
                        <li>Sets the tone right away, breaking the fourth wall.</li>
                        <li>Establishes the role of the narrator in the story.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Bold Statement</h3>
                    <ul>
                        <li>Something shocking or interesting to catch the reader's attention and draw them in.</li>
                      
                    </ul>
                </div>
                <div className="genre">
                    <h3>Character's Thoughts/Feelings</h3>
                    <ul>
                        <li>Introduces the protagonist, establishes the story question through their fears or desires.</li>
                        <li>Should entice the reader to want to see what happens to them.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Definition</h3>
                    <ul>
                        <li>Define a word that encapsulates the theme of the story.</li>
                    </ul>
                </div>
                <div className="genre">
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Jumping right into dialogue quick-starts the story and drama.</li>
                        <li>The reader is forced right into the heart of the scene.</li>
                    </ul>
                </div>
                <div className="genre">
                    <h3>Foreshadowing</h3>
                    <ul>
                        <li>Set up events that will unfold later on in the story.</li>
                        <li>Could be a hint or clue of something to come that the reader won't recognize until it's revealed later on.</li>
                    </ul>
                </div>
                <div className="genre">
                    <h3>Onomatopoeia</h3>
                    <ul>
                        <li>A word that is also the sound, catches the reader's attention.</li>
                    </ul>
                </div>
                <div className="genre">
                    <h3>Question</h3>
                    <ul>
                        <li>Asks the reader a question, to be answered later on, or to reveal theme.</li>
    
                    </ul>
                </div>
                <div className="genre">
                    <h3>Theme Statement</h3>
                    <ul>
                        <li>Tells the reader exactly what the story is going to be about.</li>
                        <li>Themes are vague and wide, so the specifics are not revealed.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Sentence Fragment</h3>
                    <ul>
                        <li>A short fragment of a sentence that leaves reader wanting more.</li>
                        <li>What does it mean? Where is this going?</li>
                    </ul>
                </div>
                <div className="genre">
                    <h3>Setting</h3>
                    <ul>
                        <li>Paints a picture of the setting with words.</li>
                        <li>Draws the reader in with sensory details, building the world around them and placing them in it.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}