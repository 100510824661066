import React from "react";
import { Outlet } from "react-router-dom";

export const CharacterElements = () => {

    return(
        <div>
                <Outlet/>
        </div>

    )
}