import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const LitDevices = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (

        <div>
            <header><h1>Literary Devices</h1></header>
            <NavLink to='/story-elements/craft-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>

            <div id='litDevices'className='genres'>
                <li className="genre">
                <h2>Alliteration</h2>
                <p><em>Description:</em> Repetition of the same consonant sound at the beginning of neighboring words.</p>
                <p><em>Example:</em> Peter Piper picked a peck of pickled peppers.</p>
                </li>

                <li className="genre">
                <h2>Metaphor</h2>
                <p><em>Description:</em> A figure of speech that makes a direct comparison between two unrelated things.</p>
                <p><em>Example:</em> The world is a stage.</p>
                </li>

                <li className="genre">
                <h2>Simile</h2>
                <p><em>Description:</em> A figure of speech that makes a comparison using "like" or "as."</p>
                <p><em>Example:</em> Her smile was as bright as the sun.</p>
                </li>

                <li className="genre">
                <h2>Personification</h2>
                <p><em>Description:</em> Attributing human qualities to non-human entities or abstract concepts.</p>
                <p><em>Example:</em> The wind whispered through the trees.</p>
                </li>

                <li className="genre">
                <h2>Irony</h2>
                <p><em>Description:</em> A contrast between expectation and reality, often with a twist in meaning.</p>
                <p><em>Example:</em> The fire station burned down.</p>
                </li>

                <li className="genre">
                <h2>Hyperbole</h2>
                <p><em>Description:</em> Exaggerated statements or claims not meant to be taken literally.</p>
                <p><em>Example:</em> I've told you a million times to clean your room!</p>
                </li>

                <li className="genre">
                <h2>Onomatopoeia</h2>
                <p><em>Description:</em> Words that imitate or resemble the sound they describe.</p>
                <p><em>Example:</em> The clock ticked loudly in the silent room.</p>
                </li>

                <li className="genre">
                <h2>Foreshadowing</h2>
                <p><em>Description:</em> A literary device used to give a hint or suggestion of what will happen later in the story.</p>
                <p><em>Example:</em> The ominous dark clouds foreshadowed the approaching storm (real or metaphorical).</p>
                </li>

                <li className="genre">
                <h2>Symbolism</h2>
                <p><em>Description:</em> The use of symbols to represent ideas or qualities.</p>
                <p><em>Example:</em> The dove is often a symbol of peace.</p>
                </li>

                <li className="genre">
                <h2>Assonance</h2>
                <p><em>Description:</em> Repetition of vowel sounds within nearby words.</p>
                <p><em>Example:</em> The rain in Spain falls mainly on the plain.</p>
                </li>
                <li class="genre">

                <h2>Euphemism</h2>
                <p><em>Description:</em> Substituting a mild or less direct word or phrase for a harsh or blunt one.</p>
                <p><em>Example:</em> He passed away.</p>
                </li>

                <li class="genre">
                <h2>Epiphany</h2>
                <p><em>Description:</em> A moment of sudden realization or understanding.</p>
                <p><em>Example:</em> After years of confusion, she had an epiphany about her true passion.</p>
                </li>

                <li class="genre">
                <h2>Anaphora</h2>
                <p><em>Description:</em> The repetition of a word or phrase at the beginning of successive clauses.</p>
                <p><em>Example:</em> I have a dream... I have a dream...</p>
                </li>

                <li class="genre">
                <h2>Paradox</h2>
                <p><em>Description:</em> A statement that appears contradictory but may be true.</p>
                <p><em>Example:</em> Less is more.</p>
                </li>

                <li class="genre">
                <h2>Oxymoron</h2>
                <p><em>Description:</em> A combination of contradictory or incongruent words.</p>
                <p><em>Example:</em> Bitter-sweet</p>
                </li>

                <li class="genre">
                <h2>Synecdoche</h2>
                <p><em>Description:</em> A figure of speech in which a part represents the whole or the whole represents a part.</p>
                <p><em>Example:</em> All hands on deck.</p>
                </li>

                <li class="genre">
                <h2>Mood</h2>
                <p><em>Description:</em> The atmosphere or feeling created by a literary work.</p>
                <p><em>Example:</em> The eerie mood of the haunted house unsettled the visitors.</p>
                </li>

                <li class="genre">
                <h2>Juxtaposition</h2>
                <p><em>Description:</em> Placing two or more elements side by side for the purpose of comparison or contrast.</p>
                <p><em>Example:</em> In the bustling city, the homeless man slept peacefully on a park bench.</p>
                </li>

                <li class="genre">
                <h2>Rhetorical Question</h2>
                <p><em>Description:</em> A question posed for effect, not requiring an answer.</p>
                <p><em>Example:</em> Who do you think you are?</p>
                </li>

                <li class="genre">
                <h2>Cliché</h2>
                <p><em>Description:</em> An overused phrase or idea that has lost its originality or impact.</p>
                <p><em>Example:</em> Time will tell.</p>
                </li>

                <div className="genre">
                <h2>Imagery</h2>
                <p><em>Description:</em> Use of vivid and descriptive language to create mental images, appealing to the reader's senses.</p>
                <p><em>Example:</em> The salty sea breeze caressed their faces as they walked along the shore.</p>
                </div>

                <div className="genre">
                <h2>Cliffhanger</h2>
                <p><em>Description:</em> A narrative device that leaves the audience in suspense at the end of a scene or chapter, compelling them to continue reading to find out what happens next.</p>
                <p><em>Example:</em> The door creaked open, and suddenly, everything went black.</p>
                </div>

                <div className="genre">
                <h2>Deus Ex Machina</h2>
                <p><em>Description:</em> An unexpected or improbable solution to resolve a conflict, often introduced as a last-minute intervention.</p>
                <p><em>Example:</em> As the hero faced certain doom, a magical creature appeared and saved the day.</p>
                </div>

                <div className="genre">
                <h2>Eucatastrophe</h2>
                <p><em>Description:</em> A sudden and unexpected turn of events that brings about a positive resolution to a seemingly dire situation.</p>
                <p><em>Example:</em> Just when it seemed all hope was lost, a long-lost friend arrived with reinforcements.</p>
                </div>

                <div className="genre">
                <h2>Story Within a Story</h2>
                <p><em>Description:</em> Inclusion of one or more narratives within the main narrative, often as a tale told by a character within the story.</p>
                <p><em>Example:</em> One character recounts a fable to illustrate a moral point within the main plot.</p>
                </div>

                <div className="genre">
                <h2>Framing Device</h2>
                <p><em>Description:</em> A narrative structure where a story is enclosed within another story, serving as a framing element.</p>
                <p><em>Example:</em> The main narrative is presented as a manuscript discovered by a character in the framing story.</p>
                </div>

                <div className="genre">
                <h2>Macguffin</h2>
                <p><em>Description:</em> A Macguffin is a plot device, typically an object or goal, that propels the story by motivating the characters. It adds mystery and suspense without intrinsic significance, remaining interchangeable to maintain focus on the characters and their journey.</p>
                <p><em>Example:</em> The mysterious briefcase in the film that everyone is after, but its contents are never revealed.</p>
                </div>

                <div className="genre">
                <h2>In Medias Res</h2>
                <p><em>Description:</em> Starting a narrative in the middle of the action, often using flashbacks to provide earlier context.</p>
                <p><em>Example:</em> The story begins with a car chase, and later chapters reveal how the characters got involved.</p>
                </div>

                <div className="genre">
                    <h2>Plot Twist</h2>
                    <p><em>Description:</em> A significant change in the expected direction or outcome of the plot, surprising the audience.</p>
                    <p><em>Example:</em> The revelation that the protagonist's ally is, in fact, the main antagonist.</p>
                </div>

                <div className="genre">
                    <h2>Poetic Justice</h2>
                    <p><em>Description:</em> The concept that virtue is ultimately rewarded, and vice is punished in a manner that is fitting or appropriate.</p>
                    <p><em>Example:</em> The villain who exploited others for personal gain faces a downfall that mirrors their own actions.</p>
                </div>

                <div className="genre">
                    <h2>Predestination</h2>
                    <p><em>Description:</em> The idea that events or outcomes are predetermined and cannot be changed, often tied to a sense of destiny.</p>
                    <p><em>Example:</em> Characters discover a prophecy that foretells the specific events of their lives.</p>
                </div>

                <div className="genre">
                    <h2>Quibble</h2>
                    <p><em>Description:</em> A clever and often humorous play on words or a trivial objection or argument.</p>
                    <p><em>Example:</em> Characters engage in a witty exchange of puns during a heated debate.</p>
                </div>

                <div className="genre">
                    <h2>Red Herring</h2>
                    <p><em>Description:</em> A misleading clue or distraction intentionally inserted to divert attention from the real issue or plot point.</p>
                    <p><em>Example:</em> The detective follows a false lead that initially seems crucial to solving the mystery.</p>
                </div>

                <div className="genre">
                    <h2>Self-fulfilling Prophecy</h2>
                    <p><em>Description:</em> A prediction or belief that influences a person's behavior in such a way that it causes the prediction to come true.</p>
                    <p><em>Example:</em> A character, fearing betrayal, takes actions that drive their friends away, fulfilling the fear of betrayal.</p>
                </div>

                <div className="genre">
                    <h2>Ticking Clock</h2>
                    <p><em>Description:</em> Introduction of a time constraint or impending deadline, creating tension and a sense of urgency in the narrative.</p>
                    <p><em>Example:</em> The characters must complete a mission before a bomb detonates, adding urgency to the plot.</p>
                </div>

                <div className="genre">
                    <h2>Chekhov's Gun</h2>
                    <p><em>Description:</em> The narrative principle that states every element in a story should be necessary and irreplaceable, and any element that is not should be removed.</p>
                    <p><em>Example:</em> A seemingly insignificant object introduced early in the story becomes crucial to the resolution later on.</p>
                </div>

                <div className="genre">
                    <h2>Unreliable Narrator</h2>
                    <p><em>Description:</em> A narrator whose credibility or reliability is compromised, intentionally or unintentionally, leading to a narrative where the audience questions the truth of the events.</p>
                    <p><em>Example:</em> The narrator with a personal bias or mental instability presents events in a way that distorts the true nature of the story.</p>
                </div>

                <div className="genre">
                    <h2>Audience Surrogate</h2>
                    <p><em>Description:</em> A character designed to represent the audience's perspective, often used to introduce them to a new world or concept.</p>
                    <p><em>Example:</em> A character from the real world is transported to a fantasy realm and learns about it along with the audience.</p>
                </div>

                

            </div>

        </div>

    )
}