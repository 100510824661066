import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Get4 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;

    let [input4, setInput4] =useState('')

    const handleChange =({target})=> {
            setInput4(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput4('')
        }

    return(
        <div id='step-four'className='step'>
            <h3>Step 4: Selecting a Theme</h3>
            <p className='step-p'>The theme is the canopy under which your story resides. Or, you could say the root from which it sprouts, the center of the web. You know the genre, story archetype, and the personality of your hero. Now, what is their story really going to be about?</p>
                <p className='step-p'>Themes can usually be broken down into one or a few words. Some great novels state the theme right in their titles: <i>War and Peace</i>, <i>Pride and Prejudice</i>, <i>Crime and Punishment</i>.</p>
                <p className='step-p'>I’ve compiled a list of themes and thematic elements but it is far from exhaustive. Combining multiple themes will give depth to your story, but overall it will have one central theme.</p>
                
            <div className='themeList'>
                <ul>
                    <li>Love and loss</li>
                    <li>Justice</li>
                    <li>Redemption</li>
                    <li>Survival</li>
                    <li>War – Glory, Necessity, Pain, Tragedy</li>
                    <li>True love</li>
                    <li>Heroism</li>
                    <li>The nature of good and evil</li>
                    <li>Circle of life</li>
                    <li>Coming of age</li>
                    <li>Sacrifice</li>
                    <li>Deception</li>
                    <li>Courage and Perseverance</li>
                    <li>Betrayal and Revenge</li>
                    <li>Forbidden Romance</li>
                    <li>Spiritual Enlightenment</li>
                    <li>Mysticism</li>
                    <li>Faith vs Doubt</li>
                    <li>Heartbreak</li>
                    <li>Order vs Chaos</li>
                </ul>
                <ul>
                    <li>Nature of Time</li>
                    <li>Change vs Tradition</li>
                    <li>Death and Dying</li>
                    <li>Empowerment</li>
                    <li>Emptiness of Attaining a False Dream</li>
                    <li>Facing Darkness</li>
                    <li>Fate and Free will</li>
                    <li>Fear of Failure</li>
                    <li>Greed as Downfall</li>
                    <li>Identity Crisis</li>
                    <li>Illusion of Power</li>
                    <li>Immortality</li>
                    <li>Injustice & Oppression</li>
                    <li>Knowledge vs Ignorance</li>
                    <li>Escaping Poverty </li>
                    <li>Man vs Wild</li>
                    <li>Materialism as Downfall</li>
                    <li>Manipulation</li>
                    <li>Loneliness</li>
                    <li>Losing Hope</li>

                </ul>
                <ul>
                    <li>Loss of Innocence</li>
                    <li>Lost Honor</li>
                    <li>The Will to Survive</li>
                    <li>Nationalism</li>
                    <li>Speaking truth to power</li>
                    <li>Power of Magic</li>
                    <li>Power and Corruption</li>
                    <li>Reunion</li>
                    <li>Power of Words</li>
                    <li>Self Reliance</li>
                    <li>Social Mobility</li>
                    <li>Role of technology in society</li>
                    <li>Totalitarianism</li>
                    <li>Temptation and Destruction</li>
                    <li>Vanity as Downfall</li>
                    <li>Vulnerability of the Strong</li>
                    <li>Temporary Nature of Beauty</li>
                    <li>Working Class Struggles</li>
                    <li>Winning against all odds</li>
                    <li>Overcoming Fear, Weakness, Vice</li>

                </ul>
               
            </div>
            <p id='example'>Examples<span>Example 1: Wild West Space Opera Mystery with a Defender Lover about lost honor.<br/> Example 2: Historical Thriller Quest with a Virtuoso Rebel about the will to survive. </span></p>

                    <div className='input'>
                            <label>Write your themes(s) here:  </label>
                            <textarea id='step4' className='stepInput' type='text' value={input4} onChange={handleChange} />
                            {input4!==''&&<button onClick={()=>handleClick('step4', input4)}id='button2'>Enter</button>}
                        </div>
                        <div className='savedInput'>
                        <p>Genre(s): <b>{step1}</b></p>
                        <p>Plot Archetype: <b>{step2}</b></p>
                        <p>Character: <b>{step3}</b></p>
                        <p>Theme(s):<b>{step4}</b></p>
                        </div>
                        <div className='stepArrows'>
                            <Link to='../get-idea-step-3'><button id='button2'><span>Back</span></button></Link>
                            <Link to='../get-idea-step-5'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}