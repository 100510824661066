import React from "react";
import { NavLink } from "react-router-dom";

export const CharacterElementsHome = () => {
   
        window.scrollTo(0,0)

    return(
        <div className='element'>
            <header><h1>Character Elements</h1></header>
            <NavLink to='/story-elements'><button id='button3'>Back</button></NavLink>

            <div className="navBoxes2">
                    
                        <NavLink to='character-archetypes' className='boxLink'>Character Archetypes</NavLink>
                    
                   
                        <NavLink to='personality-types'className='boxLink'>Personality Types</NavLink>
                   
                </div>
            
        </div>
    )
}