import { createSlice } from "@reduxjs/toolkit";



const generate = () => {

    let setting = {
        terrain: [
            'in the mountains','in a woodland','in the rainforest','on an archipelago',
            'on an island','in the lakelands','in the desert','on the plains','in the tundra',
            'in the arctic','in a river valley', 'in a wasteland','in an underground cave system',
            'on the coast', 'in the highlands','on a river delta','in a wetland', 'on a peninsula',
            'in a badlands', 'on a volcanic island','in a crater','on a floating iceberg','in a canyon',
            'on a plateau','on a spaceship','on an astroid','on the moon','in a space colony','on a new world'
        ],
        government: [
            'An oligarchic','An autocratic','A democratic', 'A theocratic', "A totalitarian",
            'A fascist', 'An anarchic', 'A socialist', 'A feudal', 'A communist', 'A matriarchal',
            'A revolutionary'
        ],
        community: [
            'city-state','farming village','empire','agricultural nation','principality','nomadic community',
            'semi-nomadic community', 'mining town','fishing village','industrial nation',
            'solar system, with their capitol', 'technologically advanced nation','secret society',
            
        ],
        ruler: [
            'a warrior king','competing politicians','local councils','a hereditary monarchy',
            'an elected council', 'a hereditary council', 'an autocrat', 'a military junta',
            'a puppet master','an emperor far away', 'a neighboring empire', 'an AI', 'a philosopher monarch',
            'trade guilds','a revolutionary council','a council of elders','a regent', 'a colonial governor',
            'a prophet','a lottery winner'
        ]
    };

    const random0 = Math.floor(Math.random() * setting.terrain.length);
    const random1 = Math.floor(Math.random() * setting.government.length);
    const random2 = Math.floor(Math.random() * setting.community.length);
    const random3 = Math.floor(Math.random() * setting.ruler.length);

    let terrain = setting.terrain[random0];
    let government = setting.government[random1];
    let community = setting.community[random2];
    let ruler = setting.ruler[random3];

    let items = {terrain, government, community, ruler}
    return items


}

export const settingPromptSlice = createSlice({
    name: 'settingPrompt',
    initialState: {
        settingPrompt : '',
        savedPrompts: [],
    },
    reducers: {
        setGen: (state) => {

           let government = generate().government
           let community = generate().community
           let terrain = generate().terrain
           let ruler = generate().ruler

            state.settingPrompt = `${government} ${community} ${terrain} ruled by ${ruler}`

        },
        savePrompt: (state, action) => {
            for(let i=0 ; i<state.savedPrompts.length+1 ; i++){
                if(state.savedPrompts[i]===action.payload){
                    return
                }
            }
            state.savedPrompts.push(state.settingPrompt)
        },
        removePrompt: (state, action) => {
            state.savedPrompts = state.savedPrompts.filter((prompt) => prompt !== action.payload)

        }
    }
})


export const selectSettingPrompt = (state) => state.settingPrompt.settingPrompt;
export const selectSavedPrompts = (state) => state.settingPrompt.savedPrompts;
export default settingPromptSlice.reducer;
export const { setGen, savePrompt, removePrompt } = settingPromptSlice.actions;