import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { enneagrams } from "../plotPrompt/descriptions";


const generate = () => {
    let character = {
        hairColor: [
            'black','blonde','gray','dyed','white','Blonde','Brunette','Red','Auburn',
            'Strawberry blonde','Copper','Dark blonde','Light brown','Golden','Platinum',
            'Ash blonde','Dark brown','Chestnut','Honey blonde','Caramel','Burgundy','Mahogany',
            'Violet','Blue', 'green',
        ],
        hairLength: [
            'long','short','medium-length','buzzed','balding','shoulder-length',
            'crew-cut','very long','thigh-length','chin-length','mid-back length',
    ],
        hairStyle: [
            'Spiked','Braided','Curly','Frizzy','Slicked','Unkempt','Dreaded','Straight','Messy',
            'Natural','Cornrowed', 'Afro', 'Top-knotted','Pony-tailed', 'Wavy','Straight','Straight',
            ''
                ],
        height: [
            'Very short','Short','Below average height', 'Average height','Above average height','Tall',
            'Very tall',
        ],
        weight: [
            'skinny','athletic','solid','obese','slim','average weight','stocky',
            'muscular','wiry','curvy','plus-sized','doughy','full-figured','compact','stout','ripped',
        ],
        look: [
            'Grizzled','Spry','Posh','Dirty','Youthful','Cute','Handsome','Beautiful','Ugly',
            'Weary','Rugged','Graceful','Vibrant','Sleek','Awkward','Rundown','Jittery',
            'Sloppy','Precise', 'Disheveled', 'Dapper', 'Lively', 'Tidy', 'Elegant', 'Crisp', 
            'Dull', 'Worn-out', 'Exuberant', 'Meticulous', 'Tattered', 'Charming', 'Shabby',
             'Spirited', 'Neat', 'Shattered', 'Glamorous', 'Unkempt', 'Limp', 'Animated', 'Pristine', 
             'Dingy', 'Unpolished', 'Animated', 'Careworn', 'Sophisticated', 'Untidy', 'Gleaming', 
             'Haggard', 'Chic', 'Bedraggled', 'Glossy', 'Fatigued', 'Jaunty', 'Grimy', 'Dashing', 
             'Untouched', 'Disjointed', 'Snappy', 'Ragged', 'Polished', 'Frazzled', 'Spiffy', 'Frumpy', 
             'Glittering', 'Slouchy', 'Snazzy', 'Battered', 'Refined', 'Tousled', 'Wrinkled', 'Flashy', 
             'Disarrayed', 'Unblemished', 'Worn', 'Jumbled', 'Jaunty', 'Chipped', 'Droopy', 'Slick', 
             'Disjointed', 'Slovenly', 'Buoyant', 'Rumpled', 'Spick-and-span', 
             'Unkempt', 'Spruced-up', 'Bedraggled', 'Gleaming', 'Tousled', 'Trim', 'Clumsy', 'Crumpled', 
             'Clever', 'Saggy', 'Glistening', 'Tidy', 'Shabby', 'Refined', 'Bleary-eyed', 'Suave', 
             'Careless', 'Disheveled', 'Sparkling', 'Smart', 'Stained', 'Lustrous', 'Disorganized', 
             'Glamorous', 'Sloppy', 'Bright-eyed', 'Sleek', 'Ragged', 'Groomed', 'Unkempt', 'Lustrous', 
             'Tousled', 'Mussy', 'Untidy', 'Unruffled', 'Disheveled', 'Natty', 'Dowdy', 'Immaculate', 
             'Bedraggled', 'Untidy', 'Chic', 'Tousled', 'Glossy', 'Disheveled', 'Disheveled', 'Gleaming', 
             'Wrinkled', 'Sharp', 'Frizzy', 'Disarrayed', 'Sleek', 'Mussed', 'Fresh', 'Messy', 'Natty', 
             'Rumpled', 'Unkempt', 'Unblemished', 'Groomed', 'Bedraggled', 'Impeccable', 'Disheveled', 
             'Tousled', 'Wrinkled', 'Meticulous',
        ],
        skinColor: [
            'pale skin','tan skin','light brown skin','brown skin','dark brown skin','black skin'
        ],
        accessories: [
            'face tattoos','arm tattoos','body tattoos','expensive jewelry',
        'cheap jewerly','a purse','a handbag','a gun','a sword','a walking stick','a cane',
        'a horse','a pet dog', 'a robot companion', 'a pet monkey', 'a lapdog', 'a lap cat',
        ''
    ],
        defining: [
            'a nose wart','a long nose','a pot belly','a face scar','one hand',
        'an eye patch','burn scars on arms','a nose ring','a bushy beard',
        'a magicians beard','a goatee','beads in their hair','a hidden birthmark',
        'a visible birthmark','yellow teeth','a beautiful smile','sparkling eyes',
        'a monocle','eye glasses','bushy eyebrows','large ears','a pointed nose',
        'missing teeth','resting scowl face','a cane','long beard','a mustache',
        'eye-liner','lots of makeup','lip stick','earrings',
    ],
    enneagram: [
        "reformer",
        "helper",
        "achiever",
        "individualist",
        "investigator",
        "loyalist",
        "enthusiast",
        "challenger",
        "peacemaker"
    ],
    archetype: [
        "innocent",
        "orphan",
        "hero",
        "caregiver",
        "explorer",
        "rebel",
        "lover",
        "creator",
        "jester",
        "sage",
        "magician",
        "ruler"
    ],

    species: ['human','elf','dwarf','troll','gray alien','reptillian humanoid','feline humanoid',
    'canine humanoid','crustacean humanoid','avian humanoid',
    'vampire','werewolf','ghost','demon','demigod','angel','robot','A.I.', 'immortal','celestial',
    'humanoid alien','shape-shifter',
    'ogre','orc','gnome','sprite','spirit'
],
    }

    const random00 = Math.floor(Math.random() * character.hairColor.length);
    const random01 = Math.floor(Math.random() * character.hairLength.length);
    const random02 = Math.floor(Math.random() * character.hairStyle.length);
    const random03 = Math.floor(Math.random() * character.height.length);
    const random04 = Math.floor(Math.random() * character.weight.length);
    const random05 = Math.floor(Math.random() * character.look.length);
    const random06 = Math.floor(Math.random() * character.skinColor.length);
    const random07 = Math.floor(Math.random() * character.accessories.length);
    const random08 = Math.floor(Math.random() * character.defining.length);
    const random09 = Math.floor(Math.random() * character.enneagram.length);
    //const random10 = Math.floor(Math.random() * character.archetype.length);
    const random11 = Math.floor(Math.random() * character.species.length);

    
    let hairColor = character.hairColor[random00].toLowerCase();
    let hairLength = character.hairLength[random01].toLowerCase();
    let hairStyle = character.hairStyle[random02].toLowerCase();
    let height = character.height[random03].toLowerCase();
    let weight = character.weight[random04].toLowerCase();
    let look = character.look[random05].toLowerCase();
    let skinColor = character.skinColor[random06].toLowerCase();
    let accessories = character.accessories[random07].toLowerCase();
    let defining = character.defining[random08].toLowerCase();
    let enneagram = character.enneagram[random09].toLowerCase();
    //let archetype = character.archetype[random10].toLowerCase();
    let species = character.species[random11].toLowerCase();

    

    const items = {hairColor,hairLength,hairStyle,height,weight,look,skinColor,accessories,defining,enneagram,species};
    return items;

}

export const characterPromptSlice = createSlice({
    name: 'characterPrompt',
    initialState: {
        prompt: '',
        savedPrompts: [],
        hairColor: '',
        hc: true,
        hairLength: '',
        hl: true,
        hairStyle: '',
        hs: true,
        height: '',
        ht: true,
        weight: '',
        wt: true,
        skinColor: '',
        sc: true,
        look: '',
        lk: false,
        accessories: '',
        acc: false,
        defining:'',
        def: false,
        enneagram: '',
        enn: false,
        //archetype: '',
        //arch: true,
        species: '',
        spe: false,
        showEnneagram: ''
    },
    reducers :{
        charGen: (state) => {
            state.hairStyle = generate().hairStyle;
            state.hairLength = generate().hairLength;
            state.hairColor = generate().hairColor;
            state.height = generate().height;
            state.weight = generate().weight;
            state.skinColor = generate().skinColor;
            state.look = generate().look;
            state.accessories = generate().accessories;
            state.defining = generate().defining;
            state.enneagram = generate().enneagram;
            //state.archetype = generate().archetype;
            state.species = generate().species;
        },
        displayPrompt(state){
            const hairStyle = state.hairStyle;
            const hairLength = state.hairLength;
            const hairColor = state.hairColor;
            const height = state.height;
            const weight = state.weight;
            const skinColor = state.skinColor;
            const look = state.look;
            const accessories = state.accessories;
            const defining = state.defining;
            const enneagram = state.enneagram;
            //const archetype = state.archetype;
            const species = state.species;

            const hs = state.hs;
            const hl = state.hl;
            const hc = state.hc;
            const ht = state.ht;
            const wt = state.wt;
            const sc = state.sc;
            const lk = state.lk;
            const acc = state.acc;
            const def = state.def;
            const enn = state.enn;
            //const arch = state.arch;
            const spe = state.spe;

            function showEnneagram(){
                let description;
                    if(enneagram==='reformer'){
                        description = enneagrams[0]
                    }
                    if(enneagram==='helper'){
                        description = enneagrams[1]
                    }
                    if(enneagram==='achiever'){
                        description = enneagrams[2]
                    }
                    if(enneagram==='individualist'){
                        description = enneagrams[3]
                    }
                    if(enneagram==='investigator'){
                        description = enneagrams[4]
                    }
                    if(enneagram==='loyalist'){
                        description = enneagrams[5]
                    }
                    if(enneagram==='enthusiast'){
                        description = enneagrams[6]
                    }
                    if(enneagram==='challenger'){
                        description = enneagrams[7]
                    }
                    if(enneagram==='peacemaker'){
                        description = enneagrams[8]
                    }
                    
                    state.showEnneagram = description
                    return description;        
                } 

            const fill1 = (enn || spe)&& (ht || wt) && ' who is '
            const fill2 = def && sc && ' and '
            const fill6 = (hc || hs || hl) && sc && ' and '
            const fill7 = (hc || hs || hl) && ' hair'
            const fill8 = (sc && (ht || wt || spe || enn || hc || hs || hl)) && ' with '
            const fill9 = wt && ht && ' and '
            const fill10 = ((acc && def) || (acc && (hc || hs || hl || enn || spe || ht || wt || lk))) && ' and '
            const fill11 = (def && !acc) && !sc && ' with '
            //const fill12 = (!sc && acc) && (hc || hs || hl || enn || spe || ht || wt || lk) &&' with '

            state.prompt = {
                id: uuidv4(), prompt: [
                    <div className = 'text'>
                
                {lk && look} {enn && <b>{enneagram}<span>{showEnneagram()}</span></b>} {spe && species}
                {fill1}{ht && height}{fill9}{wt && weight}{fill8}{sc && skinColor}{fill6}
                {hs && hairStyle} {hl && hairLength} {hc && hairColor}{fill7}{fill2}{fill11}
                {def && defining}{fill10}{acc && accessories}

                    
                    </div>
                ]};
        },

        selectElements: (state, action) => {
            if(action.payload==='hairStyle'){
                state.hs ? state.hs=false : state.hs=true;
            }
            if(action.payload==='hairLength'){
                state.hl ? state.hl=false : state.hl=true;
            }
            if(action.payload==='hairColor'){
                state.hc ? state.hc=false : state.hc=true;
            }
            if(action.payload==='height'){
                state.ht ? state.ht=false : state.ht=true;
            }
            if(action.payload==='weight'){
                state.wt ? state.wt=false : state.wt=true;
            }
            if(action.payload==='skinColor'){
                state.sc ? state.sc=false : state.sc=true;
            }
            if(action.payload==='look'){
                state.lk ? state.lk=false : state.lk=true;
            }
            if(action.payload==='accessories'){
                state.acc ? state.acc=false : state.acc=true;
            }
            if(action.payload==='defining'){
                state.def ? state.def=false : state.def=true;
            }
            if(action.payload==='enneagram'){
                state.enn ? state.enn=false : state.enn=true;
            }
            if(action.payload==='species'){
                state.spe ? state.spe=false : state.spe=true;
            }
        },
        savePrompt: (state, action) => {
            if(state.savedPrompts.length === 0){
                state.savedPrompts.push(state.prompt);
            }
            for(let i=0 ; i<state.savedPrompts.length ; i++){
                if(state.savedPrompts[i].id===action.payload.prompt.id){
                    return
                }
            }
            state.savedPrompts.push(state.prompt)
        },
        removePrompt: (state, action) => {
            state.savedPrompts = state.savedPrompts.filter((prompt) => prompt.id !== action.payload.id);

        }

    }
})

export const selectCharacterPrompt = (state) => state.characterPrompt;
export const selectSavedPrompts = (state) => state.characterPrompt.savedPrompts;
export default characterPromptSlice.reducer;
export const { displayPrompt, savePrompt, removePrompt, selectElements, charGen } = characterPromptSlice.actions;



/*
masculine feminine neutral

name
species
hair
body type
look
skin tone
accessories
defining characteristic
enneagram
archetype

Your character is 
Name, a peacemaker ruler ghost who is body type with skin tone and hair and defining. Their look is look with accessories.
*/