import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePrompt, selectSavedPrompts,removePrompt, selectRandomElements } from "./randomElementsSlice";

export const RandomSave = ({style}) => {

    const randomElements = useSelector(selectRandomElements);
    const dispatch = useDispatch();
    const savedPrompts = useSelector(selectSavedPrompts);

    const handleClick = () => {
        dispatch(savePrompt(randomElements));

    }

    return (
        <div className='random save'>
            <button id='button2' onClick={()=>handleClick()}>Save</button>
            <div className = 'savedPrompt'>
                {savedPrompts.map((prompt) => (
                    <div>
                        <p style={style}>{prompt.prompt} </p>
                        <p id='button2' onClick={()=> dispatch(removePrompt(prompt))}>remove</p>
                    </div>
                ))}
            </div>
        
        </div>
    )
}


           