import React from "react";

export const Vonnegut = () => {
    return(
        <div className='plotArchs-page'>
            <h1>Kurt Vonnegut’s 6 Story Archetypes</h1>
            <h4>Kurt Vonnegut developed a system of basic plots based not on the actual events of the plot, but on the arc the main character takes as they make their way through them. There were a finite number of these story arcs, he argued, and every story since the creation myth of the Bible follows one of these arcs. (In fact, it was the parallel between the story of the garden of Eden and the story of Cinderella that gave Vonnegut this idea in the first place.)</h4>
            <ol id='plotList'>
                <li><h3>Rise, or “Rags to Riches”</h3></li>
                    <p>A poor protagonist begins in an adverse circumstance and begins an upward journey towards a better life. These stories are popular because readers enjoy seeing someone less privileged make their way to better circumstances through their own determination, resilience, and cleverness. A Little Princess and Matilda are examples of this story type.</p>
                <li><h3>Fall, or “Riches to Rags”</h3></li>
                    <p>The hero begins in a position of privilege and, through their own mistakes or misdeeds, cause their own downfall. These stories often serve as cautionary tales about what happens when we allow our anger or avarice to devour us. The Catcher in the Rye and The Picture of Dorian Gray are examples of this arc.</p>
                <li><h3>Fall Then Rise, or “Man in a Hole”</h3></li>
                    <p>The hero faces a catastrophe that sends them plummeting into trouble, and spends the story fighting their way out. These stories show readers that we have the strength to overcome the obstacles that life throws in our way. The Hobbit and The Hunger Games are examples of this story type.</p>
                <li><h3>Rise Then Fall, or “Icarus”</h3></li>
                    <p>The hero improves their circumstances but, usually through their own pride, loses what they’ve gained and ends up worse than they were before. The shape of this arc is most similar to the story device known as Freytag’s Pyramid, and inspired Gustav Freytag in his work. The Great Gatsby is a classic example of this story arc.</p>
                <li><h3>Rise Then Fall Then Rise, or “Cinderella”</h3></li>
                    <p>A poor protagonist acquires power—which might be external or internal—comes up against an insurmountable obstacle and ends up worse than before, and finally claws their way past their misfortune and limitations to a positive new beginning. These always have a successful or happy conclusion. Most contemporary romances follow this pattern. Jane Eyre and Daphne Du Maurier’s Rebecca also follow this story arc.</p>
                <li><h3>Fall Then Rise Then Fall, or “Oedipus”</h3></li>
                    <p>The hero’s circumstances crumble because of some misfortune, and the hero manages to pull themselves back up—but lets their own major character flaw drag them back down to their unfortunate end. Much like the “Fall,” this story type warns us to be mindful of our weaknesses and allow our strengths to guide us instead. Frankenstein is a classic example of this story type.</p>

            </ol>
            <a href='https://www.vonnegutlibrary.org/' target='_blank' rel="noreferrer"><img src='/images/vonnegut.png'alt='kurt vonnegut museum logo' id='kurtV'/></a>
        </div>
    )
}