import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const PersonalityTypes = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div>
            <header><h1>Personality Types</h1></header>
            <NavLink to='/story-elements/character-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div id='charArchs'>
            <a target="_blank" rel="noreferrer" href ="https://www.16personalities.com/"><img id='img16'src='/images/perstype.png'alt='16 personalities poster'/></a>
            <br/>
            <br/>
            <br/>
                <div className="personalities" >
                    <div className="analyst personality">
                        <h3>Architect</h3>
                        <h5>Most independent</h5>
                        <ul>
                            <li>Theoretical</li>
                            <li>Skeptical</li>
                            <li>Needs to be competent</li>
                            <li>Sees world as chessboard</li>
                            <li>Needs things 'my way'</li>
                            <li>Imaginative and strategic thinkers, with a plan for everything.</li>
                        </ul>
                    </div>
                    <div className="analyst personality">
                        <h3>Logician</h3>
                        <h5>Most conceptual</h5>
                        <ul>
                            <li>Challenges others to think</li>
                            <li>High need for competency</li>
                            <li>Socially cautious</li>
                            <li>Independent</li>
                            <li>Innovative inventors with an unquenchable thirst for knowledge</li>
                        </ul>
                    </div>
                    <div className="analyst personality">
                        <h3>Commander</h3>
                        <h5>Most commanding</h5>
                        <ul>
                            <li>Visionary</li>
                            <li>Gregarious</li>
                            <li>Argumentative</li>
                            <li>Planner</li>
                            <li>Impatient with incompetence</li>
                            <li>Bold, imaginative and strong willed leaders, always finding a way, or making one</li>
                        </ul>
                    </div>
                    <div className="analyst personality">
                        <h3>Debater</h3>
                        <h5>Most inventive</h5>
                        <ul>
                            
                            <li>Given to brinksmanship</li>
                            <li>Tests limits</li>
                            <li>Enthusiastic</li>
                            <li>Innovative</li>
                            <li>Enjoys arguing both sides</li>
                            <li>Likes challenges</li>
                            <li>Smart and curious thinkers who cannot resist an intellectual challenge</li>
                        </ul>
                    </div>
                    <div className="diplomat personality">
                        <h3>Advocate</h3>
                        <h5>Most reflective</h5>
                        <ul>
                            <li>Introspective</li>
                            <li>Quietly caring</li>
                            <li>Creative</li>
                            <li>Eloquent speaker/writer</li>
                            <li>Visionary</li>
                            <li>Quiet and mystical, yet very inspiring and tireless idealists</li>
                        </ul>
                    </div>
                    <div className="diplomat personality">
                        <h3>Mediator</h3>
                        <h5>Most idealistic</h5>
                        <ul>
                            <li>Has strong personal values</li>
                            <li>Seeks inner order and peace</li>
                            <li>Creative</li>
                            <li>Non-directive</li>
                            <li>Reserved with people</li>
                            <li>Poetic, kind, and altruistic people, always eager to help a good cause</li>
                        </ul>
                    </div>
                    <div className="diplomat personality">
                        <h3>Protagonists</h3>
                        <h5>Most persuasive</h5>
                        <ul>
                            <li>Charismatic</li>
                            <li>Idealistic</li>
                            <li>Ignores unpleasantness</li>
                            <li>Sees the potential in others</li>
                            <li>Compassionate</li>
                            <li>Charismatic and inspiring leaders, able to mesmerize their listeners</li>
                        </ul>
                    </div>
                    <div className="diplomat personality">
                        <h3>Campaigners</h3>
                        <h5>Most optimistic</h5>
                        <ul>
                            <li>People oriented</li>
                            <li>Creative</li>
                            <li>Seeks harmony with others</li>
                            <li>Life of the party</li>
                            <li>Better at starting than finishing</li>
                            <li>Enthusiastic, creative, sociable, free spirits, can always find a reason to smile</li>
                        </ul>
                    </div>
                    <div className="sentinel personality">
                        <h3>Logistician</h3>
                        <h5>Most reliable</h5>
                        <ul>
                            <li>Organized</li>
                            <li>Compulsive</li>
                            <li>Private</li>
                            <li>Trustworthy</li>
                            <li>Follows rules</li>
                            <li>Practical and fact minded individuals, who reliability cannot be doubted</li>
                        </ul>
                    </div>
                    <div className="sentinel personality">
                        <h3>Defender</h3>
                        <h5>Most loyal</h5>
                        <ul>
                            <li>Easy to collaborate with</li>
                            <li>Works behind the scenes</li>
                            <li>Sacrifices readily</li>
                            <li>Accountable</li>
                            <li>Very dedicated and warm protectors, ready to defend loved ones</li>
                        </ul>
                    </div>
                    <div className="sentinel personality">
                        <h3>Executive</h3>
                        <h5>Most forceful</h5>
                        <ul>
                            <li>Orderly and structured</li>
                            <li>Sociable</li>
                            <li>Opinionated</li>
                            <li>Results-oriented</li>
                            <li>Productive</li>
                            <li>Traditional</li>
                            <li>Excellent administrators, unsurpassed at managing things or people</li>
                        </ul>
                    </div>
                    <div className="sentinel personality">
                        <h3>Consul</h3>
                        <h5>Most harmonious</h5>
                        <ul>
                            <li>Gracious</li>
                            <li>Thoughtful</li>
                            <li>Lives to please</li>
                            <li>Has strong interpersonal skills</li>
                            <li>Accomplished host/hostess </li>
                            <li>Extraordinarily caring, social and popular people, eager to help</li>
                        </ul>
                    </div>
                    <div className="explorer personality">
                        <h3>Virtuoso</h3>
                        <h5>Most pragmatic</h5>
                        <ul>
                            <li>Observant</li>
                            <li>Often seen as cool and aloof</li>
                            <li>Practical</li>
                            <li>Unpretentious</li>
                            <li>Ready for anything</li>
                            <li>Bold and practical experimenters, masters of all kinds of tools</li>
                        </ul>
                    </div>
                    <div className="explorer personality">
                        <h3>Adventurer</h3>
                        <h5>Most artistic</h5>
                        <ul>
                            <li>Warm</li>
                            <li>Sensitive</li>
                            <li>Unassuming</li>
                            <li>Team player</li>
                            <li>In touch with self and nature</li>
                            <li>Flexible and charming artists, always ready to explore and experience something new</li>
                        </ul>
                    </div>
                    <div className="explorer personality">
                        <h3>Entrepreneur</h3>
                        <h5>Most fun</h5>
                        <ul>
                            <li>Unconventional</li>
                            <li>Fun</li>
                            <li>Gregarious</li>
                            <li>Lives for the here and now</li>
                            <li>Problem solver</li>
                            <li>Smart, energetic, and very perceptive, enjoy living on the edge</li>
                        </ul>
                    </div>
                    <div className="explorer personality">
                        <h3>Entertainer</h3>
                        <h5>Most generous</h5>
                        <ul>
                            <li>Sociable</li>
                            <li>Spontaneous</li>
                            <li>Loves surprises</li>
                            <li>Cuts red tape</li>
                            <li>Juggles many projects</li>
                            <li>Shares readily</li>
                            <li>Spontaneous, energetic, enthusiastic, life is never boring around them</li>
                        </ul>
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}