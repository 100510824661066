import React from "react"
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './about.css'

export const About = () => {

    window.scrollTo(0,0)


    return(
        <div className='page'>
            <LeftSide />
            <div className = 'content'>
                <header>
                    <header>
                        <h1>About</h1>
                    </header>
                    
                    <p></p>
                </header>
                <main id="aboutMain">
                    <div className='aboutDiv'>
                        <h2>Mission Statement</h2>
                        <p> Writician hopes to be a place where writers of all stripes can come and practice their craft, be challenged, share, and grow as writers.
                           
                        </p>
                    </div>
                    <div className='aboutDiv'>
                        <h2>Contact</h2>
                        <p>coming soon</p>
                    </div>
                    <div className='aboutDiv'>
                        <h2>Write for Us</h2>
                        <p>coming soon</p>
                    </div>
                    <div className='aboutDiv'>
                        <h2>Mailing List</h2>
                        <p>coming soon</p>
                    </div>
                    
                    
                    
                    
                </main>
            </div>
            <RightSide />
        </div>
        
    )
}


/*                        <p>If you've got some great writing advice, a unique creative writing exercise, or other writing-related thoughts and want to use Writician as a venue for your work, send us an email with 'blog post' in the subject line.</p>
*/