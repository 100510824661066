import React from "react";
import { Link } from "react-router-dom";
import {Motto} from "./motto";
import {Quote} from "./quote";
import { BlogCarousel } from "../blog/blogCarousel";
import './home.css'


export const Home = () => {

    return(
        <div id='home'className = 'body'>
            <main>
            <div className="level one">
                <section id="welcome">
                    <div>
                        <Motto />
                        <p id='motto-p'>Writician is all about helping you get ideas, fleshing them out,
                        <br/>
                        and turning them into a one-of-a-kind story!</p>
                       
                    </div>
                    
                </section>
                
            </div>

            <div className = 'level two'>
                <section className="get-started">
                    
                    <Link to={`get-idea`}className='navLink'>
                        <div className = 'need idea'>
                            <h2>Need an Idea?</h2>
                            <p>Begin your eight-step journey to discovering an awesome and original story idea.</p>
                        </div>
                    </Link>
                    <Link to={`write-idea`} className='navLink'>
                        <div className="got idea">
                            <h2>Got an Idea?</h2>
                            <p>Begin the seven-step process of transforming that idea into a story.</p>
                        </div>
                    </Link>
                        
                    <Link to={`prompt-generators`} className='navLink'>
                        <div className="get idea">
                            <h2>Prompt Generators!</h2>
                            <p>Get an awesome and original story idea right now.</p>
                        </div>
                    </Link>
                        

                </section>
                <Quote />

            </div>




            <div className="level three">
                <h1>On the Blog</h1>

               <BlogCarousel />

            </div>
                <div className = 'level four'>
                <Quote />

                    <section className="get-started">
                        <Link to={`about`}className='navLink' >
                            <div className = 'got idea'>
                                <h2>Get Involved</h2>
                                <p>Got some great writing advice to share? Want to get your name out there? Write for Writician! </p>
                            </div>
                        </Link>
                        <Link to={`about`}className='navLink' >
                            <div className = 'get idea'>
                                <h2>Contact</h2>
                                <p>Something you want to let us know? Website behaving badly? Let us know!</p>
                                
                            </div>
                        </Link>
                        <Link to={`about`}className='navLink' >
                            <div className = 'need idea'>
                                <h2>Mailing List</h2>
                                <p>Sign up for our mailing list to recieve weekly writing prompts, advice, inspiration and more!</p>                                
                            </div>
                        </Link>
                        

                    </section>
                </div>
            
            </main>

        </div>
        
    )
}