import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Get8 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;
    const step5 = getIdea.step5;
    const step6 = getIdea.step6;
    const step7 = getIdea.step7;
    const step8 = getIdea.step8;

    let [input8, setInput8] =useState('')

    const handleChange =({target})=> {
            setInput8(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput8('')
        }

    return(
        <div id='step-eight'className='step'>
            <h3>Step 8: Creating Your Setting</h3>
            <p className='step-p'>How much world building you need to do will depend on your genre. For obvious reasons, science fiction and fantasy require a lot more intensive world building than others, but no matter what, you still need to build your world, even if your setting is contemporary. Remember that it will always be a place your reader has never been, whether it’s a mystical forest in another realm or the local diner in your hometown.</p>
            <p className='step-p'>Brandon Sanderson gave some great advice in his Creative Writing class which can be seen on youtube. He suggested for world building, especially in the sci-fi/fantasy genres, pick one thing to change and really dive deep in, and everything else will adapt to support it.</p>
            <p className='step-p'>Here are different world building elements to consider, both physically and culturally:</p>
            <div className = 'stepList'>
                <ul id='settingList'>
                    <div id='physical'>
                        <li >Physical</li>
                            <ul >
                                <li>weather patterns</li>
                                <li>tectonic activity</li>
                                <li>ecosystems</li>
                                <li>geography</li>
                                <li>flora & fauna</li>
                            </ul>
                            <ul>
                                <li>magic</li>
                                <li>visuals</li>
                                <li>cosmology</li>
                                <li>climate & terrain</li>
                                <li>races</li>
                            </ul>
                    </div>
                    <div id='cultural'>
                        <li>Cultural</li>
                            <ul>
                                <li>religion</li>
                                <li>government</li>
                                <li>economics</li>
                                <li>gender roles</li>
                                <li>borders</li>
                            </ul>
                            <ul>
                                <li>clothing</li>
                                <li>food</li>
                                <li>rites of passage</li>
                                <li>history</li>
                                <li>social hierarchy</li>
                            </ul>
                            <ul>
                                <li>languages</li>
                                <li>taboos / mores</li>
                                <li>military tradition</li>
                                <li>greetings</li>
                                <li>swear words / slang</li>
                            </ul>
                    </div>
                    
                </ul>
                </div>
                <p id='example'>Example 1<span>
                    We’ll place our hero in a world where the only habitable land is at the poles, meaning six months of light and six months of darkness. Think about how their culture would adapt to this predicament. Their plants and animals, social structure, jobs, art, religion and lore.                         </span></p>
                    <p id='example'>Example 2<span>
                    Our jungle setting is historical so it should be based on a real place. Mexico, Southeast Asia, Sub-saharan Africa, Papa New Guinea? It will take some research to flesh one of these settings out. But if he’s in the Amazon looking for lost Inca Gold dropped by the Spaniards, instead of trying to learn everything there is about the Amazon and it’s many cultures, pick a few things and deep dive in them. Maybe its the flora and fauna, or the indigenous peoples' weapons, tattoos, or rituals.                        </span>
                    </p>
                    <div className='input'>
                        <label>Write your setting here:  </label>
                        <textarea id='step8' className='stepInput' type='text' value={input8} onChange={handleChange} />
                        {input8!==''&&<button onClick={()=>handleClick('step8', input8)}id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                    <p>Genre(s): <b>{step1}</b></p>
                    <p>Plot Archetype: <b>{step2}</b></p>
                    <p>Character: <b>{step3}</b></p>
                    <p>Theme(s): <b>{step4}</b></p>
                    <p>Want, Need, Truth, Lie, Flaws & Weaknesses: <b>{step5}</b></p>
                    <p>Opposition: <b>{step6}</b></p>
                    <p>Supporting Cast: <b>{step7}</b></p>
                    <p>Setting: <b>{step8}</b></p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='../get-idea-step-7'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../get-idea-summary'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}