import React from "react";


export const Hero = () => {
    return(
        <div className='plotArchs-page'>
            <h1>The Hero’s Journey</h1>
            <h4>The hero's journey, also known as the monomyth, is the common template of stories that involve a hero who goes on an adventure, is victorious in a decisive crisis, and comes home changed or transformed.</h4>
            <br/>
            <ol id='plotList'>
                <li><h3>The Ordinary World</h3></li>
                    <p>The narrative introduces the protagonist, or hero, providing a glimpse of them in their ordinary, everyday environment. This initial stage sets the scene, illustrating the hero's connection with their surroundings and offering subtle clues about impending conflicts. It serves as a reminder to the reader that, akin to us, many heroes emerge from modest origins.</p>
                <li><h3>The Call to Adventure</h3></li>
                    <p>The hero encounters a challenge or predicament that propels them into their adventure. Whether stemming from a malevolent force, a shift in circumstances, or their own curiosity, this development alters the familiar landscape of their life. In terms of story structure, this pivotal moment is often referred to as the inciting incident.</p>
                <li><h3>Refusal of the Call</h3></li>
                    <p>The hero begins to question the wisdom of their undertaking. Doubts may arise about their suitability for the task, fears about potential consequences may surface, or they might realize a desire for a simpler pursuit like making tea and watching Netflix. In this phase, the hero critically assesses what truly matters and the reasons compelling them to progress.</p>
                <li><h3>Meeting the Mentor</h3></li>
                    <p>The hero encounters the initial turning point in their journey. Although mentally geared up for their quest, they lack essential tools for success—be it a tangible object, information, a specific skill set, or a surge of self-confidence. It is at this juncture that they encounter another character capable of providing what is necessary to progress to the next stage.</p>
                <li><h3>Crossing the Threshold</h3></li>
                    <p>The hero traverses the boundary separating the familiar world behind them from the unknown world ahead, reaching a juncture where returning is no longer an option. This could involve leaving home, accepting a job offer, or taking an irreversible action. Until now, the hero has been subject to external events; however, at this stage, they commence making choices of their own. Typically, this moment signifies the commencement of the second act.</p>
                <li><h3>Tests, Allies, Enemies</h3></li>
                    <p>The hero finds themselves deeply entrenched in the realm of their quest, encountering a series of challenges that must be overcome. Along this path, the hero forms new alliances and contends with fresh adversaries, occasionally facing ambiguity about the true nature of these relationships. This phase of the hero's journey typically occupies the majority of the second act of the plot and serves as the prime period for introducing a host of supporting characters.</p>
                <li><h3>Approach to the Inmost Cave</h3></li>
                    <p>The hero nears the focal point of their mission. This could manifest as a tangible location, like the villain's lair, or it might signify the convergence of the hero's and their friends' collective efforts, reaching a climax of personal insecurities and fears. This stage bears a resemblance to a reversal of the threshold, bringing the ultimate boundary into view—a point beyond which the world will undergo irreversible change once more.</p>
                <li><h3>The Ordeal</h3></li>
                    <p>The crossing of the threshold takes a drastically unexpected turn. Termed as the "black moment," the "belly of the whale," or the "dark night of the soul," this stage thrusts the hero into a confrontation with their deepest fear. Although they endure, it is not without lasting scars. A metaphorical (or literal) death and rebirth unfold, with the hero emerging from this ordeal profoundly changed and prepared for the ensuing phase of their adventure. Typically, this marks the pinnacle of the hero's character arc.</p>
                <li><h3>Reward</h3></li>
                    <p>Fortunes take a positive turn, if only temporarily. The hero attains their objective, whether it be securing a sacred object, acquiring valuable information, or reuniting with a loved one. Having weathered numerous challenges to reach this destination, they now have something worthwhile to show for their enduring efforts.</p>
                <li><h3>The Road Back</h3></li>
                    <p>The hero endeavors to return home with their gains, only to discover that their journey has spawned consequences demanding immediate attention. The attainment of their goal has set new adversaries into motion. In this moment, there echoes a resonance of the initial call to adventure; the hero faces the choice of abandoning their journey now that they've secured what they fought for. However, they opt to place themselves at risk for the greater good.</p>
                <li><h3>Resurrection</h3></li>
                    <p>The hero faces their greatest battle of all, a challenge extending beyond personal goals, one that impacts the well-being of the broader world. In this pivotal moment, the hero demonstrates that they have gleaned valuable lessons from the trials of their ordeal, undergoing a conclusive transformation into a genuine hero.</p>
                <li><h3>Return with the Elixir</h3></li>
                <p>The hero at last returns to the normal world, yet they are no longer the same individual who embarked on the journey. Having evolved, acquired wisdom, and transformed into the person they were destined to become, the hero carries with them the sought-after goal—be it a sacred object, newfound knowledge, or an enriched sense of self. Despite emerging victorious, some scars may linger. Now, the hero embarks on the commencement of the next chapter in their life.</p>
            </ol>
            <a rel="noreferrer" href='https://www.abebooks.co.uk/9781567311204/Hero-Thousand-Faces-Campbell-Joseph-1567311202/plp' target='_blank' ><img src='/images/hero1000.jpeg' alt='hero with a thousand faces by Joseph Campbell'/></a>
        </div>
    )
}