import React,{ useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { selectRandomElements, randoGen } from "./randomElementsSlice";
import { RandomSave } from "./randomSave";

export const RandomElements = () => {

    let randomElements = useSelector(selectRandomElements);
    const dispatch = useDispatch()
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(()=>{
        if(randomElements !== ''){
            setSave(true);
            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[randomElements])



    const handleClick = () => {
        dispatch(randoGen());
        save? setSave(false): setSave(true)
    }

    return(
        <div className='prompt'id='randomPrompt'>
            <p style={style}>{randomElements.prompt}</p>
            <button onClick={()=>handleClick()}>Generate Random Story Elements</button>
            {save && <RandomSave style={style}/>}
        </div>
    )
}