import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { plots } from "../prompts/plotPrompt/descriptions";
import { Link } from "react-router-dom";


export const Get2 = () => {
    

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])


    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2;

    let [input2, setInput2] =useState('')

    const handleChange =({target})=> {
            setInput2(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput2('')
        }

        function showPlot(plot){
            let description;
            if(plot==='overcoming the monster'){
                description = plots.overcoming
                }
                if(plot==='rags to riches'){
                    description = plots.rags
                }
                if(plot==='voyage and return'){
                    description = plots.voyage
                }
                if(plot==='quest'){
                    description = plots.quest
                }
                if(plot==='comedy'){
                    description = plots.comedy
                }
                if(plot==='tragedy'){
                    description = plots.tragedy
                }
                if(plot==='rebirth'){
                    description = plots.rebirth
                }
                if(plot==='rebellion against the one'){
                    description = plots.rebellion
                }
                if(plot==='mystery'){
                    description = plots.mystery0}
                return description;

        }

    return(
        <div id='step-two'className='step'>
            <h3>Step 2: Plot Archetypes</h3>
            <p className='step-p'>There are many theories on how many different types of stories are out there. Some say 1, some say 7, or 9, or 36, or 100+. I believe by looking at the following list, while also contemplating your genre, a little story idea is bound to sprout.</p>
            <div className = 'stepList'>
                <ul>
                    <li>Overcoming the Monster<span>{showPlot('overcoming the monster')}</span></li>
                    <li>Rags to Riches<span>{showPlot('rags to riches')}</span></li>
                    <li>The Quest<span>{showPlot('quest')}</span></li>
                    <li>Voyage and Return<span>{showPlot('voyage and return')}</span></li>
                    <li>Comedy<span>{showPlot('comedy')}</span></li>
                    <li>Tragedy<span>{showPlot('tragedy')}</span></li>
                    <li>Rebirth<span>{showPlot('rebirth')}</span></li>
                    <li>Mystery<span>{showPlot('mystery')}</span></li>
                    <li>Rebellion Against ‘The One’<span>{showPlot('rebellion against the one')}</span></li>
                    
                </ul>

            </div>
            <p id='example'>Examples<span>Example 1: Wild-West Space Opera Mystery <br/> Example 2: Historical Thriller Quest </span></p>

            <div className='input'>
                <label>Write your Archetype here:  </label>
                <textarea id='step2'className='stepInput' type='text' value={input2} onChange={handleChange} />
                {input2!==''&& <button onClick={()=>handleClick('step2', input2)}id='button2'>Enter</button>}
            </div>
            <div className='savedInput'>
                <p>Genre(s): <b>{step1}</b></p>
                <p>Plot Archetype: <b>{step2}</b></p>
            </div>
            <div className='stepArrows'>
                <Link to='../get-idea-step-1'><button id='button2'><span>Back</span></button></Link>
                <Link to='../get-idea-step-3'><button id='button2'><span>Next</span></button></Link>
            </div>
    </div>
             
       
    )
}