import React, {useState, useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import { PremiseSave } from "./premiseSave";
import { selectStoryPremise,premiseGen } from "./storyPremiseSlice";


export const StoryPremise = () => {

    const dispatch = useDispatch();
    const storyPremise = useSelector(selectStoryPremise);
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});


    useEffect(()=>{
        if(storyPremise !== ''){
            setSave(true);
            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[storyPremise])


    const handleClick = () => {
        dispatch(premiseGen());
        save? setSave(false): setSave(true)

    }

    return(
        <div className = 'prompt'id="premisePrompt">
            <p style={style}>{storyPremise}</p>
            <button onClick={()=> handleClick()}>Generate New Story Premise</button>
            {save && <PremiseSave style={style} />}
        </div>
    )
}