import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import { genres } from "../../prompts/plotPrompt/descriptions";

export const Genres = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return(
        <div>
            <header><h1>Genres</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className = 'genres'>
                
                <div className='genre'>{genres.scifi}</div>
                <div className='genre'>{genres.spaceOpera}</div>
                <div className='genre'>{genres.firstContact}</div>
                <div className='genre'>{genres.dystopian}</div>
                <div className='genre'>{genres.steampunk}</div>
                <div className='genre'>{genres.postApocalyptic}</div>
                <div className='genre'>{genres.altHistory}</div>
                <div className='genre'>{genres.fantasy}</div>
                <div className='genre'>{genres.highFantasy}</div>
                <div className='genre'>{genres.lowFantasy}</div>
                <div className='genre'>{genres.epic}</div>
                <div className='genre'>{genres.urbanFantasy}</div>
                <div className='genre'>{genres.magicalRealism}</div>
                <div className='genre'>{genres.cyberpunk}</div>
                <div className='genre'>{genres.superHero}</div>
                <div className='genre'>{genres.literaryFiction}</div>
                <div className='genre'>{genres.romance}</div>
                <div className='genre'>{genres.historicalFiction}</div>
                <div className='genre'>{genres.western}</div>
                <div className='genre'>{genres.mystery}</div>
                <div className='genre'>{genres.thriller}</div>
                <div className='genre'>{genres.adventure}</div>
                <div className='genre'>{genres.horror}</div>
                <div className='genre'>{genres.spyFiction}</div>
                <div className='genre'>{genres.satire}</div>
                <div className='genre'>{genres.heist}</div>
                <div className='genre'>{genres.middleGrade}</div>
                <div className='genre'>{genres.youngAdult}</div>
                

            </div>
        </div>
    )
}

                        
                       
                       