import React from "react";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";


export const PlotArchsPage = ()=> {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className="plotArchs-page">
            <header><h1>Plot Archetypes</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <nav className='archsNav'>
                <ul>
                    <NavLink to='hero' className='link'>Hero's Journey</NavLink>
                    <NavLink to='vonnegut' className='link'>Kurt Vonnegut’s 6 </NavLink>
                    <NavLink to='booker' className='link'>Christopher Booker’s 7</NavLink>
                    <NavLink to='tobias' className='link'>Ronald Tobias’s 20</NavLink>
                    <NavLink to='polti' className='link'>Georges Polti’s 36</NavLink>
                    <NavLink to='card' className='link'>OSC's M.I.C.E.</NavLink>
                </ul>
            </nav>
            <Outlet />
        </div>
    )
}