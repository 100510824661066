import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const Subplots = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className='pages'>
            <header><h1>Subplots</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className='genres'>
                <div id='subplot' className='genre'>
                    <h3>The Isolated Chunk</h3>
                    <ul>
                        <li>Disregard transitional elements and initiate a new section or chapter. Share your embedded story, and subsequently, resume your primary narrative. </li>
                        <li>This technique proves particularly advantageous in first-person narratives, where the main character can only undergo one experience at a time.</li>
                    </ul>
                </div>
                <div id='subplot'className='genre'>
                    <h3>The Parallel Line</h3>
                    <ul>
                        <li>Start your story with the primary plot, introducing your main cast of characters, particularly your hero. Concurrently, weave in the inception of your secondary plot. Alternate between the two narratives evenly to underscore their symmetrical or diametric characteristics.</li>
                        <li>You have the flexibility to determine the size and significance of your parallel plot as needed.</li>
                        <li>Plots may remain separate throughout or converge briefly, perhaps only at the conclusion.</li>
                    </ul>
                </div>
                <div id='subplot'className='genre'>
                    <h3>The Swallowtail</h3>
                    <ul>
                        <li>Introducing an alternation between two or more parallel plots (though exceeding three may lead to reader confusion) allows for the convergence of distinct characters and their narratives at a shared focal point, such as a common element or event.</li>
                        <li>This focal point could manifest as a location, person, family, event, and so forth.</li>
                    </ul>

                </div>
                <div id='subplot'className='genre'>
                    <h3>The In-and-Out</h3>
                    <ul>
                        <li>Allow your subplots to enter and exit as required. For instance, you can introduce a mentor in the early chapters, have them provide guidance, and then send them on a separate journey unrelated to the main story. Bring them back in a later chapter, making them available for further consultation with the hero. The mentor may have encountered challenges during their absence, perhaps even bringing back issues to address within the story.</li>
                        <li>If employing a first-person narrative, consider incorporating chapters written in the third person for added narrative flexibility.</li>
                    </ul>

                </div>
                <div id='subplot'className='genre'>
                    <h3>The Bookend</h3>
                    <ul>
                        <li>Compose and incorporate two distinct segments, with a substantial gap between them within the narrative.</li>
                        <li>Include a touch of foreshadowing somewhere in the middle of the story.</li>
                        <li>Consider reserving a subplot to conclude last, after the main plot, providing an unexpected delight for the reader.</li>
                    </ul>
                </div>
                <div id='subplot'className='genre'>
                    <h3>The Bridge Character</h3>
                    <ul>
                        <li>Create a character who starkly contrasts with your existing cast, someone immersed in a distinct world. Alternatively, identify the two separate realms you aim to connect and devise a character capable of bridging them.</li>
                        <li>Individuals in professions such as doctors, lawyers, counselors, and clergy possess significant potential as bridge characters. Why? Because people often confide in them, sharing their secrets.</li>
                    </ul>

                </div>
                <div id='subplot'className='genre'>
                    <h3>The Clue</h3>
                    <ul>
                        <li>Introduce clues systematically and consistently, recognizing a crucial distinction: a clue for your fictional sleuth differs from a cue for your reader.</li>
                        <li>Include them even if their resolution remains uncertain at the moment.</li>
                    </ul>

                </div>

            </div>
        </div>
    )
}