import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const GetSummary = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;
    const step5 = getIdea.step5;
    const step6 = getIdea.step6;
    const step7 = getIdea.step7;
    const step8 = getIdea.step8
    const stepSummary = getIdea.summary;

    let [inputSummary, setInputSummary] =useState('')

    const handleChange =({target})=> {
            setInputSummary(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInputSummary('')
        }

    return(
        <div id='step-summary'className='step'>
            <h3>Your Idea Summary</h3>
            <p className='step-p'>Congratulations, you've made it through all eight steps! Now, sum up your entire idea in one paragraph.
                This should help to see the big picture and chrystalize the whole idea in your mind. But don't think that any of these decisions
                are now written in stone. As you write the idea will evolve, and some of these eight steps may be altered, or changed entirely. But for now,
                pat yourself on the back and bask in the glory of your awesome story idea.
            </p>
               
                     <p id='example'>Example 1<span>
                    A Wild West Space Opera Mystery about a Defender-Lover Soldier who’s lost honor drives him to uncover the true criminal who’s framed him, though his lack of assertiveness and un-enhanced intelligence, along with his commitment to righteous principles will make his work difficult in bringing down the local warlord who he will discover is behind his fall. All this on a world where the only inhabitable land is at the poles, where the culture is centered around having adapted to six months of light and six months of darkness.
                    </span></p>
                    <p id='example'>Example 2<span>
                    A Historical Thriller Quest about a grieving Virtuoso-Rebel who must make a sacrifice as he finds the will to survive in the wake of his wife’s passing, while he seeks an ancient artifact in the Amazon jungle. Treachery from his team exposes his grief, which haunts him in the form of a local wraith-deity, his stubbornness, as well as a local child he decides to take responsibility for make things difficult when dealing with the rival Treasure Hunter who has no scruples about leaving men behind or trampling local lives.
                    </span>
                    </p>
                    <div className='input'>
                        <label>Write your summary here:  </label>
                        <textarea rows = "4" cols = "40" id='step5' className='stepInput' type='text' value={inputSummary} onChange={handleChange}/>
                        {inputSummary!==''&&<button onClick={()=>handleClick('step-summary', inputSummary)}id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                    <p>Genre(s): <b>{step1}</b></p>
                    <p>Plot Archetype: <b>{step2}</b></p>
                    <p>Character: <b>{step3}</b></p>
                    <p>Theme(s): <b>{step4}</b></p>
                    <p>Want, Need, Truth, Lie, Flaws & Weaknesses: <b>{step5}</b></p>
                    <p>Opposition: <b>{step6}</b></p>
                    <p>Supporting Cast: <b>{step7}</b></p>
                    <p>Setting: <b>{step8}</b></p>
                    <p>Summary: {stepSummary}</p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='../get-idea-step-8'><button id='button2'><span>Back</span></button></Link>
                        <Link to ='/write-idea'> <button id='button2'> Ready to Write?</button></Link>

                    </div>
                        
        </div>
             
       
    )
}