import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write6 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 6: Plot out Scenes and Sequels</h3>
            <p className='step-p'>Depending on the length of your story, you may have anywhere from one, to a hundred or more sets of scenes and sequels. These drive your story forward by making sure there is enough conflict, dilemmas, and decision making on the part of your protagonist.</p>
            <p className='step-p'>A simple outline is:</p>
            <ul className = 'plotList'>
                <li><b>Scene:</b></li>
                <ul>
                    <li>Goal</li>
                    <li>Conflict</li>
                    <li>Disaster</li>
                </ul>
                <li><b>Sequel:</b></li>
                <ul>
                    <li>Reaction</li>
                    <li>Dilemma</li>
                    <li>Decision</li>
                </ul>
                
            </ul>
            <p className='step-p'>At the start of every scene, your protagonist must have a goal, either explicitly stated or implied. They don't get what they want right away, because then there is no story. In comes conflict. Raise the stakes and in comes the disaster. That's a scene.</p>
            <p className='step-p'>The sequel is how they deal with the disaster. First comes the reaction. How do they respond? What will they do next? But it can't be so easy. There are no good choices. They must choose a course of action and in so doing, sacrifice something. Then comes the decision, which in turn, creates another goal. The cycle continues.</p>
           <br/>
            <ul className = 'plotList'>
                <li><b><u>Scene:</u></b> Unified by time, no breaks or lapses in the continuous flow of events.</li>
                <ul>
                    <li><b>Goal</b>- Specific and concrete. Of achievement or resistance.
                        <ul>
                            <li>Character must want something:
                                <ul>
                                    <li>Possession of something.</li>
                                    <li>Relief from something.</li>
                                    <li>Revenge for something.</li>
                                </ul>
                            </li>
                            
                        </ul>
                    </li>
                    <li><b>Conflict</b>- Opposition to character’s goal.
                        <ul>
                            <li>External difficulties, obstacles, complications, hindrances.</li>
                            <li>Two entities striving to attain mutually incompatible goals. </li>
                        </ul>
                    
                    </li>
                    <li><b>Disaster</b>- A logical yet unanticipated development that throws your focal character for a loss. </li>
                </ul>
                <br/><br/>
                <li><b><u>Sequel:</u></b> Making a decision may take time. Summary is often necessary here, and some speeding up or slowing down of time.</li>
                <ul>
                    <li><b>Reaction</b>- Your character’s reaction to their very real, very personal disaster.
                        <ul>
                            <li>How does the disaster change your character’s state of mind(internal) and state of affairs (external)?</li>
                        </ul>
                    </li>
                    <li><b>Dilemma</b>- Character is faced with two unsatisfactory options.</li>
                    <li><b>Decision</b>- Character makes a hard choice, setting up the goal for the next scene.</li>
                </ul>
                
            </ul>
            <br/><br/>
            <p className='step-p' >Write out scenes and sequels for your entire story. You don't have to do them all at once, but the more you do, the easier it will be to write, and finish, your story.</p>
           
            <div className='stepArrows'>
                        <Link to='../write-idea-step-5'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../write-idea-step-7'><button id='button2'><span>Next</span></button></Link>
                    </div>
        </div>
    )}