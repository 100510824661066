import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Get6 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;
    const step5 = getIdea.step5;
    const step6 = getIdea.step6;

    let [input6, setInput6] =useState('')

    const handleChange =({target})=> {
            setInput6(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput6('')
        }

    return(
        <div id='step-six'className='step'>
            <h3>Step 6: Finding the Opposition</h3>
            <p className='step-p'>Now this is where things get interesting. This is what makes the story an actual story: the real danger. This is the immovable force meeting another immovable force and only one can come out with what they want. So what stands in the hero’s way? Why can’t they get what they want?</p>
            <p className='step-p'>Your protagonist is only as interesting as their opponent. We need to make them big, mean and scary. Or, perhaps they are polished, articulate and kind. Even scarier, knowing they are ruthless under the surface.
                Maybe it's not a person at all, but a force of nature, an inner demon, the weight of the world. 
            </p>
            <p className='step-p'>If it is a person, and usually it should be at least symbolized in one person, your villain should be just as complex as your protagonist. They have a goal, and a pretty good justification for doing the bad things they do to achieve it. Their goal has to be in direct opposition to the hero’s. And they should be competent, where your hero probably isn’t. Not, right away, at least.</p>
            <div className = 'stepList'>
               <ul>

               </ul>
                </div>
                <p id='example'>Example 1<span>
                        Our Space Opera Defender Lover has to get back his honor. How did he lose it? A Lover wants connection and fears isolation. So let’s get him alone. Stranded on a desert planet, barely inhabited. The galactic government has no presence here. He’s self exiled for failing his king/commander/leader. The only thing that will make things right is if he brings the true criminal to justice. So the mystery is, who framed him? He must be tracking him down in this lawless place. Perhaps he’s the local warlord who runs the show now. Our Lover must bring people together…rival gangs?…in order to take this guy down.
                        </span></p>
                    <p id='example'>Example 2<span>
                        Our Treasure Hunting Rebel, who stubbornly plays by his own rules, sick with grief, fighting the elements, let’s say he’s got another treasurer hunter to deal with. No, even better, he has a mutiny to deal with. He’s had to hire on some new men after the loss of his wife. Her brother is also out of the game now that she’s gone. The new guys don’t like our hero’s brashness, and can sense him slipping as he deals with grief and the complications of the Quest. They decide to take the relic for themselves. <br/>
                        Or, maybe Our Virtuoso is running for his life, and the opposition could be nature itself. Though it is often better to personify it. A beast of some sort could work, as in King Kong, and that can be one level of the opposition. But better someone else trying to get that artifact. Someone with more resources, evil intent. <br/>
                        <br/>***If we want to go the King Kong route and overcome the monster to escape the jungle, we can switch the plot archetype. Maybe our genre can shift/blend as well, as we give it a sci-fi edge by making the monster an alien. Or we can give it a fantasy or magical realism twist and the monster is something supernatural, local wraith/demon/deity. Make it slipstream, and the monster is the Rebel’s own grief personified in a specter only he can see, but the reader doesn’t find that out until the end.

                        <br/><br/>So many possibilities...
                        </span>
                    </p>
                    <div className='input'>
                        <label>Write about your antagonist here:</label>
                        <textarea id='step6' className='stepInput' type='text' value={input6} onChange={handleChange} />
                        {input6!==''&&<button onClick={()=>handleClick('step6', input6)}id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                    <p>Genre(s): <b>{step1}</b></p>
                    <p>Plot Archetype: <b>{step2}</b></p>
                    <p>Character: <b>{step3}</b></p>
                    <p>Theme(s): <b>{step4}</b></p>
                    <p>Want, Need, Truth, Lie, Flaws & Weaknesses: <b>{step5}</b></p>
                    <p>Opposition: <b>{step6}</b></p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='../get-idea-step-5'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../get-idea-step-7'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}