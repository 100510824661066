import React from "react";

export const BlogPost1 =() => {

        window.scroll(0,0)
  

    return(
        <div className='blogPost'>
                
                <div className='blogLeft'>
                    <img className='blogImage' src='/images/assortedpics/amandine-bataille-5W78I6Jvbjw-unsplash.jpg' alt='wizard writing desk'/>
                    <p className = 'blogDate'>Mar 1, 2024</p>
                    <div className = 'blogNav'>
                        <h4>Contents</h4>
                        <ul>
                            <li><a href= '#all-about' >What it's all about</a></li>
                            <li><a href='#using-the-site'>Using the site</a></li>
                            <li><a href='#what-to-expect'>What to expect</a></li>
                        </ul>
                    </div>
                </div>
                

                <div className='blogTop'>
                    <div className='blogTitle'>Welcome to Writician!</div>
                    <div className='subtitle'>
                        <p>
                            If you're reading this then you're one of the first lucky ones who've discovered our site; 
                            a new space for creative writers to learn, share, and grow together. 
                            We've got a lot of plans for the site, and we're hoping we can make this journey together with all of you.
                        </p>
                
                    </div>
                </div>
            

                <div className ='blogSection'>
                    <h3 id='all-about'>Where It's All About</h3>
                    <p>Creative writing! That's for sure. Writing unique, imaginative, and well written stories.
                        We want to help you develop your ideas and finish strong. We want to help you get published.</p>
                        
                        <p>Together, let's bring more
                        original, authentic, amazing stories into the world.</p>
            
                    <h3 id='using-the-site'>Using the Site</h3>

                    <p>As of right now, we have three main features available and a good amount of reference material.</p>
                    <p>The prompt generators are the crown jewel, and in the future users will be able to submit stories 
                        based on these prompts.
                    </p>
                    <p>Get an Idea and Write Your Idea are short, intro-to-writing tutorials to help you finish short and even longer
                        projects. They are guidelines for getting those amazing ideas of yours out into the world.
                    </p>
                    <h3 id='what-to-expect'>What to Expect</h3>
                    <p>Amazing things! Hopefully...sharing stories based on prompts, 
                        comment sections and user profiles, writing contests, critique groups, a writing advice and general creative writing forum...So much, just you wait and see!
                    </p>

                    <img alt='writician logo' src='/images/full-logo-trans.png' width='300px' />

                
                </div>
            

        </div>
    )
}