import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const Get7 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;
    const step4 = getIdea.step4;
    const step5 = getIdea.step5;
    const step6 = getIdea.step6;
    const step7 = getIdea.step7;

    let [input7, setInput7] =useState('')

    const handleChange =({target})=> {
            setInput7(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput7('')
        }

    return(
        <div id='step-seven'className='step'>
            <h3>Step 7: Choosing a Supporting Cast</h3>
            <p className='step-p'>Now, we need to fill in our cast who will help and hinder our hero and towards his goal. Journey back on up to the character archetype page and choose who you think fits best in your story.</p>
            <div id='supportingCastList'>
                <ul>
                    <li>a sidekick</li>
                    <li>a mentor</li>
                    <li>a motherly figure</li>
                    <li>comedic relief</li>
                    <li>a rival (who is not the villain)</li>
                </ul>
                <ul>
                    <li>a love interest</li>
                    <li>a skeptic</li>
                    <li>a guardian</li>
                    <li>someone who represents emotion</li>
                    <li>someone who represents reason</li>
                </ul>
                </div>
                <p className='step-p'>Some characters may play multiple roles. Some roles will be larger or smaller than others.</p>

                    <p id='example'>Example 1<span>
                        For our Wild West Space Opera, our soldier is out to prove his innocence and restore his honor. So he’ll need a sidekick on this new planet to show him around. Maybe his commander that he is framed for betraying was his mentor, but probably only seen, if at all, in the beginning, or in flash backs. As a Lover, he’s likely to have a love interest to symbolize and solidify his bringing together the locals to help him solve his case and/or overthrow the warlord.                        </span></p>
                    <p id='example'>Example 2<span>
                    Our Treasure hunter will need a crew he’s out there with. A rival could be a false-enemy who ends up helping him against the real villain. A guardian may be a local who helps them. A skeptic in the crew, possibly the rival, villain, or combined with the voice of emotion. A voice of reason will be critical here, as he fights with his grief, the mutiny, the fight against nature, and the ultimate decision to leave the relic (or the search for it) and get his people out safely.
                        </span>
                    </p>
                    <div className='input'>
                        <label>Write about your supporting cast here:  </label>
                        <textarea id='step7' className='stepInput' type='text' value={input7} onChange={handleChange} />
                        {input7!==''&&<button onClick={()=>handleClick('step7', input7)}id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                    <p>Genre(s): <b>{step1}</b></p>
                    <p>Plot Archetype: <b>{step2}</b></p>
                    <p>Character: <b>{step3}</b></p>
                    <p>Theme(s): <b>{step4}</b></p>
                    <p>Want, Need, Truth, Lie, Flaws & Weaknesses: <b>{step5}</b></p>
                    <p>Opposition: <b>{step6}</b></p>
                    <p>Supporting Cast: <b>{step7}</b></p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='../get-idea-step-6'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../get-idea-step-8'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}