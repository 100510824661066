import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './write.css'

export const WriteIdea = () => {
    window.scrollTo(0,0)

    return(
        <div className='page' >
            <LeftSide />
            <div className='content'>
                <header>
                    <h1>7 Steps for Transforming Your Idea Into a Story</h1>
                </header>
                <div className='body' id='write-idea'> 
                
                        <nav className='sideNav'>
                                <button class="sidebtn" id='button3'>Menu
                                    <i class="fa fa-caret-down"></i>
                                </button>
                                <div class="sidedown-content">
                                    <NavLink to='write-idea-step-1' id='button3' className='sideLink'><span className="text">1. Story Premise</span></NavLink>
                                    <NavLink to="write-idea-step-2" id='button3'className='sideLink' ><span className="text">2. Disasters/ Resolution</span></NavLink>
                                    <NavLink to="write-idea-step-3" id='button3' className='sideLink'><span className="text">3. Character Arc</span></NavLink>
                                    <NavLink to="write-idea-step-4/positive-arc" id='button3'className='sideLink' ><span className="text">4. Story Structure Summary</span></NavLink>
                                    <NavLink to="write-idea-step-5" id='button3' className='sideLink'><span className="text">5. Three Act Outline</span></NavLink>
                                    <NavLink to="write-idea-step-6"id='button3' className='sideLink'><span className="text">6. Scenes and Sequels</span></NavLink>
                                    <NavLink to="write-idea-step-7" id='button3' className='sideLink'><span className="text">7. MR-Units</span></NavLink>
                                </div>
                        </nav>

                    <Outlet />
                    
                </div>
            

            </div>
            <RightSide />
            
        </div>
    )
}