import React from "react";

export const Booker = () => {
    return(
        <div className='plotArchs-page'>
            <h1>Christopher Booker’s 7 Story Archetypes</h1>
            <br/>
            <h4><i>The Seven Basic Plots: Why We Tell Stories</i>  is a 2004 work by Christopher Booker that offers a Jung-influenced analysis of stories and their psychological significance. Booker dedicated 34 years to the development of this book, which delves into the foundations of storytelling across literature, film, and libretto. It seeks to unravel the mystery of whether there exist only a limited number of 'basic stories' in the world. Through numerous examples, the book illustrates the existence of seven archetypal themes. Additionally, the author guides readers through the evolution in the nature of storytelling.</h4>
            <h2>Meta Plot</h2>
            <ul>
                <li><h3>Anticipation stage</h3></li>
                    <p>The hero is called to the adventure to come.</p>
                <li><h3>Dream stage</h3></li>
                    <p>The adventure begins, the hero has some success, and has an illusion of invincibility.</p>
                <li><h3>Frustration stage</h3></li>
                    <p>The hero has his first confrontation with the enemy, and the illusion of invincibility is lost.</p>
                <li><h3>Nightmare stage</h3></li>
                    <p>The climax of the plot, where hope is apparently lost.</p>
                <li><h3>Resolution stage</h3></li>
                    <p>The hero overcomes his burden against the odds.</p>
            </ul>
            <br/>
            <br/>
            <h2>7 Archetypes</h2>
            <ol id='plotList'>
                <li><h3>Overcoming the Monster</h3></li>
                    <p>The protagonist sets out to defeat an antagonistic force (often evil) which threatens the protagonist and/or protagonist’s homeland.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>The threat of the monster becomes known</p>
                        <li><h4>Driver</h4></li>
                            <p>The Call: The hero is called upon to confront monster</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>All begins well as the hero prepares to fight or journeys to confront</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Coming face to face with the monster, the hero appears outmatched</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>The final battle, seems hopeless for the hero.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>Hero overthrows the monster, escapes, (possibly with treasure/princess/kingdom)</p>
                        <br/>
                        <li>Examples: <i>Perseus, Theseus, Beowulf, Dracula, The War of the Worlds, Nicholas Nickleby, The Guns of Navarone, Seven Samurai (The Magnificent Seven), James Bond, Jaws, Star Wars, Attack on Titan.</i></li>
                    </ul>
                <li><h3>Rags to Riches</h3></li>
                    <p>The poor protagonist acquires power, wealth, and/or a mate, loses it all and gains it back, growing as a person as a result.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>Initial wretchedness, we see the hero unhappy and low.</p>
                        <li><h4>Driver</h4></li>
                            <p>The Call: The hero is called into the wider world.</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>Out in the world, enjoys initial success, gets a taste of higher status, possibly meets true love, (someone previously known to them).</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Central Crisis: everything goes wrong. Hero is separated from true love.</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>Independence and final ordeal. Hero discovers his true strength and proves it by defeating his rival.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>Hero wins his true love and obtains a permanent higher status.</p>
                        <br/>
                        <li>Examples: <i>Aladdin, Jane Eyre, A Little Princess, Great Expectations, David Copperfield, The Prince and the Pauper, Brewster’s Millions. The Jerk.</i>Cinderella</li>
                    </ul>
                <li><h3>The Quest</h3></li>
                    <p>The protagonist and companions set out to acquire an important object or to get to a location. They face temptations and other obstacles along the way.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>Story begins in a “City of Destruction” where life is intolerable or oppressive.</p>
                        <li><h4>Driver</h4></li>
                            <p>The Call: The hero gets a vision or supernatural call that says the key to making things better is to go get something from far away.</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>The Journey: Hero travels to the goal, having adventures along the way, gaining helpers and encountering monsters, temptations, dangers, and the ghosts of failed questers.</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Arrival and frustration: within sight of his goal, the hero finds another terrible set of obstacles to overcome.</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>Final ordeals: hero faces a final set of tests and his toughest fight yet.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>The Goal: Hero survives and gets the treasure, Princess, kingdom etc. forevermore.</p>
                        <br/>
                        <li>Examples: <i>The Iliad, The Pilgrim’s Progress, The Lord Of The Rings, King Solomon’s Mines, Six of Crows, Watership Down, Lightning Thief, Raiders of the Lost Ark, Monty Python and the Holy Grail.</i></li>
                    </ul>
                <li><h3>Voyage and Return</h3></li>
                    <p>The protagonist goes to a strange land and, after overcoming the threats it poses or learning important lessons unique to that location, they return with experience.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>We see a hero who is bored, curious, reckless, or otherwise open to a new experience.</p>
                        <li><h4>Driver</h4></li>
                            <p>Hero is suddenly transported to a strange new world.</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>Hero explores the new world, finding it puzzling but fascinating.</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Gradually, the world becomes alarming, frustrating, oppressive, or difficult.</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>A serious threat to the hero’s survival arises.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>Thrilling escape and return.</p>
                        <br/>
                        <li>Examples: <i>Ramayana, Odyssey, Alice’s Adventures in Wonderland, Goldilocks and the Three Bears, Orpheus, The Time Machine, Peter Rabbit, The Hobbit,  Brideshead Revisited, The Rime of the Ancient Mariner, Gone with the Wind, The Third Man, The Lion King, Back to the Future, The Midnight Gospel.</i></li>
                    </ul>
                <li><h3>Comedy</h3></li>
                    <ul>
                        <li><p>Different than the others, as Booker does not assign to it the common act and driver structure.</p></li>
                        <li><p>Light and humorous character with a happy or cheerful ending; a dramatic work in which the central motif is the triumph over adverse circumstance, resulting in a successful or happy conclusion. Booker stresses that comedy is more than humor. It refers to a pattern where the conflict becomes more and more confusing, but is at last made plain in a single clarifying event. The majority of romance films fall into this category.</p></li>
                        <li><p>The story takes place in a community where the relationships between people (and by implication true love and understanding) are under the shadow of confusion, uncertainty, and frustration. Sometimes this is caused by an oppressive or self-centered person, sometimes by the hero acting in such a way, or sometimes through no one’s fault.</p></li>
                        <li><p>The confusion worsens until it reaches a crisis.</p></li>
                        <li><p>The truth comes out, perceptions are changed, and the relationships are healed in love and understanding (and typically marriage for the hero).</p></li>
                        <li><p><b>Variation 1: Failure</b></p></li>
                            <ul>
                            <li><p>What Booker calls the “dark” version of this story is when the hero fails to win in the end, usually because he sought wealth and status for selfish reasons. Dramatica (and most other theorists) would call this a tragedy.</p></li>
                            </ul>
                        <li><p><b>Variation 2: Hollow Victory</b></p></li>
                            <ul>
                            <li><p>Booker’s second variation are stories where the hero “may actually achieve [his] goals, but only in a way which is hollow and brings frustration, because he again has sought them only in an outward and egocentric fashion.” Another way to describe this would be a comi-tragic ending or personal failure. In Dramatica terms, it’s an outcome of success, but a judgment of failure since the hero fails to satisfactorily resolve his inner conflict.</p></li>
                            </ul>
                        <br/>
                        <li>Examples: <i> Midsummer Night’s Dream, Much Ado About Nothing, Twelfth Night, Bridget Jones’s Diary, Music and Lyrics, Sliding Doors, Four Weddings and a Funeral, The Big Lebowski.</i>A</li>  
                    </ul>
                <li><h3>Tragedy</h3></li>
                    <p>The protagonist is a hero with a major character flaw or great mistake which is ultimately their undoing. Their unfortunate end evokes pity at their folly and the fall of a fundamentally good character.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>We start with a hero is unfulfilled and wants more.</p>
                        <li><h4>Driver</h4></li>
                            <p>Tempatation: the hero finds focus in some object of desire or course of action, (usually something forbidden).</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>Hero commits to his goal and things go amazingly well for him.</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Gradually, things start to go wrong and the hero may resort to desperate and unwise actions that cannot be undone.</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>Hero loses control of the situation. Forces of opposition close in one him.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>Hero is destroyed in some way.</p>
                        <br/>
                        <li>Examples: <i>Anna Karenina, Bonnie and Clyde, Carmen, Citizen Kane, John Dillinger, Jules et Jim, Julius Caesar, Macbeth, Madame Bovary, Oedipus Rex, The Picture of Dorian Gray, Romeo and Juliet.</i></li>
                    </ul>
                <li><h3>Rebirth</h3></li>
                    <p>An event forces the main character to change their ways and often become a better individual.</p>
                    <ul>
                        <li><h4>Act 1 (Anticipation)</h4></li>
                            <p>Hero falls under the shadow of the dark power.</p>
                        <li><h4>Act 2 (Dream)</h4></li>
                            <p>Things seem to go well at first, and the threat seems to recede.</p>
                        <li><h4>Act 3 (Frustration)</h4></li>
                            <p>Threat returns in full force, imprisoning the hero in a state of living death.</p>
                        <li><h4>Act 4 (Nightmare)</h4></li>
                            <p>The Dark power seems to completely triumph.</p>
                        <li><h4>Final Driver (Resolution)</h4></li>
                            <p>Someone miraculously rescues the hero.</p>
                        <br/>
                        <li>Examples: <i>Pride and Prejudice, The Frog Prince, Beauty and the Beast, The Snow Queen, A Christmas Carol, The Secret Garden, Peer Gynt, Groundhog Day.</i></li>
                    </ul>
            </ol>
            <a target='_blank' rel="noreferrer" href='https://www.wob.com/en-gb/books/christopher-booker/seven-basic-plots/9780826480378?cq_src=google_ads&cq_cmp=18075688485&cq_con=&cq_med=pla&cq_plac=&cq_net=x&gad_source=1&gclid=Cj0KCQiA6vaqBhCbARIsACF9M6m0KGPZk04tQDjnp4nHnQZBOGM4UZTJUF8XjnQCf3_8JcvOsDYYKp4aAgF_EALw_wcB#GOR001829242'>
                <img src='/images/booker.jpeg' alt='The seven basic plots by Christopher Booker'/></a>
        </div>
    )
}