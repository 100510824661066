import React from "react";
import { useSelector } from "react-redux";
import { selectPlotPrompt,selectElements, displayPlot } from "./plotPromptSlice";
import { useDispatch } from "react-redux";

export const Selector =()=> {

    const plotPrompt = useSelector(selectPlotPrompt);
    const dispatch = useDispatch();
    
    const handleChange = (item) => {
        dispatch(selectElements(item));
        if(plotPrompt.prompt!== ''){
            dispatch(displayPlot());
        }
    }

        let genre = plotPrompt.gen;
        let plot = plotPrompt.plo;
        let personality = plotPrompt.pers;
        let character = plotPrompt.char;
        let arc = plotPrompt.ar;
        let theme = plotPrompt.th;
        let hook = plotPrompt.ho;
        let ending = plotPrompt.end;
        let litDevice = plotPrompt.ld;
        let narStyle = plotPrompt.ns;
        let genre2 = plotPrompt.gen2;
        let length = plotPrompt.len;


        return(
            <div>
                <fieldset className="selector">
                    <legend>Choose your Story Elements</legend>
                    <div className='check'>
                        <div>
                            <input type="checkbox" id="genre" name="genre" 
                                value="genre" checked={genre} onChange={()=> handleChange('genre')} />
                            <label htmlFor="genre">Genre</label>
                        </div>
                        <div>
                            <input type="checkbox" id="plot" name="plot" 
                                value="plot" checked={plot} onChange={()=> handleChange('plot')}/>
                            <label htmlFor="plot">Plot</label>
                        </div>
                        <div>
                            <input type="checkbox" id="personality" name="personality" 
                                value="personality" checked={personality} onChange={()=> handleChange('personality')}/>
                            <label htmlFor="personality">Personality</label>
                        </div>
                        <div>
                            <input type="checkbox" id="character" name="character" 
                                value="character" checked={character} onChange={()=> handleChange('character')}/>
                            <label htmlFor="character">Character</label>
                        </div>
                        <div>
                            <input type="checkbox" id="arc" name="arc" 
                                value="arc" checked={arc} onChange={()=> handleChange('arc')}/>
                            <label htmlFor="arc">Arc</label>
                        </div>
                        <div>
                            <input type="checkbox" id="theme" name="theme" 
                                value="theme" checked={theme} onChange={()=> handleChange('theme')}/>
                            <label htmlFor="arc">Theme</label>
                        </div>
                    </div>
                    <div className='check'>
                        <div>
                            <input type="checkbox" id="genre2" name="genre2" 
                                value="genre2" checked={genre2} onChange={()=> handleChange('genre2')}/>
                            <label htmlFor="genre2">Genre 2</label>
                        </div>
                        <div>
                            <input type="checkbox" id="hook" name="hook" 
                                value="hook" checked={hook} onChange={()=> handleChange('hook')}/>
                            <label htmlFor="hook">Hook</label>
                        </div>
                        <div>
                            <input type="checkbox" id="ending" name="ending" 
                                value="ending" checked={ending} onChange={()=> handleChange('ending')}/>
                            <label htmlFor="ending">Ending</label>
                        </div>
                        <div>
                            <input type="checkbox" id="litDevice" name="litDevice" 
                                value="litDevices" checked={litDevice} onChange={()=> handleChange('litDevice')}/>
                            <label htmlFor="litDevice">Literary Device</label>
                        </div>
                        <div>
                            <input type="checkbox" id="narStyle" name="narStyle" 
                                value="narStyle" checked={narStyle} onChange={()=> handleChange('narStyle')}/>
                            <label htmlFor="narStyle">Narrative Style</label>
                        </div>
                        <div>
                            <input type="checkbox" id="length" name="length" 
                                value="length" checked={length} onChange={()=> handleChange('length')}/>
                            <label htmlFor="length">Length</label>
                        </div>
                    </div>
                    
                </fieldset>
            </div>
        )
}