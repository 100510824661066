import React from "react";


export const PositiveArc=()=>{
    return(
        <div>
            <h3>Character believes Lie, overcomes Lie, new Truth is liberating.</h3>
            <br/>
            <ul className='arcList'>

                <li><b>Hook</b>
                    <ul>
                        <li>Grab your readers attention, introduce your protagonist, and possibly the antagonist.</li>
                        <li><i>Protagonist believes Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Inciting Incident</b>
                    <ul>
                        <li>The call to adventure, the moment the normal world is rocked by conflict.</li>
                        <li><i>First hint Lie will no longer work.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Plot point 1</b>
                    <ul>
                        <li>First disaster. The point of no return, establishes the main story question.</li>
                        <li><i>Lie no longer effective.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Pinch point 1</b>
                    <ul>
                        <li>First battle. The antagonist shows power.</li>
                        <li><i>Protagonist punished for believing Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Midpoint</b>
                    <ul>
                        <li>Moment of truth, possibly forced by the second disaster. The protagonist shifts from reactive to proactive.</li>
                        <li><i>Protagonist sees truth but doesn’t yet reject Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Pinch point 2</b>
                    <ul>
                        <li>Second battle. The antagonist strikes back.</li>
                        <li><i>Protagonist is rewarded for using Truth.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Plot point 3</b>
                    <ul>
                        <li>Third disaster. The darkest moment for the protagonist. All is seemingly lost.</li>
                        <li><i>Protagonist rejects Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Climax</b>
                    <ul>
                        <li>The final battle begins.</li>
                        <li><i>Protagonist embraces Truth.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Climatic Moment</b>
                    <ul>
                        <li>Protagonist wins (or loses).</li>
                        <li><i>They use the Truth to get Need.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Denouement</b>
                    <ul>
                        <li>The aftermath.</li>
                        <li><i>Protagonist enters new Truth empowered world.</i></li>
                    </ul>
                </li>

            </ul>
        </div>
    )
}