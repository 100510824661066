import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePrompt, selectSavedPrompts,removePrompt, selectUspPrompt } from "./uspPromptSlice";

export const UspSave = ({style}) => {

    const savedPrompts = useSelector(selectSavedPrompts);
    const dispatch = useDispatch();
    const uspPrompt = useSelector(selectUspPrompt);

    const handleClick = () => {
        dispatch(savePrompt(uspPrompt));

    }

    return (
        <div className = 'usp save'>
            <button id='button2' onClick={()=>handleClick()}>Save</button>
            <div className = 'savedPrompt'>
                    {savedPrompts.map((prompt) => (
                    <div>
                        <p style={style}>{prompt}</p>
                        <p id='button2'onClick={()=> dispatch(removePrompt(prompt))}>remove</p>
                    </div>
                    ))}
            </div>
        
        </div>
    )
}


           