import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write3 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 3: Choosing a Character Arc</h3>
            <p className='step-p'>A character's arc is the trajectory of their change from the beginning of the story until the end.</p>
            <div className="arcs1">
                <div className="arc1">
                    <h3>Positive Arc</h3>
                    <h5>Character believes lie, overcomes lie, new truth is liberating.</h5>
                </div>
                <div className="arc1">
                    <h3>Flat Arc</h3>
                    <h5>Character believes Truth, maintains Truth, uses Truth to overcome world's Lie.</h5>
                </div>
                <div className="arc1">
                    <h3>Corruption Arc</h3>
                    <h5>Character sees Truth, rejects Truth, embraces Lie.</h5>
                </div>
                <div className="arc1">
                    <h3>Disillusionment Arc</h3>
                    <h5>Character believes Lie, overcomes Lie, new Truth is tragic.</h5>
                </div>
                <div className="arc1">
                    <h3>The Fall</h3>
                    <h5>Character believes Lie, clings to Lie, rejects new Truth, believes worse Lie.</h5>
                </div>
            </div>
            <p className='step-p'>The more complex stories have arcs for multiple characters. Series can have multiple arcs for one character. Maybe you want to have a positive arc for your protagonist and a negative arc for your antagonist? Or maybe vice versa? It’s up to you. The most common arc, however, is the positive change. This sees your protagonist addresses their flaw, changing for the better, and saving the day.</p>
            <p className='step-p'>You will also need to do a lot of character building to bring that character to life. But assuming you already have a well fleshed out protagonist, once you’ve chosen their arc, you can move on to the next step.</p>
           
            <div className='stepArrows'>
                        <Link to='../write-idea-step-2'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../write-idea-step-4/positive-arc'><button id='button2'><span>Next</span></button></Link>
                    </div>
        </div>
    )}
