import React from "react";
import { LeftSide } from "./leftSide/leftSide";
import { RightSide } from "./rightSide/rightSide";


export const Login = () => {
    return(
        <div className='page'>
            <LeftSide />
            <div className = 'content'>
                <header>
                    <h1 id='blogHeader'>Login</h1>
                </header>
                <p>Login feature (hopefully) coming soon!</p>
            </div>
            <RightSide />
        </div>
        
    )
}