import React,{useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUspPrompt, uspGen } from "./uspPromptSlice";
import { UspSave } from "./uspSave";


export const UspPrompt = () => {

    const uspPrompt = useSelector(selectUspPrompt);
    const dispatch = useDispatch();
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(()=>{
        if(uspPrompt !== ''){
            setSave(true);
            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[uspPrompt])



    const handleClick = () => {
        dispatch(uspGen());
        save? setSave(false): setSave(true)

    }

    return(
        <div className = 'prompt' id='uspPrompt'>
            <p style={style}>{uspPrompt}</p>
            <button onClick={()=> handleClick()}>Generate New USP</button>
            {save && <UspSave style={style}/>}
        </div>
    )
}