import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write1 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 1. Writing a Story Premise</h3>
            <p className='step-p'>Write one sentence that summarizes your story. The shorter the better. This is because stories can really only be about one thing. Above all else, what does it all boil down to?</p>
            <p className='step-p'>Try to limit yourself to 15 words. </p>
            <br/>
            <p>Example:</p>
            <p className='step-p'><i>A computer hacker fights back against the robots who have enslaved humanity.</i> -The Matrix.</p>
            <div className='stepArrows'>
                        <Link to='..'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../write-idea-step-2'><button id='button2'><span>Next</span></button></Link>
                    </div>
        </div>
    )
}