import React from "react"

export const PromptHome =()=> {
    window.scrollTo(0,0)

    return (
        <div id='promptHome'>
            
            <h3>Welcome to <i>Writician</i>'s world renowned creative writing prompt generators!</h3>
            <p>
                Get awesome ideas, <br/>
                challenge yourself to write stories outside of your comfort zone, <br/>
                and let the randomness fuel your creativity!
            </p>

        </div>
    )
}
