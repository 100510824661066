import React, { useState, useEffect } from "react";
import { enter, selectGetIdea } from "../../pages/get-idea/getIdeaSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { characters } from "../prompts/plotPrompt/descriptions";
import { personalities } from "../prompts/plotPrompt/descriptions";

export const Get3 = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch();
    const getIdea = useSelector(selectGetIdea);
    const step1 = getIdea.step1
    const step2 = getIdea.step2
    const step3 = getIdea.step3;

    let [input3, setInput3] =useState('')

    const handleChange =({target})=> {
            setInput3(target.value)
        }

        const handleClick = (step, input)=> {
            dispatch(enter({step,input}));
            setInput3('')
        }

        function showChar(character){
            let description;
            if(character==='lover'){
                description=characters.lover
            }
            if(character==='jester'){
                description=characters.jester
            }
            if(character==='hero'){
                description=characters.hero
            }
            if(character==='sage'){
                description=characters.sage
            }
            if(character==='ruler'){
                description=characters.ruler
            }
            if(character==='magician'){
                description=characters.magician
            }
            if(character==='everyman'){
                description=characters.everyman
            }
            if(character==='caregiver'){
                description=characters.caregiver
            }
            if(character==='rebel'){
                description=characters.rebel
            }
            if(character==='innocent'){
                description=characters.innocent
            }
            if(character==='explorer'){
                description=characters.explorer
            }
            if(character==='creator'){
                description=characters.creator
            }
            return description;        
        }

        function showPers(personality){
            let description;
            if(personality==='architect'){
                description=personalities.architect
            }
            if(personality==='logician'){
                description=personalities.logician
            }
            if(personality==='commander'){
                description=personalities.commander
            }
            if(personality==='debater'){
                description=personalities.debater
            }
            if(personality==='advocate'){
                description=personalities.advocate
            }
            if(personality==='mediator'){
                description=personalities.mediator
            }
            if(personality==='protagonist'){
                description=personalities.protagonist
            }
            if(personality==='campaigner'){
                description=personalities.campaigner
            }
            if(personality==='logistician'){
                description=personalities.logistician
            }
            if(personality==='defender'){
                description=personalities.defender
            }
            if(personality==='executive'){
                description=personalities.executive
            }
            if(personality==='consul'){
                description=personalities.consul
            }
            if(personality==='virtuoso'){
                description=personalities.virtuoso
            }
            if(personality==='adventurer'){
                description=personalities.adventurer
            }
            if(personality==='entrepreneur'){
                description=personalities.entrepreneur
            }
            if(personality==='entertainer'){
                description=personalities.entertainer
            }
            return description;        
        }

    return(
        <div id='step-three'className='step'>
            <h3>Step 3: Start With Character</h3>
            <p className='step-p'>Why? Because all stories are essentially character driven. If your stories are lacking something, it’s probably because your stories don’t have characters with depth. This was the hardest lesson I had to learn. I wanted to go straight into the cool world building stuff right away. My ideas always come first as settings, as events, as crazy circumstances, magical abilities, wars between nations, worlds, demons and demigods. But these only mean anything through the eyes of a character. So, let’s start there.</p>
            <p className='step-p'>Now that you’ve got your genre and archetype, (or maybe you don’t, that’s ok, picking a lead character may help you find the kind of story you want to tell,) look through the lists of archetypes and personalities. Which one do you resonate with the most? The least? How could combining any of the archetypes with personalities create something new? How about a character who is a walking contradiction? Someone unique and interesting. Someone you’d like to write a story about.</p>
            <h4>Character Archetypes</h4>
            <div className = 'stepList' id='threeList'>
                <ul>
                    <li>The Innocent<span>{showChar('innocent')}</span></li>
                    <li>The Everyman<span>{showChar('everyman')}</span></li>
                    <li>The Hero<span>{showChar('hero')}</span></li>
                    <li>The Caregiver<span>{showChar('caregiver')}</span></li>
                </ul>
                <ul>
                    <li>The Explorer<span>{showChar('explorer')}</span></li>
                    <li>The Rebel<span>{showChar('rebel')}</span></li>
                    <li>The Lover<span>{showChar('lover')}</span></li>
                    <li>The Creator<span>{showChar('creator')}</span></li>
                </ul>
                <ul>
                    <li>The Jester<span>{showChar('jester')}</span></li>
                    <li>The Sage<span>{showChar('sage')}</span></li>
                    <li>The Magician<span>{showChar('magician')}</span></li>
                    <li>The Ruler<span>{showChar('ruler')}</span></li>
                </ul>
            </div>
            <h4>Personality Types</h4>
            <div className = 'stepList' id='threeList'>
                <div>
                    <h5>Analysts</h5>
                    <ul>
                        <li>Architect<span>{showPers('architect')}</span></li>
                        <li>Logician<span>{showPers('logician')}</span></li>
                        <li>Commander<span>{showPers('commander')}</span></li>
                        <li>Debater<span>{showPers('debater')}</span></li>
                    </ul>
                </div>
                <div>
                <h5>Diplomats</h5>
                    <ul>
                        <li>Advocate<span>{showPers('advocate')}</span></li>
                        <li>Mediator<span>{showPers('mediator')}</span></li>
                        <li>Protagonist<span>{showPers('protagonist')}</span></li>
                        <li>Campaigner<span>{showPers('campaigner')}</span></li>
                    </ul>
                </div>
                <div>
                <h5>Sentinels</h5>
                    <ul>
                        <li>Logistician<span>{showPers('logistician')}</span></li>
                        <li>Defender<span>{showPers('defender')}</span></li>
                        <li>Executive<span>{showPers('executive')}</span></li>
                        <li>Consul<span>{showPers('consul')}</span></li>
                    </ul>
                </div>
                <div>
                    <h5>Explorers</h5>
                    <ul>
                        <li>Virtuoso<span>{showPers('virtuoso')}</span></li>
                        <li>Adventurer<span>{showPers('adventurer')}</span></li>
                        <li>Entrepreneur<span>{showPers('entrepreneur')}</span></li>
                        <li>Entertainer<span>{showPers('entertainer')}</span></li>
                    </ul>
                </div>
            </div>
            <p id='example'>Examples<span>Example 1: Wild West Space Opera Mystery with a Defender Lover.<br/> Example 2: Historical Thriller Quest with a Virtuoso Rebel. </span></p>

                    <div className='input'>
                        <label>Write your character personality type and archetype here:  </label>
                        <textarea id='step1' className='stepInput' type='text' value={input3} onChange={handleChange} />
                        {input3!=="" && <button onClick={()=>handleClick('step3', input3)}id='button2'>Enter</button>}
                    </div>
                    <div className='savedInput'>
                    <p>Genre(s): <b>{step1}</b></p>
                    <p>Plot Archetype: <b>{step2}</b></p>
                    <p>Character: <b>{step3}</b></p>
                    </div>
                    <div className='stepArrows'>
                        <Link to='../get-idea-step-2'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../get-idea-step-4'><button id='button2'><span>Next</span></button></Link>
                    </div>
                        
        </div>
             
       
    )
}