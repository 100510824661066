import React from "react";


export const FlatArc=()=>{
    return(
        <div>
            <h3>Character believes Truth, maintains Truth, uses Truth to overcome world’s Lie.</h3>
            <br/>
            <ul className='arcList'>
                <li><b>Hook</b>
                    <ul>
                        <li>Grab your readers attention, introduce your protagonist, and possibly the antagonist.</li>
                        <li><i>Protagonist believes the Truth in a Lie-ridden world.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Inciting Incident</b>
                    <ul>
                        <li>The call to adventure, the moment the normal world is rocked by conflict.</li>
                        <li><i>Protagonist is challenged to use the Truth to oppose Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Plot point 1</b>
                    <ul>
                        <li>First disaster. The point of no return, establishes the main story question.</li>
                        <li><i>World tries to forcibly impose Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Pinch point 1</b>
                    <ul>
                        <li>First battle. The antagonist shows power.</li>
                        <li><i>Protagonist becomes uncertain if Truth is capable of defeating Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Midpoint</b>
                    <ul>
                        <li>Moment of truth, possibly forced by the second disaster. The protagonist shifts from reactive to proactive.</li>
                        <li><i>Protagonist proves power of Truth to world.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Pinch point 2</b>
                    <ul>
                        <li>Second battle. The antagonist strikes back.</li>
                        <li><i>Lie driven characters fight back.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Plot point 3</b>
                    <ul>
                        <li>Third disaster. The darkest moment for the protagonist. All is seemingly lost.</li>
                        <li><i>Lie seems to triumph externally.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Climax</b>
                    <ul>
                        <li>The final battle begins.</li>
                        <li><i>Final confrontation between Truth and Lie.</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Climatic Moment</b>
                    <ul>
                        <li>Protagonist wins (or loses).</li>
                        <li><i>Truth defeats Lie</i></li>
                    </ul>
                </li>
                <br/>

                <li><b>Denouement</b>
                    <ul>
                        <li>The aftermath.</li>
                        <li><i>New Truth-empowered normal world.</i></li>
                    </ul>
                </li>

            </ul>
        </div>
    )
}