import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePrompt, selectSavedPrompts,removePrompt, selectRedditPrompt } from "./redditPromptSlice";

export const RedditSave = ({style}) => {

    const redditPrompt = useSelector(selectRedditPrompt);
    const dispatch = useDispatch();
    const savedPrompts = useSelector(selectSavedPrompts);
 

    const handleClick = () => {
        dispatch(savePrompt(redditPrompt));
    }

    return (
        <div className='reddit save'>
            <button id='button2'onClick={()=>handleClick()}>Save</button>
            <div className = 'savedPrompt'>
                {savedPrompts.map((prompt) => (
                <div>
                    <p style={style}>{prompt}</p>
                    <p id='button2' onClick={()=> dispatch(removePrompt(prompt))}>remove</p>                    
                </div>
                ))}
            </div>
           
        
        </div>
    )
}


           