import React from "react";
import { Outlet } from "react-router-dom";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './storyElements.css'

export const StoryElements = () => {

    return(
        <div className ='page'>
            
            <LeftSide />
            <div className='content'>
                <Outlet/>
            </div>
            <RightSide />
        </div>

    )
}