import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const Endings = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div>
            <header><h1>Endings</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className='genres'>
            <div className="genre">
                    <h3>Circular</h3>
                    <ul>
                        <li>The story circles back to the beginning</li>
                        <li>Sometimes ending on the same idea or theme, even the exact same or similar words as the beginning of the story.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Twist</h3>
                    <ul>
                        <li>The story takes you where you didn't expect it to go.</li>
                        <li>An unforeseen ending, but in hindsight it makes sense if properly foreshadowed.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Moral</h3>
                    <ul>
                        <li>The main character grows, changes, or learns something positive at the end.</li>
                       
                    </ul>

                </div>
                <div className="genre">
                    <h3>Warm & Fuzzy</h3>
                    <ul>
                        <li>The story ends leaving the reader feeling emotional or good inside.</li>
                        <li>Tugs at the heart strings</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Reflection</h3>
                    <ul>
                        <li>The narrator steps back and reflects on what just happened.</li>
                        <li>They often look back on the experience and determine the importance of it and what was learned.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Cliffhanger</h3>
                    <ul>
                        <li>The story ends leaving the reader hanging or wanting more.</li>
                        <li>Unresolved ending, good for the end of chapters or book series.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Question</h3>
                    <ul>
                        <li>Ends with a question to keep the reader thinking.</li>
                        <li>The question usually involves the reader and is used to make the writing memorable.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Humorous</h3>
                    <ul>
                        <li>Ends with a funny thought or statement, bringing levity to the story or situation, and making it more memorable.</li>
                        
                    </ul>

                </div>
                <div className="genre">
                    <h3>Image</h3>
                    <ul>
                        <li>Ends with an important scene that the writer shows the reader through vivid details. </li>
                        <li>By showing and not telling, the writer touches the reader's emotions and conveys a mood.</li>
                    </ul>

                </div>
                <div className="genre">
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Ends with an important conversation or quote.</li>
                        <li>Reveals character's personality</li>
                    </ul>

                </div>
            </div>
        </div>
    )
}