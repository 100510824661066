import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

    export const getPrompt = createAsyncThunk(
      'prompt/getPrompt', async() => {
          const jsonURL = 'https://www.reddit.com/r/WritingPrompts/.json'
          const random = Math.floor(Math.random()*27)
          const response = await fetch(jsonURL)
          const json = await response.json();
          const path = json.data.children[random].data.title
          const link = `https://www.reddit.com${json.data.children[random].data.permalink}`
          const user = json.data.children[random].data.author
          return {path, link, user}
      })
  
  

export const redditPromptSlice = createSlice({
    name: 'redditPrompt',
    initialState: {
        prompt: [],
        isLoading: false,
        failedToLoad: false,
        savedPrompts: [],

    },
    reducers: {
      savePrompt: (state, action) => {
        for(let i=0 ; i<state.savedPrompts.length+1 ; i++){
            if(state.savedPrompts[i]===action.payload){
                return
            }
        }
        state.savedPrompts.push(state.prompt)
    },
    removePrompt: (state, action) => {
        state.savedPrompts = state.savedPrompts.filter((prompt) => prompt !== action.payload)

    }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getPrompt.pending, (state) => {
            state.isLoading = true;
            state.failedToLoad = false;
          })
          .addCase(getPrompt.fulfilled, (state, action) => {
            state.isLoading = false;
            state.failedToLoad = false;
            state.prompt[0] = (action.payload.path);
            state.prompt[1] = (<a href={action.payload.link} target='_blank' rel="noreferrer" id = 'redditLink'>u/{action.payload.user}</a>);
            document.getElementById('redditButton').innerHTML = 'Fetch New Prompt';

          })
          .addCase(getPrompt.rejected, (state) => {
            state.isLoading = false;
            state.failedToLoad = true;
          })
          
    }
})

export const selectRedditPrompt = (state) => state.redditPrompt.prompt;
export const selectRedditLink = (state) => state.redditPrompt.promptURL;
export const selectRedditUser = (state) => state.redditPrompt.user;
export const selectSavedPrompts = (state) => state.redditPrompt.savedPrompts;
export const selectLoading = (state) => state.redditPrompt.isLoading;
export const isLoading = (state) => state.redditPrompt.isLoading;
export default redditPromptSlice.reducer;
export const { savePrompt,removePrompt } = redditPromptSlice.actions;

