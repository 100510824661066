import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Write2 =() => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div className ='step'>
            <h3>Step 2: Three Disasters and a Resolution</h3>
            <p className='step-p'>Now, expand your sentence into a paragraph. Think of your character's goal and three things that can go wrong. There should be some escalation of intensity and danger, physical or otherwise. I’ve also seen it suggested that the first disaster can be an external circumstance, something beyond the protagonist’s control, but the next two should be the results of their trying to fix things. Things just keep getting worse and worse.</p>
            <p>Example:</p>
            <p className='step-p'>
                <p>#1- Neo is hunted by Agents at his office, captured, and bugged.</p>
                <p>#2 – Morpheus is captured.</p>
                <p>#3 – Neo is stuck in the Matrix and must fight Agent Smith.</p>
                <p>Resolution – He defeats the Agents and realizes his destiny as the One.</p>
            </p>
           
            <div className='stepArrows'>
                        <Link to='../write-idea-step-1'><button id='button2'><span>Back</span></button></Link>
                        <Link to='../write-idea-step-3'><button id='button2'><span>Next</span></button></Link>
                    </div>
        </div>
    )
}