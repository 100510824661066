import React from "react";
import { Link } from "react-router-dom";



export const BlogHome = () => {
    return(

        <div id='blogHome'>

                <Link to='welcome-to-writician' className='blogLink'>
                    <div className='blogTop' id='blogTop1'>
                        <img className='blogImage' src='/images/assortedpics/amandine-bataille-5W78I6Jvbjw-unsplash.jpg' alt='typewriter'/>
                        <div className = 'blogHeader'>
                            <div className='blogTitle'>Welcome to Writician!</div>
                            <div className='blogHook'><p>
                            If you're reading this then you're one of the first lucky ones who've discovered our site; 
                            a new space for creative writers to learn, share, and grow together. 
                            We've got a lot of plans for the site, and we're hoping we can make this journey together with all of you...
                        </p></div>
                            <div className='blogDate'>March 1, 2024</div>

                        </div>
                    </div>
                </Link>

                
                
            

        </div>
    )
}


/*
 <Link to='creative-writing-exercise-1' className='blogLink'>
                        <div className='blogTop' id='blogTop3'>
                            <img className='blogImage' src='/images/magic.jpg' alt='book with magic coming to life'/>
                            <div className = 'blogHeader'>
                                <div className='blogTitle'>Creative Writing Exercise #1</div>
                                <div className='blogHook'>Magical abilities in fantasy based on world-building.</div>
                                <div className='blogDate'>Dec 1,2023</div>

                            </div>
                        </div>
                    </Link>


                <Link to='are-you-a-gardner-or-an-architect' className='blogLink'>
                    <div className='blogTop' id='blogTop2'>
                        <img className='blogImage' src='../images/blog2.png' alt='building next to garden'/>
                        <div className = 'blogHeader'>
                            <div className='blogTitle'>Are you a Gardner or an Architect?</div>
                            <div className='blogHook'>Find out your plotting style and what it means for your next writing project.</div>
                            <div className='blogDate'>Nov 30,2023</div>

                        </div>
                    </div>
                </Link> */