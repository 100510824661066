import React from "react";
import { NavLink} from "react-router-dom";

export const CraftHome = () => {

    window.scrollTo(0,0);

    return(
        <div>
            <header><h1>Craft Elements</h1></header>
            <NavLink to='/story-elements'><button id='button3'>Back</button></NavLink>
            <div className="navBoxes2">
                        <NavLink to='lit-devices' className='boxLink'>Literary Devices</NavLink>
                    
                        <NavLink to='themes'className='boxLink'>Themes</NavLink>
                    
                        <NavLink to='narrative-styles'className='boxLink'>Narrative Styles</NavLink>
            </div>
            
            
        </div>
    )
}



/*                     <div className='navBox2'>
                        <NavLink to='get-published'className='boxLink'><h1>Getting Published</h1></NavLink>
                    </div>
                    */