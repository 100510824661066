import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useEffect } from "react";



export const CharacterArcs = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div>
            <header><h1>Character Arcs</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <nav className='arcsNav'>
                <ul>
                    <NavLink to='positive-arc' className='link'>Positive</NavLink>
                    <NavLink to='flat-arc' className='link'>Flat</NavLink>
                    <NavLink to='corruption-arc' className='link'>Corruption</NavLink>
                    <NavLink to='disillusionment-arc' className='link'>Disillusionment</NavLink>
                    <NavLink to='thefall-arc' className='link'>The Fall</NavLink>
                </ul>
            </nav>
            <br/>
                <Outlet/>
            <br/>
            <a target='_blank'rel="noreferrer" href="https://www.amazon.com/Creating-Character-Arcs-Masterful-Development-ebook/dp/B01M6VC68U/ref=as_li_ss_tl?ie=UTF8&linkCode=sl1&tag=httpwwwkmweil-20&linkId=8937de72caa49d1a4d1fd1bd850f43c5">
                <img alt="Creating Character Arcs by K.M. Weiland"src='/images/weiland.jpg'/></a>
        </div>
    )
}