import React from 'react';
import { Nav } from '../app/nav/nav';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer/footer';

function App() {

  window.onload = function() {
    // similar behavior as clicking on a link
  //window.location.href = "http://localhost:3000/home";
}


  return (
    <body className="App">
      <Nav />
      <div className='mainOutlet'><Outlet/></div>
      <Footer/>
    </body>
  );
}

export default App;
