import React from "react";


export const Motto =()=>{
    const genMotto = () => {
        let rando = Math.floor(Math.random()*4)
        let mottos = [`Banish Writer's Block Forever!`, 'Behold, the Magic of Story!','Get the Ideas Flowing.','Write. Cool. Stories.'
    ]
    return mottos[rando]
    }
    return(
        <div id='motto'>
            {genMotto()}
        </div>
    )
}