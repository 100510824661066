import React from "react";

export const Polti = () => {
    return(
        <div className='plotArchs-page'>
            <h1>Georges Polti’s 36 Dramatic Situations </h1>
            <h4><i>The Thirty-Six Dramatic Situations</i>, initially introduced by Georges Polti in 1895, is a descriptive list aimed at categorizing every possible dramatic situation that may unfold in a story or performance. Polti's analysis drew from classical Greek texts, as well as classical and contemporary French works.</h4>
            <ol id='plotList'>
                <li><h3>Supplication</h3></li>
                    <ul>
                        <li>a persecutor; a suppliant; a power in authority, whose decision is doubtful.</li>
                        <li>The suppliant appeals to the power in authority for deliverance from the persecutor. The power in authority may be a distinct person or be merely an attribute of the persecutor, e.g. a weapon suspended in their hand. The suppliant may also be two persons, the Persecuted and the Intercessor, an example of which is Esther interceding to the king on behalf of the Jews for deliverance from the king's chief advisor.</li>
                    </ul>
                <li><h3>Deliverance</h3></li>
                    <ul>
                        <li>an unfortunate; a threatener; a rescuer</li>
                        <li>The unfortunate has caused a conflict, and the threatener is to carry out justice, but the rescuer saves the unfortunate.</li>
                        <li>Examples: Ifigenia in Tauride, Deliverance; Superman (1941 film)</li>
                    </ul>
                <li><h3>Crime pursued by vengeance</h3></li>
                    <ul>
                        <li>a criminal; an avenger</li>
                        <li>The criminal commits a crime that will not see justice, so the avenger seeks justice by punishing the criminal.</li>
                        <li>Example: The Count of Monte Cristo</li>
                    </ul>
                <li><h3>Vengeance taken for kin upon kin</h3></li>
                    <ul>
                        <li>Guilty Kinsman; an Avenging Kinsman; remembrance of the Victim, a relative of both.</li>
                        <li>Two entities, the Guilty and the Avenging Kinsmen, are put into conflict over wrongdoing to the Victim, who is allied to both.</li>
                        <li>Example: Hamlet</li>
                    </ul>
                <li><h3>Pursuit</h3></li>
                    <ul>
                        <li>punishment; a fugitive</li>
                        <li>The fugitive flees punishment for a misunderstood conflict.</li>
                        <li>Examples: Les Misérables, The Fugitive</li>
                    </ul>
                <li><h3>Disaster</h3></li>
                    <ul>
                        <li>a vanquished power; a victorious enemy or a messenger</li>
                        <li>The vanquished power falls from their place after being defeated by the victorious enemy or being informed of such a defeat by the messenger.</li>
                        <li>Example: Agamemnon (play)</li>
                    </ul>
                <li><h3>Falling prey to cruelty/misfortune</h3></li>
                    <ul>
                        <li>an unfortunate; a master or a misfortune</li>
                        <li>The unfortunate suffers from misfortune and/or at the hands of the master.</li>
                        <li>Example: Job (biblical figure)</li>
                    </ul>
                <li><h3>Revolt</h3></li>
                    <ul>
                        <li>a tyrant; a conspirator</li>
                        <li>The tyrant, a cruel power, is plotted against by the conspirator.</li>
                        <li>Example: Julius Caesar (play)</li>
                    </ul>
                <li><h3>Daring enterprise</h3></li>
                    <ul>
                        <li>a bold leader; an object; an adversary</li>
                        <li>The bold leader takes the object from the adversary by overpowering the adversary.</li>
                        <li>Examples: The Lord of the Rings; Raiders of the Lost Ark</li>
                    </ul>
                <li><h3>Abduction</h3></li>
                    <ul>
                        <li>an abductor; the abducted; a guardian</li>
                        <li>The abductor takes the abducted from the guardian.</li>
                        <li>Example: Helen of Troy</li>
                    </ul>
                <li><h3>The enigma</h3></li>
                    <ul>
                        <li>a problem; an interrogator; a seeker</li>
                        <li>The interrogator poses a problem to the seeker and gives a seeker better ability to reach the seeker's goals.</li>
                        <li>Examples: Oedipus and the Sphinx; The Batman</li>
                    </ul>
                <li><h3>Obtaining</h3></li>
                    <ul>
                        <li>a Solicitor & an adversary who is refusing or an arbitrator & opposing parties</li>
                        <li>The solicitor is at odds with the adversary who refuses to give the solicitor an object in the possession of the adversary, or an arbitrator decides who gets the object desired by opposing parties (the solicitor and the adversary).</li>
                        <li>Example: Apple of Discord</li>
                    </ul>
                <li><h3>Enmity of kin</h3></li>
                    <ul>
                        <li>a Malevolent Kinsman; a Hated or a reciprocally-hating Kinsman</li>
                        <li>The Malevolent Kinsman and the Hated or a second Malevolent Kinsman conspire together.</li>
                        <li>Example: As You Like It</li>
                    </ul>
                <li><h3>Rivalry of kin</h3></li>
                    <ul>
                        <li>the Preferred Kinsman; the Rejected Kinsman; the Object of Rivalry</li>
                        <li>The Object of Rivalry chooses the Preferred Kinsman over the Rejected Kinsman.</li>
                        <li>Example: Wuthering Heights</li>
                    </ul>
                <li><h3>Murderous adultery</h3></li>
                    <ul>
                        <li>two Adulterers; a Betrayed Spouse</li>
                        <li>Two Adulterers conspire to kill the Betrayed Spouse.</li>
                        <li>Examples: Clytemnestra, Aegisthus, Double Indemnity</li>
                    </ul>
                <li><h3>Madness</h3></li>
                    <ul>
                        <li>a Madman; a Victim</li>
                        <li>The Madman goes insane and wrongs the Victim.</li>
                        <li>Example: The Shining</li>
                    </ul>
                <li><h3>Fatal imprudence</h3></li>
                    <ul>
                        <li>the Imprudent; a Victim or an Object Lost</li>
                        <li>The Imprudent, by neglect or ignorance, loses the Object Lost or wrongs the Victim.</li>
                        <li>Example: Kris Kelvin and his wife in Solaris</li>
                    </ul>
                <li><h3>Involuntary crimes of love</h3></li>
                    <ul>
                        <li>a Lover; a Beloved; a Revealer</li>
                        <li>The Lover and the Beloved have unknowingly broken a taboo through their romantic relationship, and the Revealer reveals this to them.</li>
                        <li>Examples: Oedipus, Jocasta and the messenger from Corinth.</li>
                    </ul>
                <li><h3>Slaying of kin unrecognized</h3></li>
                    <ul>
                        <li>the Slayer; an Unrecognized Victim</li>
                        <li>The Slayer kills the Unrecognized Victim.</li>
                        <li>Example: Oedipus and Laius</li>
                    </ul>
                <li><h3>Self-sacrifice for an ideal</h3></li>
                    <ul>
                        <li>a Hero; an Ideal; a Creditor or a Person/Thing sacrificed</li>
                        <li>The Hero sacrifices the Person or Thing for their Ideal, which is then taken by the Creditor.</li>
                        <li>Example: The Gospel</li>
                    </ul>
                <li><h3>Self-sacrifice for kin</h3></li>
                    <ul>
                        <li>a Hero; a Kinsman; a Creditor or a Person/Thing sacrificed</li>
                        <li>The Hero sacrifices a Person or Thing for their Kinsman, which is then taken by the Creditor.</li>
                    </ul>
                <li><h3>All sacrificed for passion</h3></li>
                    <ul>
                        <li>a Lover; an Object of fatal Passion; the Person/Thing sacrificed</li>
                        <li>A Lover sacrifices a Person or Thing for the Object of their Passion, which is then lost forever. </li>
                        <li>Example: Breaking Bad</li>
                    </ul>
                <li><h3>Necessity of sacrificing loved ones</h3></li>
                    <ul>
                        <li>a Hero; a Beloved Victim; the Necessity for the Sacrifice</li>
                        <li>The Hero wrongs the Beloved Victim because of the Necessity for their Sacrifice</li>
                        <li>Example: Binding of Isaac</li>
                    </ul>
                <li><h3>Rivalry of superior vs. inferior</h3></li>
                    <ul>
                        <li>a Superior Rival; an Inferior Rival; the Object of Rivalry</li>
                        <li>An Inferior Rival bests a Superior Rival and wins the Object of Rivalry.</li>
                        <li>Example: Godzilla vs. Kong</li>
                    </ul>
                <li><h3>Adultery</h3></li>
                    <ul>
                        <li>two Adulterers; a Deceived Spouse</li>
                        <li>Two Adulterers conspire against the Deceived Spouse.</li>
                        <li>Example: Brothers (2009 film)</li>
                    </ul>
                <li><h3>Crimes of love</h3></li>
                    <ul>
                        <li>a Lover; the Beloved</li>
                        <li>A Lover and the Beloved break a taboo by initiating a romantic relationship</li>
                        <li>Example: Sigmund and his sister in The Valkyrie</li>
                    </ul>
                <li><h3>Discovery of the dishonour of a loved one</h3></li>
                    <ul>
                        <li>a Discoverer; the Guilty One</li>
                        <li>The Discoverer discovers the wrongdoing committed by the Guilty One.</li>
                        
                    </ul>
                <li><h3>Obstacles to love</h3></li>
                    <ul>
                        <li>two Lovers; an Obstacle</li>
                        <li>Two Lovers face an Obstacle together.</li>
                        <li>Example: Romeo and Juliet</li>
                    </ul>
                <li><h3>An enemy loved</h3></li>
                    <ul>
                        <li>a Lover; the Beloved Enemy; the Hater</li>
                        <li>The allied Lover and Hater have diametrically opposed attitudes towards the Beloved Enemy.</li>
                    </ul>
                <li><h3>Ambition</h3></li>
                    <ul>
                        <li>an Ambitious Person; a Thing Coveted; an Adversary</li>
                        <li>The Ambitious Person seeks the Thing Coveted and is opposed by the Adversary.</li>
                        <li>Example: Macbeth</li>
                    </ul>
                <li><h3>Conflict with a god</h3></li>
                    <ul>
                        <li>a Mortal; an Immortal</li>
                        <li>The Mortal and the Immortal enter a conflict.</li>
                    </ul>
                <li><h3>Mistaken jealousy</h3></li>
                    <ul>
                        <li>a Jealous One; an Object of whose Possession He is Jealous; a Supposed Accomplice; a Cause or an Author of the Mistake</li>
                        <li>The Jealous One falls victim to the Cause or the Author of the Mistake and becomes jealous of the Object and becomes conflicted with the Supposed Accomplice.</li>
                    </ul>

                <li><h3>Erroneous judgment</h3></li>
                    <ul>
                        <li>a Mistaken One; a Victim of the Mistake; a Cause or Author of the Mistake; the Guilty One</li>
                        <li>The Mistaken One falls victim to the Cause or the Author of the Mistake and passes judgment against the Victim of the Mistake when it should be passed against the Guilty One instead.</li>
                    </ul>
                <li><h3>Remorse</h3></li>
                    <ul>
                        <li>a Culprit; a Victim or the Sin; an Interrogator</li>
                        <li>The Culprit wrongs the Victim or commits the Sin, and is at odds with the Interrogator who seeks to understand the situation.</li>
                        <li>Examples: No Exit, The Bourne Supremacy</li>
                    </ul>
                <li><h3>Recovery of a lost one</h3></li>
                    <ul>
                        <li>a Seeker; the One Found</li>
                        <li>The Seeker finds the One Found. </li>
                        <li>Example: A Very Long Engagement, Finding Nemo</li>
                    </ul>
                <li><h3>Loss of loved ones</h3></li>
                    <ul>
                        <li>a Kinsman Slain; a Kinsman Spectator; an Executioner</li>
                        <li>The killing of the Kinsman Slain by the Executioner is witnessed by the Kinsman.</li>
                        <li>Example: Braveheart, Gladiator</li>
                    </ul>
                    
            </ol>
            <a target="_blank" rel="noreferrer" href='https://www.wob.com/en-gb/books/polti-georges-polti/36-dramatic-situations-georges-polti/9781604244878?cq_src=google_ads&cq_cmp=18027178076&cq_con=&cq_med=pla&cq_plac=&cq_net=x&gad_source=1&gclid=Cj0KCQiA6vaqBhCbARIsACF9M6lkwai8ahGvuJuzSRiUO2Gd5Hq-rQX4FC-j2uGenMCOznIjTsUopvIaAmC9EALw_wcB#NLS9781604244878'><img src='/images/polti.jpg'alt='Georges Polti’s 36 Dramatic Situations'/></a>
        </div>
    )
}