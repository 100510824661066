import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePrompt, selectSavedPrompts,removePrompt, selectSettingPrompt } from "./settingPromptSlice";

export const SettingSave = ({style}) => {

    const savedPrompts = useSelector(selectSavedPrompts);
    const dispatch = useDispatch();
    const settingPrompt = useSelector(selectSettingPrompt);

    const handleClick = () => {
        dispatch(savePrompt(settingPrompt));

    }

    return (
        <div className='setting save'>
            <button id='button2'onClick={()=>handleClick()}>Save</button>
            <div className = 'savedPrompt'>
                {savedPrompts.map((prompt) => (
                    <div>
                        <p style={style}>{prompt} </p>
                        <p id='button2' onClick={()=> dispatch(removePrompt(prompt))}>remove</p>
                    </div>
                ))}
            </div>
        
        </div>
    )
}


           