import React from "react";


export const Quote =()=>{

    const quotes = [
        {quote: "There is no greater agony than bearing an untold story inside you.", by: 'Maya Angelou'},
        {quote: "There is nothing to writing. All you do is sit down at a typewriter and bleed.", by:'Ernest Hemingway'},
        {quote: "Fantasy is hardly an escape from reality. It's a way of understanding it.", by: 'Lloyd Alexander'},
        {quote: "The worst enemy to creativity is self-doubt.",by: 'Sylvia Plath'},
        {quote: "No tears in the writer, no tears in the reader. No surprise in the writer, no surprise in the reader.", by:'Robert Frost'},
        {quote: "Don't tell me the moon is shining; show me the glint of light on broken glass.", by:'Anton Chekhov'},
        {quote: "After nourishment, shelter and companionship, stories are the thing we need most in the world.", by:'Philip Pullman'},
        {quote: "How vain it is to sit down to write when you have not stood up to live.", by:'Henry David Thoreau'},
        {quote: "If you want to be a writer, you must do two things above all others: read a lot and write a lot.", by:'Stephen King'},
        {quote: "A writer is someone for whom writing is more difficult than it is for other people.", by: 'Thomas Mann'},
        {quote: "Fill your paper with the breathings of your heart.",by: 'William Wordsworth'},
        {quote: "There is something delicious about writing the first words of a story. You never quite know where they'll take you.",by: 'Beatrix Potter'},
        {quote: "A word after a word after a word is power.",by: 'Margaret Atwood'},
        {quote: "Tomorrow may be hell, but today was a good writing day, and on the good writing days nothing else matters.",by: 'Neil Gaiman'},
        {quote: "Tears are words that need to be written.",by: 'Paulo Coelho'},
        {quote: "You must write every single day of your life... You must lurk in libraries and climb the stacks like ladders to sniff books like perfumes and wear books like hats upon your crazy heads... may you be in love every day for the next 20,000 days. And out of that love, remake a world.",by: 'Ray Bradbury'},
        {quote: "You can make anything by writing.",by: 'C.S. Lewis'},
        {quote: "I write to give myself strength. I write to be the characters that I am not. I write to explore all the things I'm afraid of.",by: 'Joss Whedon'},
        {quote: "A well-composed book is a magic carpet on which we are wafted to a world that we cannot enter in any other way.",by: 'Caroline Gordon'},
        {quote: "The strength of your villain is the strength of your story.",by: 'Dwight V. Swain'},
        {quote: "Specificity is the soul of narrative.",by: 'John Hodgman'},
        {quote: "If there's a book that you want to read, but it hasn't been written yet, then you must write it.",by: 'Toni Morrison'},
        {quote: "Imagination is like a muscle. I found out that the more I wrote, the bigger it got.",by: 'Philip José Farmer'},
        {quote: "We have to continually be jumping off cliffs and developing our wings on the way down.",by: 'Kurt Vonnegut'},
        {quote: "M is for magic. All the letters are, if you put them together properly. You can make magic with them, and dreams, and, I hope, even a few surprises…",by: 'Neil Gaiman'},
        {quote: "Specificity is the soul of narrative.",by: 'John Hodgman'},
        {quote: "It's weird not to be weird.", by: "John Lennon"}

    ]


    const genQuote =(array)=>{
        let rando = Math.floor(Math.random()*array.length)
        return array[rando];
        
    }

    let quote = genQuote(quotes)

    const link = `https://en.wikipedia.org/wiki/${quote.by}`

    return(
        <section id="quote">
            <blockquote>
                <p>
                "{quote.quote}"
                </p>
                <cite>
                <a target="_blank" rel="noreferrer" 
                    href={link}>
                    -{quote.by}
                </a>
                </cite>
            </blockquote>
        </section>
    )
}