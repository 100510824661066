import { configureStore } from '@reduxjs/toolkit';

import characterPromptReducer from '../pages/prompts/characterPrompt/characterPromptSlice';
import settingPromptReducer from '../pages/prompts/settingPrompt/settingPromptSlice';
import randomElementsReducer from '../pages/prompts/randomElements/randomElementsSlice';
import storyPremiseReducer from '../pages/prompts/storyPremise/storyPremiseSlice';
import uspPromptReducer from '../pages/prompts/uspPrompt/uspPromptSlice';
import redditPromptReducer from '../pages/prompts/redditPrompt/redditPromptSlice';
import plotPromptReducer from '../pages/prompts/plotPrompt/plotPromptSlice';
import getIdeaReducer from '../pages/get-idea/getIdeaSlice';

export const store = configureStore({
  reducer: {
    characterPrompt: characterPromptReducer,
    settingPrompt: settingPromptReducer,
    randomElements: randomElementsReducer,
    storyPremise: storyPremiseReducer,
    uspPrompt: uspPromptReducer,
    redditPrompt: redditPromptReducer,
    plotPrompt: plotPromptReducer,
    getIdea: getIdeaReducer,
  },
});
