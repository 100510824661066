import { createSlice } from "@reduxjs/toolkit";

export const getIdeaSlice = createSlice({
    name: 'getIdea',
    initialState: {
        step1: '',
        step2: '',
        step3: '',
        step4: '',
        step5: '',
        step6: '',
        step7: '',
        step8: '',
        summary: ''
    },
    reducers: {
        enter: (state, action) => {
            if(action.payload.step==='step1'){
                state.step1 = action.payload.input;
            }
            if(action.payload.step==='step2'){
                state.step2 = action.payload.input;
            }
            if(action.payload.step==='step3'){
                state.step3 = action.payload.input;
            }
            if(action.payload.step==='step4'){
                state.step4 = action.payload.input;
            }
            if(action.payload.step==='step5'){
                state.step5 = action.payload.input;
            }
            if(action.payload.step==='step6'){
                state.step6 = action.payload.input;
            }
            if(action.payload.step==='step7'){
                state.step7 = action.payload.input;
            }
            if(action.payload.step==='step8'){
                state.step8 = action.payload.input;
            }
            if(action.payload.step==='step-summary'){
                state.summary = action.payload.input;
            }
            
        }
    }
})

export const selectGetIdea = (state) => state.getIdea
export default getIdeaSlice.reducer;
export const { enter } = getIdeaSlice.actions;