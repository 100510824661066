import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";

export const GetPublished = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <div>
             <header><h1>Getting Published</h1></header>
            <NavLink to='/story-elements/craft-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
        </div>
    )
}
