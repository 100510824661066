import React from "react";
import { NavLink } from "react-router-dom";

export const PlotHome = () => {
   
        window.scrollTo(0,0)

    return(
        <div className='element'>
            <header><h1>Plot Elements</h1></header>
            <NavLink to='/story-elements'><button id='button3'>Back</button></NavLink>
            <div className="navBoxes2">
                    <NavLink to='genres' className='boxLink'>Genres</NavLink>
                    <NavLink to='plot-archetypes' className='boxLink'>Plot Archetypes</NavLink>
                    <NavLink to='character-arcs' className='boxLink'>Character Arcs</NavLink>
             
                    <NavLink to='subplots' className='boxLink'>Subplots</NavLink>
                
                    <NavLink to='hooks'className='boxLink'>Hooks</NavLink>
                
                    <NavLink to='endings'className='boxLink'>Endings</NavLink>
             
                    
            </div>
        </div>
    )
}