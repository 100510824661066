import React from "react";

export const Tobias = () => {
    return(
        <div className='plotArchs-page'>
            <h1>Ronald B. Tobias’s 20 Master Plots</h1>
            <p></p>
            <ol id="plotList">
                <h4>In his popular and practical book, "20 Master Plots, and How to Build Them," Ronald Tobias delineates 20 prevalent story plots, offering in-depth guidance on crafting comprehensive narratives based on each plot archetype.</h4>
                <li><h3>Quest</h3></li>
                    
                        <p>The hero goes looking for something or someone that affects a world much bigger than themselves. This can also be an internal quest, as they search for courage or inspiration within themselves.</p>
                    
                <li><h3>Adventure</h3></li>
                   
                        <p>The hero goes on a journey, but unlike the Quest, Adventure focuses more on the hero’s experiences than the destination.</p>
                <li><h3>Pursuit</h3></li>
                <p>Like many spy thrillers, this story focuses on the hero pursuing a villain or being pursued by one, always doing whatever they can to stay one step ahead.</p>
                <li><h3>Rescue</h3></li>
                  
                        <p>Someone the hero loves or needs has been lost, captured, or waylaid and the hero goes after them. Many classic fairy tales follow this story, which creates a triangle between the rescuer, the rescued, and the villain.</p>
                   
                <li><h3>Escape</h3></li>
                   
                        <p>This may follow the rescue, or your story may begin here. The hero escapes from a prison (real, metaphorical, or imagined) and does their best to fight their way home.</p>
                    
                <li><h3>Revenge</h3></li>
                   
                        <p>The hero seeks retribution against a person or group that has wronged them, very often becoming just as bad as their enemies in the process.</p>
                    
                <li><h3>Riddle</h3></li>
                    <p>Like the traditional mystery or detective story, the hero must unravel a series of challenges to gain new understanding and reach a solution.</p>
                <li><h3>Rivalry</h3></li>
                    <p>The hero has to compete against an opposing force, whether that’s in love, sports, business, or the arts. In these stories they need to balance their ultimate goal with their own personal values and morals.</p>
                <li><h3>Underdog</h3></li>
                    <p>A rivalry with a significant shift in status. The hero is at a physical, financial, or cultural disadvantage and needs to exercise greater cunning and determination to beat their opponent.</p>
                <li><h3>Temptation</h3></li>
                    <p>The hero faces an internal conflict with their own weaknesses, such as extreme pessimism, addiction, or insecurity. They navigate their world while fighting against these weaknesses—sometimes successfully, sometimes not.</p>
                <li><h3>Metamorphosis</h3></li>
                    <p>A physical transformation, usually due to some supernatural force, in which the hero either finds a way to turn back into who they were or learns to build a new life as what they’ve become.</p>
                <li><h3>Transformation</h3></li>
                    <p>An emotional or spiritual transformation (as opposed to the literal transformation of the metamorphosis); the hero deals with an extreme change in their circumstance and undergoes a positive shift because of it.</p>
                <li><h3>Maturation</h3></li>
                    <p>The classic “coming of age” tale where the hero has to grow up in response to what’s happening around them, gaining a new understanding of the world and a new sense of purpose.</p>
                <li><h3>Love</h3></li>
                    <p>A story in which two lovers find each other, become separated by external forces or by personal insecurities, and overcome their obstacles to be reunited.</p>
                <li><h3>Forbidden Love</h3></li>
                    <p>A love story in which the two lovers meet in spite of the rules of their society, cultures, or personal circumstances. These stories may focus on their inner conflicts and divided loyalties.</p>
                <li><h3>Sacrifice</h3></li>
                    <p>The central character makes a bold sacrifice for the good of their loved ones or the world around them, showcasing human nature at its greatest potential. This will usually come at the end of a difficult period of growth for the hero.</p>
                <li><h3>Discovery</h3></li>
                    <p>A story of revelation in which the hero learns something that shakes their perception of the world they know, and has to adapt and understand what that revelation means for them.</p>
                <li><h3>Wretched Excess</h3></li>
                    <p>The protagonist lives a life far beyond what is socially acceptable, making hedonistic choices that alienate what’s truly most important and ultimately lead to their downfall.</p>
                <li><h3>Ascension</h3></li>
                    <p>The “rags to riches” story, whether by material wealth or by personal growth, in which the hero rises to become something greater than what they started out as.</p>
                <li><h3>Descent</h3></li>
                    <p>The opposite story arc to “Ascension,” in which the central character begins in a place of contentment or privilege and watches their world crumble as a result of their own hubris or mistakes.</p>

            </ol>
            <a target="_blank" rel="noreferrer" href='https://www.goodreads.com/en/book/show/114819'><img src='/images/tobias.jpeg'alt ='20 Master Plots: And How to Build Them by Ronald B. Tobias'/></a>
        </div>
    )
}